import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core';
import { createApolloProvider } from '@vue/apollo-option';

let apolloClient;
export function getDefaultClient() {
  if (!apolloClient) {
    apolloClient = new ApolloClient({
      link: createHttpLink({
        uri: `${process.env.VUE_APP_API_BASE_URL}/graphql`,
      }),
      cache: new InMemoryCache(),
    });
  }

  return apolloClient;
}

export default {
  install(app) {
    app.use(
      createApolloProvider({
        defaultClient: getDefaultClient(),
      }),
    );
  },
};
