<template>
  <div>
    <main-card :title="$route.query.title" :progress="progress">
      <template v-slot:content>
        <ar-item-table />
      </template>
    </main-card>
  </div>
</template>

<script>

import ArItemTable from '@/views/account-receivable/detail/ArItemTable.vue';

export default {
  name: 'AccountReceivableDetail',
  components: {
    ArItemTable,
  },
  data() {
    return {
      progress: false,
    };
  },
  props: {
    title: {
      type: String,
    },
  },
};
</script>
