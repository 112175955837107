<template>
  <fare-api ref="axios"></fare-api>
  <DataTable :value="tables" responsive-layout="scroll">
    <Column v-for="col in columns" :lazy="true" :field="col.field" :header="col.header" :key="col.field"></Column>
    <template #empty>
      결과 없음
    </template>
  </DataTable>
</template>

<script>
import FareApi from '@/apis/FareApi.vue';
import ChargeConstant from '@/views/constant/charge-constant';

export default {
  components: {
    FareApi,
  },
  data() {
    return {
      columns: null,
      tables: null,
    };
  },
  props: {
    type: null,
  },
  mounted() {
    this.emitter.on('searchDate', async (request) => {
      await this.showTable(request);
    });
    this.emitter.on('clearTable', () => {
      this.tables = null;
      this.columns = null;
    });
  },
  created() {
  },
  unmounted() {
    this.emitter.off('searchDate');
    this.emitter.off('clearTable');
  },
  methods: {
    async showTable(request) {
      const fareList = await this.$refs.axios.getFareTariff(request);
      this.columns = [
        { field: 'fareType', header: '추가 운임 항목' },
        { field: 'fareTarget', header: '청구 대상' },
        { field: 'tariffType', header: '청구 방법' },
        { field: 'chargePrice', header: '단가' },
      ];
      const excludeList = ['AP', 'AR', 'ZONE'];
      this.tables = fareList.filter((value) => !excludeList.includes(value.fareType) && ChargeConstant.items.some((item) => value.fareType.includes(item.key))).map((value) => {
        // eslint-disable-next-line no-return-assign,no-param-reassign
        value.tariffType = ChargeConstant.inputTypes.find((inputType) => inputType.key === value.tariffType).title;
        // eslint-disable-next-line no-param-reassign
        value.fareTarget = value.fareType.includes('_AP') ? '파트너사 -> 어크로스비 청구' : '어크로스비 -> 고객사 청구';
        // eslint-disable-next-line no-param-reassign
        value.fareType = ChargeConstant.items.find((item) => value.fareType.includes(item.key)).title;
        return value;
      });
      if (fareList.length === 0) {
        this.$toast.add({
          severity: 'error',
          summary: '조회 결과',
          detail: `고객사 : ${request.customerId}, 타입: ${this.type}`,
          life: 3000,
        });
        this.tables = null;
      }
    },
  },
};
</script>
