import { createRouter, createWebHistory } from 'vue-router';
import UploadOrdersForHmIntl from '@/views/upload-orders/UploadOrdersForHmIntl.vue';
import YoonseulExportDeclaration from '@/views/export-declarations/YoonseulExportDeclaration.vue';
import JlExportDeclaration from '@/views/export-declarations/JlExportDeclaration.vue';
import TariffRegister from '@/views/contract-info/fare-tariff/register/index.vue';
import TariffManagement from '@/views/contract-info/fare-tariff/management/index.vue';
import CustomersManagement from '@/views/contract-info/customers/management/index.vue';
import ApUpload from '@/views/account-payable/invoice/Upload.vue';
import ApList from '@/views/account-payable/invoice/List.vue';
import WarehousingList from '@/views/warehousing/list/index.vue';
import WarehousingRegister from '@/views/warehousing/register/index.vue';
import OrdersExtract from '@/views/orders/extract/index.vue';
import OrdersMigrate from '@/views/orders/migrate/index.vue';
import AccountPayableDetail from '@/views/account-payable/detail/index.vue';
import CurrencyManagement from '@/views/contract-info/currency/management/index.vue';
import CurrencyRegister from '@/views/contract-info/currency/register/index.vue';
import FSCManagement from '@/views/contract-info/fuel-surcharge/management/index.vue';
import FSCRegister from '@/views/contract-info/fuel-surcharge/register/index.vue';
import Dashboard from '@/views/dashboard/Dashboard.vue';
import ArList from '@/views/account-receivable/invoice/List.vue';
import AccountReceivableDetail from '@/views/account-receivable/detail/index.vue';
import PartnerTariffRegister from '@/views/contract-info/fare-tariff/customer/register/index.vue';
import PartnerTariffManagement from '@/views/contract-info/fare-tariff/customer/management/index.vue';
import PreparedTrackingPage from '@/views/prepared-tracking/PreparedTrackingPage.vue';
import PreparedTrackingUploadPage from '@/views/prepared-tracking/PreparedTrackingUploadPage.vue';
import CarrierManagePage from '@/views/prepared-tracking/carrier/CarrierManagePage.vue';
import PreparedTrackingLocalOfficePage from '@/views/prepared-tracking/PreparedTrackingLocalOfficePage.vue';
import PreparedTrackingLocalOfficeUpload from '@/views/prepared-tracking/PreparedTrackingLocalOfficeUpload.vue';
import { defineComponent } from 'vue';

const routes = [
  {
    path: '/',
    component: defineComponent({
      mounted() {
        window.location.replace(process.env.VUE_APP_MIRINAE_URL);
      },
    }),
  },
  {
    path: '/upload-orders/hm-intl',
    name: 'UploadOrdersForHmIntl',
    component: UploadOrdersForHmIntl,
  },
  {
    path: '/export-declarations/yoonseul',
    name: 'YoonseulExportDeclaration',
    component: YoonseulExportDeclaration,
  },
  {
    path: '/export-declarations/jl',
    name: 'JlExportDeclaration',
    component: JlExportDeclaration,
  },
  {
    path: '/account-payable/invoice/list',
    name: 'AccountPayableList',
    component: ApList,
  },
  {
    path: '/account-payable/invoice/register',
    name: 'AccountPayableUpload',
    component: ApUpload,
  },
  {
    path: '/account-receivable/invoice/list',
    name: 'AccountReceivableList',
    component: ArList,
  },
  {
    path: '/account-receivable/detail/',
    name: 'AccountReceivableDetail',
    component: AccountReceivableDetail,
    props: true,
  },
  {
    path: '/account-payable/detail/',
    name: 'AccountPayableDetail',
    component: AccountPayableDetail,
    props: true,
  },
  {
    path: '/contract-info/fare-tariff/register',
    name: 'TariffRegister',
    component: TariffRegister,
  },
  {
    path: '/contract-info/fare-tariff/management',
    name: 'TariffManagement',
    component: TariffManagement,
  },
  {
    path: '/contract-info/customers/management',
    name: 'CustomersManagement',
    component: CustomersManagement,
  },
  {
    path: '/contract-info/currency/management',
    name: 'CurrencyManagement',
    component: CurrencyManagement,
  },
  {
    path: '/contract-info/currency/register',
    name: 'CurrencyRegister',
    component: CurrencyRegister,
  },
  {
    path: '/contract-info/fuel-surcharge/management',
    name: 'FSCManagement',
    component: FSCManagement,
  },
  {
    path: '/contract-info/fuel-surcharge/register',
    name: 'FSCRegister',
    component: FSCRegister,
  },
  {
    path: '/warehousing/list',
    name: 'WarehousingList',
    component: WarehousingList,
  },
  {
    path: '/warehousing/register',
    name: 'WarehousingRegister',
    component: WarehousingRegister,
  },
  {
    path: '/orders/extract',
    name: 'OrdersExtract',
    component: OrdersExtract,
  },
  {
    path: '/orders/migrate',
    name: 'OrdersMigrate',
    component: OrdersMigrate,
  },
  {
    path: '/contract-info/fare-tariff/partner/register',
    name: 'PartnerTariffRegister',
    component: PartnerTariffRegister,
  },
  {
    path: '/contract-info/fare-tariff/partner/management',
    name: 'PartnerTariffManagement',
    component: PartnerTariffManagement,
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
  },
  {
    path: '/prepared-tracking',
    name: 'PreparedTrackingPage',
    component: PreparedTrackingPage,
  },
  {
    path: '/prepared-tracking/upload',
    name: 'PreparedTrackingUploadPage',
    component: PreparedTrackingUploadPage,
  },
  {
    path: '/prepared-tracking/local-office',
    name: 'LocalOfficePage',
    component: PreparedTrackingLocalOfficePage,
  },
  {
    path: '/prepared-tracking/local-office/upload',
    name: 'LocalOfficeUploadPage',
    component: PreparedTrackingLocalOfficeUpload,
  },
  {
    path: '/prepared-tracking/carriers',
    name: 'CarrierManagePage',
    component: CarrierManagePage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  next();
});

export default router;
