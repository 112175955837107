export default {
  headers: [
    { label: '운송장번호', key: 'trackingNumber' },
    { label: '전자상거래수출신고번호', key: 'declarationNumber' },
    { label: '수출화주 신고인 부호', key: 'exportNumber' },
    { label: '주문번호', key: 'orderId' },
    { label: '이행수량', key: 'totalItemCount' },
    { label: '이행중량', key: 'totalWeight' },
  ],
};
