<template>
  <div>
    <prepared-tracking-api ref="api" />
    <main-card title="영업소 코드 조회">
      <template v-slot:content>
        <div class="grid">
          <div class="col-8 grid flex-nowrap p-fluid">
            <div class="col-4">
              <DefaultLabel label="운송사" />
              <Dropdown
                v-model="selectedCarrier"
                @change="onFilterUpdate"
                :placeholder="carriers.find((carrier) => carrier.label === 'yamato')?.label ?? ''"
                :options="carriers" optionLabel="label"
              >
                <template #optiongroup="slotProps">
                  <div class="flex align-items-center country-item">
                    <img src="https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png" width="18" />
                    <div>{{slotProps.option.label}}</div>
                  </div>
                </template>
              </Dropdown>
            </div>
          </div>

          <div class="col">
            <DefaultLabel label="검색 키워드" />
            <Dropdown
              style="width: 130px"
              v-model="selectedSearchKeywordOptions"
              @change="onSearchKeywordOptionChanged"
              :options="searchKeywordOptions"
              optionLabel="label"
            >
              <template #optiongroup="slotProps">
                <div class="flex align-items-center country-item">
                  <img src="https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png" width="18" />
                  <div>{{slotProps.option.label}}</div>
                </div>
              </template>
            </Dropdown>
            <InputText type="text" :placeholder="selectedSearchKeywordOptions?.label ?? ''" @keydown.enter="search" v-model="searchKeyword" />
            <Button icon="pi pi-search" @click="search"></Button>
          </div>
        </div>
        <prepared-tracking-local-office-table
          :list="localOfficeCodes"
          :page="page"
          :size="size"
          :total-pages="currentPage.totalPages"
          :total-elements="currentPage.totalElements"
          @onPaging="onPaging"
        />
      </template>
    </main-card>
  </div>
</template>

<script>
import DefaultLabel from '@/components/DefaultLabel.vue';
import PreparedTrackingApi from '@/views/prepared-tracking/PreparedTrackingApi.vue';
import PreparedTrackingLocalOfficeTable from '@/views/prepared-tracking/PreparedTrackingLocalOfficeTable.vue';

export default {
  components: {
    DefaultLabel,
    PreparedTrackingApi,
    PreparedTrackingLocalOfficeTable,
  },
  data() {
    return {
      page: 0,
      size: 10,
      searchKeyword: '',
      selectedCarrier: null,
      selectedSearchKeywordOptions: null,
      searchKeywordOptions: [{
        label: 'ㅤ',
      }, {
        label: '우편 번호',
        value: 'zipCode',
      }, {
        label: '영업소 코드',
        value: 'localOfficeCode',
      }],
      carriers: [],
      currentPage: { content: [] },
    };
  },
  computed: {
    localOfficeCodes() {
      return this.currentPage.content;
    },
  },
  methods: {
    async search() {
      const carrierId = this.selectedCarrier?.value ?? null;
      const result = await this.$refs.api.listOfLocalOffices({
        zipCode: this.selectedSearchKeywordOptions?.value === 'zipCode' ? this.searchKeyword : null,
        localOfficeCode: this.selectedSearchKeywordOptions?.value === 'localOfficeCode' ? this.searchKeyword : null,
        carrierId,
        size: this.size,
        page: this.page,
      });
      this.currentPage = result;
    },
    async onFilterUpdate() {
      this.page = 0;
      this.size = 10;
      await this.search();
    },
    async onSearchKeywordOptionChanged() {
      this.searchKeyword = '';
    },
    async onPaging({ page, size }) {
      this.page = page;
      this.size = size;
      await this.search();
    },
  },
  async mounted() {
    const carrierList = (await this.$refs.api.listOfCarriers()).map((carrier) => ({
      label: carrier.id,
      value: carrier.id,
    }));
    this.carriers = carrierList;
    await this.search();
  },
};
</script>
