<script>
import axios from 'axios';
import getCustomerText from '@/views/constant/customers-info';
import getErrorMsg from '@/apis/error-handler';
import getCarrierText from '@/views/constant/carriers-info';

export default {
  data() {
    return {
      API_BASE_URL: `${process.env.VUE_APP_API_BASE_URL}/settlement`,
      api: axios.create(),
    };
  },
  created() {
    this.api.interceptors.response.use(
      (res) => res,
      (err) => {
        this.$toast.add(getErrorMsg(err));
        return err;
      },
    );
  },
  methods: {
    async addCustomer(id, name) {
      const { data } = await this.api.put(`${this.API_BASE_URL}/fare/customer/item/save`, { id, name });
      return data;
    },
    async deleteCustomers(ids) {
      const { data } = await this.api.post(`${this.API_BASE_URL}/fare/customer/items/delete`, { ids });
      return data;
    },
    async convertKeyText(url, convert) {
      const { data } = await this.api.get(url);
      return Promise.all(data.map(async (value) => ({
        key: value.id,
        text: convert !== undefined ? convert(value.id) : value.name,
      })));
    },
    async getCustomerList() {
      return this.convertKeyText(`${this.API_BASE_URL}/fare/customer/item/list`, getCustomerText);
    },
    async getCarrierList() {
      return this.convertKeyText(`${this.API_BASE_URL}/fare/carrier/item/list`, getCarrierText);
    },
    async getPartnerList() {
      return this.convertKeyText(`${this.API_BASE_URL}/fare/partner/item/list`);
    },
    async getCustomerMapList() {
      const { data } = await this.api.get(`${this.API_BASE_URL}/fare/customerMap`);
      return data;
    },
    async getFareDateList(request) {
      const { data } = await this.api.post(`${this.API_BASE_URL}/fare/date`, request);
      return data;
    },
    async checkFareDate(request) {
      const { data } = await this.api.post(`${this.API_BASE_URL}/fare/duplicated`, request);
      return data;
    },
    async registerFare(request) {
      await this.api.put(`${this.API_BASE_URL}/fare/register`, request);
    },
    async getFareTariff(request) {
      const { data } = await this.api.post(`${this.API_BASE_URL}/fare/tariff`, request);
      return data;
    },

    async getFareTariffByFareType(request) {
      const { data } = await this.api.post(`${this.API_BASE_URL}/fare/type/list`, request);
      return data;
    },
    async deleteFareTariff(request) {
      const { data } = await this.api.delete(`${this.API_BASE_URL}/fare`, { data: request });
      return data;
    },
    async deleteFareByIds(request) {
      const { data } = await this.api.delete(`${this.API_BASE_URL}/fare/ids`, { data: request });
      return data;
    },
    getCustomerText,
    getCarrierText,
  },
};
</script>
