export default {
  ko_kr: {
    monthNames: [
      '1월', '2월', '3월',
      '4월', '5월', '6월',
      '7월', '8월', '9월',
      '10월', '11월', '12월',
    ],
    monthNamesShort: [
      '1월', '2월', '3월',
      '4월', '5월', '6월',
      '7월', '8월', '9월',
      '10월', '11월', '12월',
    ],
    dayNamesMin: ['일', '월', '화', '수', '목', '금', ' 토'],
    clear: '초기화',
    today: '오늘',
  },
};
