export function toFileSize(bytes) {
  if (bytes === 0) {
    return '0 B';
  }
  const k = 1024;
  const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / (k ** i)).toFixed(2))} ${units[i]}`;
}

export function sumCharge(item) {
  return Object.keys(item).reduce((sum, key) => {
    if (key.toLowerCase().includes('weight')) {
      return sum;
    }
    if (key.toLowerCase().includes('charge') || key.toLowerCase().includes('duty') || key.toLowerCase().includes('tax')) {
      return sum + item[key];
    }
    if (key.toLowerCase().includes('discount')) {
      return sum - item[key];
    }
    return sum;
  }, 0);
}

export function isCharge(field) {
  return (!field.toLowerCase().includes('weight')
    // eslint-disable-next-line max-len
    && (field.toLowerCase().includes('charge') || field.toLowerCase().includes('charge') || field.toLowerCase().includes('duty') || field.toLowerCase().includes('discount') || field.toLowerCase().includes('tax')));
}

export function toNumberFormat(n, { locale = 'ko-KR' } = {}) {
  if (typeof n !== 'number') {
    return '0';
  }
  const formatter = new Intl.NumberFormat(locale);
  return formatter.format(n);
}

export function toDateFromFormat(timestamp) {
  const date = new Date(timestamp);
  let month = date.getMonth() + 1;
  let day = date.getDate();

  if (date.getFullYear() < 2000) {
    return null;
  }

  month = month >= 10 ? month : `0${month}`;
  day = day >= 10 ? day : `0${day}`;

  return `${date.getFullYear()}-${month}-${day}`;
}
