<template>
  <div v-if="$store.getters.hasSession" :class="containerClass" @click="onWrapperClick" :style="{ padding: '1.5rem' }">
    <router-view />
  </div>
  <div v-else class="checking-login h-screen bg-cover flex flex-column justify-content-center align-items-center">
    <div class="loading-bar flex justify-content-center align-items-center">
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
    </div>
    <div
      class="text-white text-center mt-4"
      style="font-size: 14px; font-weight: 700; line-height: 16px; letter-spacing: 0em;"
    >
      로그인 정보 확인 중...
    </div>
  </div>
</template>

<script>
import '@/styles/color.css';
import '@/styles/font.css';

export default {
  data() {
    return {
      layoutMode: 'static',
      layoutColorMode: 'light',
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      menu: [
        {
          label: 'Upload Order',
          items: [
            { label: 'HM Intl`', icon: 'pi pi-fw pi-cloud-upload', to: '/upload-orders/hm-intl' },
          ],
        },
        {
          label: 'Export Declaration',
          items: [
            { label: '윤슬', icon: 'pi pi-fw pi-cloud-register', to: '/export-declarations/yoonseul' },
            { label: '제이엘', icon: 'pi pi-fw pi-cloud-register', to: '/export-declarations/jl' },
          ],
        },
        {
          label: '입고 정보',
          items: [
            { label: '입고 정보 업로드', icon: 'pi pi-fw pi-cloud-upload', to: '/warehousing/register' },
            { label: '입고 목록', icon: 'pi pi-fw pi-list', to: '/warehousing/list' },
          ],
        },
        {
          label: '주문',
          items: [
            { label: '정산 주문 다운로드', icon: 'pi pi-fw pi-cloud-download', to: '/orders/extract' },
          ],
        },
        {
          label: '고객사 청구서 검증',
          items: [
            { label: '고객사 청구서 목록', icon: 'pi pi-fw pi-list', to: '/account-receivable/invoice/list' },
          ],
        },
        {
          label: '파트너사 청구서 검증',
          items: [
            { label: '파트너사 청구서 업로드', icon: 'pi pi-fw pi-cloud-upload', to: '/account-payable/invoice/register' },
            { label: '파트너사 청구서 목록', icon: 'pi pi-fw pi-exclamation-circle', to: '/account-payable/invoice/list' },
          ],
        },
        {
          label: '계약 정보',
          items: [
            { label: '운임 등록', icon: 'pi pi-fw pi-folder-open', to: '/contract-info/fare-tariff/register' },
            { label: '운임 관리', icon: 'pi pi-fw pi-folder', to: '/contract-info/fare-tariff/management' },
            {
              label: '유류할증료 등록', icon: 'pi pi-fw pi-folder-open', to: '/contract-info/fuel-surcharge/register',
            },
            {
              label: '유류할증료 관리', icon: 'pi pi-fw pi-folder', to: '/contract-info/fuel-surcharge/management',
            },
            {
              label: '환율 등록', icon: 'pi pi-fw pi-folder-open', to: '/contract-info/currency/register',
            },
            {
              label: '환율 관리', icon: 'pi pi-fw pi-folder', to: '/contract-info/currency/management',
            },
            { label: '고객사 관리', icon: 'pi pi-fw pi-user-edit', to: '/contract-info/customers/management' },
          ],
        },
        {
          label: '고객사 요율 정보',
          items: [
            { label: '고객사 요율 등록', icon: 'pi pi-fw pi-folder-open', to: '/contract-info/fare-tariff/partner/register' },
            { label: '고객사 요율 관리', icon: 'pi pi-fw pi-folder', to: '/contract-info/fare-tariff/partner/management' },
          ],
        },
        {
          label: '대역대 송장',
          items: [
            { label: '대역대 조회', icon: 'pi pi-fw pi-folder-open', to: '/prepared-tracking' },
            { label: '대역대 업로드/대시보드', icon: 'pi pi-fw pi-folder-open', to: '/prepared-tracking/upload' },
            { label: '발급사/배송사 관리', icon: 'pi pi-fw pi-folder-open', to: '/prepared-tracking/carriers' },
            { label: '영업소 코드 조회', icon: 'pi pi-fw pi-folder-open', to: '/prepared-tracking/local-office' },
            { label: '영업소 코드 업로드', icon: 'pi pi-fw pi-folder-open', to: '/prepared-tracking/local-office/upload' },
          ],
          // visible: false,
        },
        {
          label: '고객사 관리',
          items: [
            { label: '고객사 목록', icon: 'pi pi-fw pi-folder-open', to: '/customers' },
          ],
        },
      ],
    };
  },
  watch: {
    $route() {
      this.menuActive = false;
      this.$toast.removeAllGroups();
    },
  },
  methods: {
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }

      this.menuClick = false;
    },
    onMenuToggle(event) {
      this.menuClick = true;

      if (this.isDesktop()) {
        if (this.layoutMode === 'overlay') {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }

          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        } else if (this.layoutMode === 'static') {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }

      event.preventDefault();
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode;
    },
    onLayoutColorChange(layoutColorMode) {
      this.layoutColorMode = layoutColorMode;
    },
    addClass(element, className) {
      if (element.classList) element.classList.add(className);
      // eslint-disable-next-line no-param-reassign
      else element.className += ` ${className}`;
    },
    removeClass(element, className) {
      if (element.classList) element.classList.remove(className);
      // eslint-disable-next-line no-param-reassign
      else element.className = element.className.replace(new RegExp(`(^|\\b)${className.split(' ').join('|')}(\\b|$)`, 'gi'), ' ');
    },
    isDesktop() {
      return window.innerWidth >= 992;
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === 'static') return !this.staticMenuInactive;
        if (this.layoutMode === 'overlay') return this.overlayMenuActive;
      }

      return true;
    },
  },
  computed: {
    containerClass() {
      return ['layout-wrapper', {
        'layout-overlay': this.layoutMode === 'overlay',
        'layout-static': this.layoutMode === 'static',
        'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
        'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
        'layout-mobile-sidebar-active': this.mobileMenuActive,
        'p-input-filled': this.$primevue.config.inputStyle === 'filled',
        'p-ripple-disabled': this.$primevue.config.ripple === false,
      }];
    },
    logo() {
      return (this.layoutColorMode === 'dark') ? 'images/logo-white.svg' : 'images/logo.svg';
    },
  },
  beforeUpdate() {
    if (this.mobileMenuActive) this.addClass(document.body, 'body-overflow-hidden');
    else this.removeClass(document.body, 'body-overflow-hidden');
  },
  async mounted() {
    await this.$store.dispatch('refreshSession');
    if (!this.$store.getters.hasSession) {
      const url = new URL(`${process.env.VUE_APP_API_BASE_URL}/session/initialize`);
      url.searchParams.set('redirect_uri', window.location.href);
      window.location.href = url.toString();
    }
  },
  components: {
  },
};
</script>

<style lang="scss">
.p-toast.p-toast-top-right {
  z-index: 1000;
  top: 7rem;
}

.checking-login {
  background-image: url('~@/assets/axb-background.svg');

  @keyframes bar-loading {
    0% {
      transform: scaleY(0.5);
    }
    100% {
      transform: scaleY(1);
    }
  }

  .loading-bar {
    .bar {
      width: 0.875rem;
      height: 5rem;
      background: white;
      margin-left: 0.875rem;
      animation: bar-loading 1s infinite alternate;

      &:nth-child(2) {
        animation-delay: -0.2s;
      }

      &:nth-child(3) {
        animation-delay: -0.4s;
      }
    }
  }
}
</style>
