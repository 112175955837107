import UpsServiceInfo from '@/views/constant/ups-service-info';

const carriers = [
  {
    key: 'eu_post',
    text: 'B2C Europe',
  },
  {
    key: 'ems',
    text: 'E-EMS',
  },
  {
    key: 'fedex',
    text: 'Fedex Express',
  },
  {
    key: 'sf-express',
    text: 'SF Express',
  },
  {
    key: 'au_post',
    text: 'Australia Post',
  },
  {
    key: 'sf',
    text: 'LOTTE(USPS)',
  },
  {
    key: 'ups',
    text: 'UPS',
    data: UpsServiceInfo,
  },
];

export default function getCarrierText(key) {
  const found = carriers.find((value) => value.key === key);
  if (found) {
    return found.text;
  }
  return key?.toUpperCase();
}

function getCarrierData(key) {
  const found = carriers.find((value) => value.key === key);
  if (found) {
    return found.data;
  }
  return null;
}

export { getCarrierData };
