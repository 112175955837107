export default {
  headers: [
    { label: '트래킹번호', key: 'trackingNumber' },
    { label: '신고자 상호', key: 'reporterName' },
    { label: '수출대행자 상호', key: 'exportAgencyName' },
    { label: '수출화주상호', key: 'exportShipperName' },
    { label: '대표자', key: 'agent' },
    { label: '사업자등록번호', key: 'exporterBusinessNumber' },
    { label: '통관고유부호', key: 'customsClearanceCode' },
    { label: '수출자우편번호', key: 'exporterZipCode' },
    { label: '수출자주소', key: 'exporterAddress' },
    { label: '제조자 상호', key: 'manufacturerName' },
    { label: '해외거래처상호', key: 'recipientName' },
    { label: '신고구분', key: 'declarationType' },
    { label: '거래구분', key: 'tradeType' },
    { label: '결제종류', key: 'paymentType' },
    { label: '목적국', key: 'targetCountryCode' },
    { label: '물품소재지 우편번호', key: 'goodsLocationPostalCode' },
    { label: '물품소재지 주소', key: 'goodsLocationAddress' },
    { label: '창고코드', key: 'warehouseCode' },
    { label: '환급신청인', key: 'drawBackRole' },
    { label: '간이환급구분', key: 'drawBackType' },
    { label: '총중량', key: 'totalWeight' },
    { label: '인도조건', key: 'tradeTerms' },
    { label: '결제통화단위', key: 'paymentCurrency' },
    { label: '입력 결제금액' },
    { label: '입력 운임' },
    { label: '세번부호(HSCODE)', key: 'hscode' },
    { label: '순중량', key: 'netWeight' },
    { label: '원산지', key: 'originCountryCode' },
    { label: '품명및규격1', key: 'itemName' },
    { label: '수량', key: 'itemCount' },
    { label: '수량단위', key: 'quantityUnit' },
    { label: '단가', key: 'unitPrice' },
    { label: '결제금액', key: 'price' },
    { label: '포장갯수', key: 'packageCount' },
    { label: '포장단위', key: 'packageUnit' },
    { label: '운송용기', key: 'transportEquipment' },
    { label: '적재항', key: 'loadingLocation' },
    { label: '운송수단', key: 'transportMeans' },
    { label: 'Order No', key: 'orderId' },
    { label: 'Packing No' },
    { label: '조정금액' },
    { label: '총조정금액' },
    { label: '결제방법' },
    { label: '상세주소', key: 'goodsLocationAddressDetail' },
  ],
};
