<template>
  <div class="grid">
    <div :class="styleClass">
      <h6>요금표 파일 업로드</h6>
      <SingleFileSelect :disabled="disabled" accept=".xlsx,.xls,.csv" @change:file="onSelect"/>
    </div>
    <div :class="styleClass" v-if="useTariffDialog">
      <h6>&nbsp;</h6>
      <Button class="p-button-outlined hidden" @click="showConfirmDialog">요금표 가져오기</Button>
      <import-tariff-file-dialog title="요금표 가져오기" v-if="show" @closeDialog="closeDialog"/>
    </div>
  </div>
</template>

<script>
import SingleFileSelect from '@/components/SingleFileSelect.vue';
import * as excelReader from '@/services/excel-reader/excel-reader';
import ImportTariffFileDialog from '@/views/contract-info/fare-tariff/register/ImportTariffFileDialog.vue';
import { mapActions } from 'vuex';

export default {
  components: {
    SingleFileSelect,
    ImportTariffFileDialog,
  },
  props: {
    styleClass: {
      type: String,
    },
    useTariffDialog: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    surchargeType: {
      type: String,
    },
    direction: {
      type: String,
    },
  },
  data() {
    return {
      tariffFiles: [],
      fileItem: [],
      show: false,
    };
  },
  methods: {
    ...mapActions('fareStore', [
      'AC_TARIFF_FILES_PUSH',
      'AC_TARIFF_FILE_CLEAR',
    ]),
    closeDialog() {
      this.show = false;
    },
    showConfirmDialog() {
      this.show = true;
    },
    async onSelect({ file }) {
      this.fileDetail = null;
      if (file) {
        this.fileDetail = { status: 'LOADING' };
        if (!this.surchargeType) {
          this.AC_TARIFF_FILE_CLEAR();
        }
        try {
          const sheets = await excelReader.readSheetAll(file);
          const result = sheets.map((value) => {
            const {
              isValid,
              sheetName,
              rows,
              errors,
            } = value;
            if (isValid) {
              this.status = 'LOADED';
              this.tariffFiles.push({
                tariff: rows,
                type: sheetName,
              });
              return {
                rows,
                status: 'LOADED',
                description: '파일이 정상적으로 로드되었습니다.',
              };
            }
            return {
              status: 'ERROR',
              description: '파일을 인식할 수 없습니다.',
              errors,
            };
          });
          if (result.every((value) => value.status === 'LOADED')) {
            this.tariffFiles.forEach((value) => {
              if (this.surchargeType) {
                // eslint-disable-next-line no-param-reassign
                value.surchargeType = this.surchargeType;
              }
              if (this.direction) {
                // eslint-disable-next-line no-param-reassign
                value.type = this.direction;
              }
              this.AC_TARIFF_FILES_PUSH(value);
            });
            this.tariffFiles = [];
          }
        } catch (e) {
          console.log(e);
        }
      }
    },
  },
};
</script>

<style scoped>

</style>
