<template>
  <fare-api ref="axios"></fare-api>
  <div class="grid pb-2" v-if="tables">
    <div class="flex">
      <div class="flex-initial flex align-items-center justify-content-center px-3">국가코드</div>
      <div class="flex-initial flex align-items-center justify-content-center">
        <Dropdown type="text" label="국가코드" v-model="country" :filter="true" :options="countries" option-label="field"/>
      </div>
    </div>
    <div class="flex">
      <div class="flex-initial flex align-items-center justify-content-center px-3">무게</div>
      <div class="flex-initial flex align-items-center justify-content-center">
        <input-text type="text" label="무게" v-model="weight"/>
      </div>
    </div>
    <div class="flex">
      <div class="flex-initial flex align-items-center justify-content-center px-3">
        <Button @click="calculate">계산</Button>
      </div>
    </div>
    <div class="flex">
      <div class="flex-initial flex align-items-center justify-content-center px-1">
        통화 :
      </div>
      <div class="flex-initial flex align-items-center justify-content-center pr-1">
        {{ currency }}
      </div>
    </div>
    <div class="flex">
      <div class="flex-initial flex align-items-center justify-content-center px-1">
        요금 :
      </div>
      <div class="flex-initial flex align-items-center justify-content-center">
        {{ result }}
      </div>
    </div>
  </div>
  <DataTable :value="tables" responsive-layout="scroll">
    <Column v-for="col in columns" :lazy="true" :field="col.field" :header="col.header" :key="col.field"></Column>
  </DataTable>
</template>

<script>
import FareApi from '@/apis/FareApi.vue';
import { mapActions } from 'vuex';
import CurrencyInfo from '@/views/constant/currency-info';

export default {
  components: {
    FareApi,
  },
  data() {
    return {
      columns: null,
      tables: null,
      country: '',
      weight: '',
      currency: null,
      result: 0,
      zoneList: [],
    };
  },
  props: {
    type: null,
  },
  mounted() {
    this.emitter.on('searchDate', async (request) => {
      await this.showTable(request);
    });
    this.emitter.on('clearTable', () => {
      this.tables = null;
      this.columns = null;
    });
  },
  created() {
  },
  unmounted() {
    this.emitter.off('searchDate');
    this.emitter.off('clearTable');
  },
  methods: {
    ...mapActions('fareStore', [
      'AC_FARE_DATE',
    ]),
    calculate() {
      const cWeight = this.weight;
      let cCountry = this.country.header;
      const found = this.tables.find((value) => ((parseFloat(value.KG) * 1000) - (parseFloat(cWeight) * 1000)) >= 0);
      const zoneCountry = this.zoneList.find((value) => value.국가코드 === cCountry);
      if (zoneCountry) {
        cCountry = zoneCountry.ZONE ?? zoneCountry['ZONE NO'];
      }
      this.result = found !== undefined ? found[Object.keys(found).filter((key) => key === cCountry)] : 0;
    },
    async showTable(request) {
      const fareList = await this.$refs.axios.getFareTariff(request);
      this.tables = null;
      if (fareList.length === 0) {
        this.$toast.add({
          severity: 'error',
          summary: '조회 결과',
          detail: `고객사 : ${request.customerId}, 타입: ${this.type}`,
          life: 3000,
        });
      } else {
        const self = this;
        const zone = fareList.filter((value) => value.fareType === 'ZONE');
        this.zoneList = zone[0] === undefined ? [] : JSON.parse(zone[0].tariffJson);
        fareList.forEach((fare) => {
          if (self.type === fare.fareType) {
            self.columns = Object.keys(JSON.parse(fare.tariffJson)[0]).map((value) => {
              let header = value;
              if (!Number.isNaN(parseInt(value, 10))) {
                header = `ZONE${header}`;
              }
              return { field: value, header };
            }).sort((prev, cur) => {
              if (cur.header.includes('ZONE')) {
                // eslint-disable-next-line no-nested-ternary
                return prev.header < cur.header ? -1 : prev.header > cur.header ? 1 : 0;
              }
              return 1;
            });
            self.countries = self.columns.filter((value) => value.field !== 'KG' || !Number.isNaN(parseInt(value.field, 10)))
              .concat(this.zoneList.map((value) => ({ field: `${value.국가코드}(ZONE ${value.ZONE ?? value['ZONE NO']})`, header: value.국가코드 })));
            self.tables = JSON.parse(fare.tariffJson);
            self.currency = CurrencyInfo.getCurrencyText(fare.currency);
          }
          const date = { startDate: new Date(fare.startDate).getTime(), endDate: new Date(fare.endDate).getTime() };
          self.AC_FARE_DATE(date);
        });
      }
    },
  },
};
</script>
