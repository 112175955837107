<template>
  <fare-api ref="axios"></fare-api>
  <div class="grid flex-nowrap p-fluid">
    <div :class="styleClass" v-if="targets.includes('customer')">
      <DefaultLabel label="고객사"/>
      <Dropdown v-model="customer" @update:modelValue="updateCustomer" editable :options="customers" @filter="selectCustomer"
                @keyup.enter="$emit('enter')"
                @change="selectCustomer" optionLabel="text" placeholder="Select One"></Dropdown>
    </div>
    <div :class="styleClass" v-if="targets.includes('partner')">
      <DefaultLabel label="파트너사"/>
      <Dropdown @keyup.enter="$emit('enter')" v-model="partner" :disabled="partners.length === 0" :options="partners" @change="selectPartner" optionLabel="text" placeholder="Select One"></Dropdown>
    </div>
    <div :class="styleClass" v-if="targets.includes('carrier')">
      <DefaultLabel label="배송사"/>
      <Dropdown @keyup.enter="$emit('enter')" v-model="carrier" :disabled="carriers.length === 0" :options="carriers" @change="selectCarrier" optionLabel="text" placeholder="Select One"></Dropdown>
    </div>
    <div :class="styleClass" v-if="usingDate">
      <DefaultLabel :label="dateTitle"/>
      <Dropdown v-if="filteredMode"
                @keyup.enter="$emit('enter')"
                v-model="term" :disabled="terms.length === 0"
                :options="terms" @change="selectTerms" optionLabel="text" placeholder="Select One"></Dropdown>
      <calendar v-else v-model="term" selectionMode="range" :disabled="showDate" :showButtonBar="true"
                @clear-click="clearTerm" @date-select="selectTerm" @keyup.enter="$emit('enter')" @hide="$emit('close')" date-format="yy.mm.dd" :show-icon="true"/>
    </div>
  </div>
</template>

<script>

import FareApi from '@/apis/FareApi.vue';
import DefaultLabel from '@/components/DefaultLabel.vue';
import { mapActions } from 'vuex';
import { toDateFromFormat } from '@/utils/FormatUtil';

export default {
  components: {
    FareApi,
    DefaultLabel,
  },
  props: {
    styleClass: {
      type: String,
    },
    filteredMode: {
      type: Boolean,
      default: false,
    },
    usingDate: {
      type: Boolean,
      default: true,
    },
    dateTitle: {
      type: String,
    },
    targets: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: () => ['customer', 'carrier', 'partner'],
    },
    customersFilter: {
      type: Object,
    },
    carriersFilter: {
      type: Object,
    },
    partnersFilter: {
      type: Object,
    },
  },
  data() {
    return {
      customer: null,
      carrier: null,
      partner: null,
      customerMap: [],
      term: null,
      terms: [],
      customers: [],
      partners: [],
      carriers: [],
    };
  },
  computed: {
    showDate() {
      return this.targets.length === 3 ? this.customer === null || this.partner === null || this.carrier === null : false;
    },
  },
  methods: {
    ...mapActions('fareStore', [
      'AC_SELECT_CUSTOMER',
      'AC_SELECT_CARRIER',
      'AC_SELECT_PARTNER',
      'AC_TERM',
    ]),
    updateCustomer() {
      this.AC_SELECT_CUSTOMER({ key: this.customer, text: this.customer });
    },
    async selectCustomer() {
      if (this.customer !== null) {
        const customerObject = this.customer.key === undefined ? { key: this.customer, text: this.customer } : this.customer;
        this.AC_SELECT_CUSTOMER(customerObject);
        if (this.filteredMode) {
          this.clear();
          const filteredKey = this.customerMap.filter((value) => value.customerId === (this.customer.key ?? this.customer))
            .map(((value) => value.partnerId));
          console.log(filteredKey);
          const partnerList = await this.$refs.axios.getPartnerList();
          this.partners = partnerList.filter((value) => filteredKey.includes(value.key));
          console.log(this.partners);
        }
      }
    },
    async selectCarrier() {
      this.AC_SELECT_CARRIER(this.carrier);
      if (this.filteredMode) {
        const request = {
          customerId: this.customer.key ?? this.customer,
          carrierId: this.carrier.key,
          partnerId: this.partner.key,
        };
        const fareDateList = await this.$refs.axios.getFareDateList(request);
        this.terms = fareDateList.filter((value) => !(value.fareType.includes('CURRENCY') || value.fareType.includes('FSC_AP') || value.fareType.includes('FSC_AR')))
          .map((v, i) => ({
            key: i,
            text: `${toDateFromFormat(v.startDate)} ~ ${toDateFromFormat(v.endDate)}`,
            data: [new Date(v.startDate), new Date(v.endDate)],
          }));
      }
    },
    async selectPartner() {
      this.AC_SELECT_PARTNER(this.partner);
      if (this.filteredMode) {
        const filteredKey = this.customerMap.filter((value) => value.customerId === (this.customer.key ?? this.customer) && value.partnerId === this.partner.key)
          .map(((value) => value.carrierId));
        const carrierList = await this.$refs.axios.getCarrierList();
        this.carriers = carrierList.filter((value) => filteredKey.includes(value.key));
      }
    },
    selectTerm() {
      this.AC_TERM(this.term);
    },
    clearTerm() {
      this.AC_TERM(this.term = []);
    },
    selectTerms() {
      this.AC_TERM(this.term.data);
      this.$emit('close');
    },
    clear() {
      this.partners = [];
      this.AC_SELECT_PARTNER(this.partner = { });
      this.carriers = [];
      this.AC_SELECT_CARRIER(this.carrier = { });
      this.clearTerm();
      this.terms = [];
      this.emitter.emit('clearTable');
    },
    clearWithCustomer() {
      this.AC_SELECT_CUSTOMER(this.customer = { });
      this.clear();
    },
  },
  watch: {
    customer() {
      this.selectCustomer();
    },
    partner: 'showDate',
    carrier: 'showDate',
  },
  unmounted() {
    this.clearWithCustomer();
  },
  async mounted() {
    this.customers = await this.$refs.axios.getCustomerList();
    if (this.customersFilter) {
      this.customers = this.customers.filter((v) => this.customersFilter?.includes(v.key));
    }
    if (this.filteredMode) {
      this.customerMap = await this.$refs.axios.getCustomerMapList();
    } else {
      this.partners = await this.$refs.axios.getPartnerList();
      if (this.partnersFilter) {
        this.partners = this.partners.filter((v) => this.partnersFilter?.includes(v.key));
      }
      this.carriers = await this.$refs.axios.getCarrierList();
      if (this.carriersFilter) {
        this.carriers = this.carriers.filter((v) => this.carriersFilter?.includes(v.key));
      }
    }
  },
};
</script>

<style scoped>
</style>
