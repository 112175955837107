<template>
  <div>
    <main-card title="입고 목록">
      <template v-slot:content>
        <div class="grid">
          <div class="col-4">
            <customer-dropdown-group @enter="search" ref="customerDropdownGroup" date-title="입고 기간" style-class="md:col-6" targets="[customer]"></customer-dropdown-group>
          </div>
          <div class="col-8">
            <div class="grid flex-nowrap p-fluid">
              <div class="col-2">
                <h6>주문상태</h6>
                <Dropdown v-model="orderStatus" @keyup.enter="search" :options="statusMap" option-label="title" placeholder="선택하세요."></Dropdown>
              </div>
              <div class="col-2">
                <h6>주문번호</h6>
                <input-text v-model="orderName" @keyup.enter="search" placeholder="입력하세요."></input-text>
              </div>
              <div class="col-2">
                <h6>운송장번호</h6>
                <input-text v-model="trackingNumber" @keyup.enter="search" placeholder="입력하세요."></input-text>
              </div>
              <div class="flex align-items-end justify-content-center p-2">
                <Button icon="pi pi-undo" @click="refresh"></Button>
              </div>
              <div class="flex align-items-end justify-content-center p-2">
                <Button icon="pi pi-search" @click="search"></Button>
              </div>
              <div class="flex align-items-end justify-content-center p-2" v-if="false">
                <Button @click="openCreateArModal">AR 생성</Button>
              </div>
              <div class="flex align-items-end justify-content-center p-2">
                <Button @click="exportCSV">입고 목록 다운로드</Button>
              </div>
            </div>
            <create-ar-invoice-dialog v-if="openModal" @closeDialog="closeDialog"></create-ar-invoice-dialog>
          </div>
        </div>
        <order-table ref="dt"></order-table>
      </template>
    </main-card>
  </div>
</template>

<script>

import OrderTable from '@/views/warehousing/list/WarehousingTable.vue';
import CustomerDropdownGroup from '@/components/CustomerDropdownGroup.vue';
import { mapActions, mapGetters } from 'vuex';
import WarehousingInfo from '@/views/constant/warehousing-info';
import CreateArInvoiceDialog from '@/views/warehousing/list/CreateArInvoiceDialog.vue';

export default {
  name: 'WarehousingList',
  components: {
    CreateArInvoiceDialog,
    CustomerDropdownGroup,
    OrderTable,
  },
  methods: {
    ...mapActions('orderStore', [
      'AC_SELECT_STATUS',
      'AC_SELECT_ORDER',
      'AC_SELECT_TRACKING_NUMBER',
    ]),
    ...mapActions('fareStore', [
      'AC_SELECT_CUSTOMER',
      'AC_TERM',
    ]),
    search() {
      this.emitter.emit('searchTable');
    },
    refresh() {
      this.orderStatus = null;
      this.orderName = null;
      this.trackingNumber = null;
      this.$refs.customerDropdownGroup.clearWithCustomer();
    },
    async exportCSV() {
      await this.$refs.dt.exportExcel();
    },
    openCreateArModal() {
      if (this.GE_SELECT_CUSTOMER.key !== null && this.GE_TERM.length !== 0) {
        this.openModal = true;
      } else {
        this.$toast.add({
          severity: 'error',
          summary: '고객사 또는 입고 기간을 선택하세요.',
          life: 3000,
        });
      }
    },
    closeDialog() {
      this.openModal = false;
    },
  },
  computed: {
    ...mapGetters('orderStore', [
      'GE_SELECT_STATUS',
      'GE_SELECT_ORDER',
      'GE_SELECT_TRACKING_NUMBER',
    ]),
    ...mapGetters('fareStore', [
      'GE_SELECT_CUSTOMER',
      'GE_TERM',
      'GE_START_DATE',
      'GE_END_DATE',
    ]),
    orderStatus: {
      get() {
        return this.GE_SELECT_STATUS;
      },
      set(value) {
        this.AC_SELECT_STATUS(value);
      },
    },
    orderName: {
      get() {
        return this.GE_SELECT_ORDER;
      },
      set(value) {
        this.AC_SELECT_ORDER(value);
      },
    },
    trackingNumber: {
      get() {
        return this.GE_SELECT_TRACKING_NUMBER;
      },
      set(value) {
        this.AC_SELECT_TRACKING_NUMBER(value);
      },
    },
  },
  data() {
    return {
      statusMap: WarehousingInfo.statusMap,
      openModal: false,
    };
  },
};
</script>
