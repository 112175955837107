export default {
  currencyMap: [{ key: 'KRW', text: '원화' }, { key: 'JPY', text: '엔화' }, { key: 'USD', text: '미국 달러' }],
  getCurrencyText(key) {
    const found = this.currencyMap.find((value) => value.key === key);
    if (found) {
      return found.text;
    }
    return key.toUpperCase();
  },
};
