<template>
  <div>
    <account-receivable-api ref="axios"></account-receivable-api>
    <DataTable :value="tables" responsive-layout="scroll"
               class="p-datatable-gridlines"
               :paginator="true"
               :rowHover="true"
               :total-records="totalCount"
               @page="onPage($event)"
               :lazy="true"
               :rows="rowsCount"
               ref="dt"
    >
      <template #empty>
        결과 없음
      </template>
      <template #header>
        <div style="text-align: right">
          <Button icon="pi pi-external-link" label="Export" @click="exportCSV($event)" />
        </div>
      </template>
      <Column v-for="col in columns" :lazy="true" :field="col.field" :header="col.header" :key="col.field">
        <template #body="{data}">
          <skeleton v-if="progress"></skeleton>
          <span v-else-if="Number.isInteger(data[col.field])" >{{ toNumberFormat(data[col.field]) }}</span>
          <span v-else-if="col.field === 'status'">{{ convertStatus(data[col.field]) }}</span>
          <span v-else-if="col.field === 'carrierId'">{{ getCarrierText(data[col.field]) }}</span>
          <span v-else-if="col.field === 'customerId'">{{ getCustomerText(data[col.field]) }}</span>
          <span v-else-if="col.field === 'shipmentDate'" >{{ toDateFromFormat(data.shipmentDate) }}</span>
          <span v-else>{{ data[col.field] }}</span>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import apItemColumns from '@/views/account-payable/detail/apItem-columns';
import { toDateFromFormat, toNumberFormat } from '@/utils/FormatUtil';
import getCarrierText from '@/views/constant/carriers-info';
import getCustomerText from '@/views/constant/customers-info';
import AccountReceivableApi from '@/apis/AccountReceivableApi.vue';

export default {
  components: {
    AccountReceivableApi,
  },
  data() {
    return {
      tables: null,
      totalCount: null,
      progress: true,
      columns: apItemColumns.columns,
      rowsCount: 10,
    };
  },
  methods: {
    async exportCSV() {
      await this.loadTable(0, this.totalCount);
      this.$refs.dt.exportCSV();
    },
    async onPage(event) {
      this.progress = true;
      await this.loadTable(event.page, event.rowCount);
    },
    convertStatus(status) {
      const statusMap = [{ key: 'OVER_CHARGED', title: '파트너사 과청구' },
        { key: 'UNDER_CHARGED', title: 'AXB 과청구' },
        { key: 'NOT_VERIFIED', title: '검증 미완료' },
        { key: 'VERIFIED', title: '검증 완료' }];
      return statusMap.find((value) => value.key === status).title;
    },
    toDateFromFormat,
    toNumberFormat,
    getCarrierText,
    getCustomerText,
    async loadTable(page, rowCount) {
      const { list, totalCount } = await this.$refs.axios.getInvoiceArItemList(this.$route.query.invoiceId, page, rowCount);
      this.tables = list;
      this.totalCount = totalCount;
      this.progress = false;
    },
  },
  async mounted() {
    await this.loadTable(0, this.rowsCount);
  },
};
</script>

<style scoped>

</style>
