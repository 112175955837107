<template>
  <div>
    <fare-api ref="axios"></fare-api>
    <main-card title="유류할증료 등록" :progress="progress">
      <template v-slot:content>
        <customer-dropdown-group @close="checkFscDate" style-class="col-3" date-title="유류할증료 적용 기간"></customer-dropdown-group>
        <duplicated-fare-dialog title="중복 유류할증료" v-if="duplicatedFsc !== null" :result="duplicatedFsc" @closeDialog="closeDialog"></duplicated-fare-dialog>
        <charge-drop-down-group direction="AP" title="파트너사 → 어크로스비 청구 방법" surcharge-type="FSC" />
        <charge-drop-down-group direction="AR" title="어크로스비 → 고객사 청구 방법" surcharge-type="FSC" />
      </template>
    </main-card>
    <div class="flex justify-content-end flex-wrap">
      <div class="flex align-items-center">
        <Button :disabled="progress" label="업로드" @click="register"></Button>
      </div>
    </div>
  </div>
</template>

<script>

import getCustomerText from '@/views/constant/customers-info';
import getCarrierText from '@/views/constant/carriers-info';
import getPartnerText from '@/views/constant/partners-info';
import CustomerDropdownGroup from '@/components/CustomerDropdownGroup.vue';
import { mapGetters } from 'vuex';
import FareApi from '@/apis/FareApi.vue';
import DuplicatedFareDialog from '@/views/contract-info/fare-tariff/register/DuplicatedFareDialog.vue';
import ChargeDropDownGroup from '@/components/ChargeDropDownGroup.vue';

export default {
  name: 'FSCRegister',
  components: {
    FareApi,
    CustomerDropdownGroup,
    DuplicatedFareDialog,
    ChargeDropDownGroup,
  },
  data() {
    return {
      progress: false,
      loadedFileInfo: {},
      duplicatedFsc: null,
      chargePriceAP: null,
      chargePriceAR: null,
      term: null,
    };
  },
  methods: {
    closeDialog() {
      this.duplicatedFsc = null;
    },
    async checkFscDate() {
      const defaultData = {
        customerId: this.GE_SELECT_CUSTOMER.key,
        carrierId: this.GE_SELECT_CARRIER.key,
        partnerId: this.GE_SELECT_PARTNER.key,
        startDate: this.GE_START_DATE,
        endDate: this.GE_END_DATE,
      };
      defaultData.fareType = 'FSC_AR';
      let result = await this.$refs.axios.checkFareDate(defaultData);
      if (result === '') {
        defaultData.fareType = 'FSC_AP';
        result = await this.$refs.axios.checkFareDate(defaultData);
      }
      this.duplicatedFsc = result === '' ? null : result;
    },
    async register() {
      const registerFareList = [];
      for (const item of this.GE_SURCHARGES) {
        if (item.key !== 'TARIFF') {
          const registerFareData = {
            customerId: this.GE_SELECT_CUSTOMER.key,
            carrierId: this.GE_SELECT_CARRIER.key,
            partnerId: this.GE_SELECT_PARTNER.key,
            fareType: `${item.surchargeType}_${item.direction}`,
            currency: 'KRW',
            startDate: this.GE_START_DATE,
            endDate: this.GE_END_DATE,
            fromCountryCode: 'KR',
            tariffJson: null,
            chargePrice: item.value,
            tariffType: item.key,
          };
          registerFareList.push(registerFareData);
        }
      }

      this.progress = true;

      const result = await this.$refs.axios.registerFare(registerFareList);

      this.progress = false;

      if (!result) {
        this.$toast.add({
          severity: 'success',
          summary: '유류할증료 등록 성공',
          detail: `고객사 : ${this.GE_SELECT_CUSTOMER.text}`,
          life: 3000,
        });
      }
    },
    getCustomerText,
    getCarrierText,
    getPartnerText,
  },
  computed: {
    ...mapGetters(
      'fareStore',
      [
        'GE_SELECT_CUSTOMER',
        'GE_SELECT_CARRIER',
        'GE_SELECT_PARTNER',
        'GE_START_DATE',
        'GE_END_DATE',
        'GE_SURCHARGES',
      ],
    ),
  },
};
</script>

<style scoped lang="scss">
.month-select-calendar {
  width: 140px;
}
.upload-file-info__label {
  padding: 0.5rem;
  width: 120px
}
</style>
