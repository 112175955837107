<template>
  <div>
    <order-api ref="axios"></order-api>
    <DataTable :value="tables" responsive-layout="scroll"
               class="p-datatable-gridlines"
               :paginator="true"
               :rowHover="true"
               :total-records="totalCount"
               @page="onPage($event)"
               :loading="isDownloading"
               :lazy="true"
               :rows="10"
               ref="dt"
    >
      <template #empty>
        결과 없음
      </template>
      <Column field="status" header="주문상태">
        <template #body="{data}">
          {{ convertStatus(data.status) }}
        </template>
      </Column>
      <Column field="orderName" header="주문번호">
        <template #body="{data}">
          <a :href="'https://' + data.customerId + '.acrossb.net/order?orderId=' + data.orderId + '&storeId=' + data.storeId + '&groupId=' + data.groupId" target="_blank">
            {{ data.orderName }}
          </a>
        </template>
      </Column>
      <Column field="packingNumber" header="출고번호">
      </Column>
      <Column field="trackingNumber" header="운송장번호">
        <template #body="{data}">
          <a :href=data.trackingUrl target="_blank">
            {{ data.trackingNumber }}
          </a>
        </template>
      </Column>
      <Column field="customerId" header="고객사">
        <template #body="{data}">
          <span>{{ getCustomerText(data.customerId) }}</span>
        </template>
      </Column>
      <Column field="partnerId" header="파트너사">
        <template #body="{data}">
          <span>{{ getPartnerText(data.partnerId) }}</span>
        </template>
      </Column>
      <Column field="carrierId" header="배송사">
        <template #body="{data}">
          <span>{{ getCarrierText(data.carrierId) }}</span>
        </template>
      </Column>
      <Column field="warehousingDate" header="입고일">
        <template #body="{data}">
          <span>{{ toDateFromFormat(data.warehousingDate) ?? '-' }}</span>
        </template>
      </Column>
      <Column field="orderCreatedAt" header="생성일">
        <template #body="{data}">
          <span>{{ toDateFromFormat(data.orderCreatedAt) ?? '-' }}</span>
        </template>
      </Column>
      <Column field="weight" header="실무게">
        <template #body="{data}">
          <span>{{ data.weight ?? '-' }}</span>
        </template>
      </Column>
      <Column field="chargeableWeight" header="청구무게">
        <template #body="{data}">
          <span>{{ data.chargeableWeight ?? '-' }}</span>
        </template>
      </Column>
      <Column field="dimWidth" header="Width">
        <template #body="{data}">
          <span>{{ data.dimWidth ?? '-' }}</span>
        </template>
      </Column>
      <Column field="dimLength" header="Length">
        <template #body="{data}">
          <span>{{ data.dimLength ?? '-' }}</span>
        </template>
      </Column>
      <Column field="dimHeight" header="Height">
        <template #body="{data}">
          <span>{{ data.dimHeight ?? '-' }}</span>
        </template>
      </Column>
      <Column field="dimUnit" header="단위">
        <template #body="{data}">
          <span>{{ data.dimUnit ?? '-' }}</span>
        </template>
      </Column>
      <Column field="volumeWeight" header="부피무게">
        <template #body="{data}">
          <span>{{ data.volumeWeight ?? '-' }}</span>
        </template>
      </Column>
      <Column field="fromCountryCode" header="배송국가"></Column>
      <Column field="toCountryCode" header="도착국가"></Column>
    </DataTable>
  </div>
</template>

<script>
import OrderApi from '@/apis/OrderApi.vue';
import { toDateFromFormat } from '@/utils/FormatUtil';
import getCarrierText from '@/views/constant/carriers-info';
import getCustomerText from '@/views/constant/customers-info';
import getPartnerText from '@/views/constant/partners-info';
import { mapGetters } from 'vuex';
import WarehousingInfo from '@/views/constant/warehousing-info';
import * as excelFileWriter from '@/services/excel-writer/excel-writer';
import ExportOrders from '@/views/warehousing/list/export-orders';

function findMinMaxDates(dateList) {
  if (dateList.length === 0) {
    return { earliestDate: null, latestDate: null };
  }

  // 배열을 날짜 오름차순으로 정렬합니다.
  dateList.sort((a, b) => new Date(a) - new Date(b));

  // 정렬된 배열에서 가장 빠른 날짜와 가장 늦은 날짜를 찾습니다.
  const earliestDate = dateList[0];
  const latestDate = dateList[dateList.length - 1];

  return { earliestDate, latestDate };
}
export default {
  components: {
    OrderApi,
  },
  data() {
    return {
      tables: null,
      totalCount: null,
      progress: true,
      isDownloading: false,
    };
  },
  methods: {
    async onPage(event) {
      this.progress = true;
      await this.loadTable(event.page, event.rowCount);
      this.progress = false;
    },
    toDateFromFormat,
    getCarrierText,
    getCustomerText,
    getPartnerText,
    convertStatus(key) {
      return WarehousingInfo.getWareHousingStatus(key);
    },
    async loadTable(page, rowCount) {
      const { list, totalCount } = await this.loadData(page, rowCount);
      this.tables = list.map((item, index) => ({ no: index + 1, ...item }));
      this.totalCount = totalCount;
    },
    async loadData(page, rowCount) {
      const request = {
        customerId: this.GE_SELECT_CUSTOMER.key,
        orderName: this.GE_SELECT_ORDER,
        status: this.GE_SELECT_STATUS?.key,
        trackingNumber: this.GE_SELECT_TRACKING_NUMBER,
        startDate: this.GE_TERM[0]?.getTime(),
        endDate: this.GE_TERM[1]?.getTime(),
        page,
        rowCount,
      };
      const { list, totalCount } = await this.$refs.axios.getOrderItemList(request);
      return { list, totalCount };
    },
    async exportExcel() {
      this.isDownloading = true;
      const { list, totalCount } = await this.loadData(0, this.totalCount);

      const dateList = list.map((order) => toDateFromFormat(order.orderCreatedAt)).filter((i) => i);

      const { earliestDate, latestDate } = findMinMaxDates(dateList);
      await excelFileWriter.writeFile({
        headers: ExportOrders.headers,
        rows: list.sort((a, b) => new Date(b.orderCreatedAt) - new Date(a.orderCreatedAt)).map((order, index) => ({
          ...order,
          no: index + 1,
          status: order.status === 'NOT_IN_STOCK' ? '미입고' : '입고',
          orderCreatedAt: toDateFromFormat(order.orderCreatedAt) ?? '-',
          warehousingDate: toDateFromFormat(order.warehousingDate) ?? '-',
        })),
      }, { filename: `${this.GE_SELECT_CUSTOMER.text ?? '전체'}_입고목록_${earliestDate}-${latestDate}(${totalCount}).xlsx` });
      this.isDownloading = false;
    },
  },
  computed: {
    ...mapGetters('fareStore', [
      'GE_SELECT_CUSTOMER',
      'GE_TERM',
    ]),
    ...mapGetters('orderStore', [
      'GE_SELECT_ORDER',
      'GE_SELECT_TRACKING_NUMBER',
      'GE_SELECT_STATUS',
    ]),
  },
  async mounted() {
    this.emitter.on('searchTable', async () => {
      await this.loadTable(0, 10);
    });
    await this.loadTable(0, 10);
  },
};
</script>

<style scoped>

</style>
