<script>
import axios from 'axios';
import { sumCharge } from '@/utils/FormatUtil';
import getErrorMsg from '@/apis/error-handler';

export default {
  components: {
  },
  data() {
    return {
      API_BASE_URL: `${process.env.VUE_APP_API_BASE_URL}/settlement`,
      api: axios.create(),
    };
  },
  created() {
    this.api.interceptors.response.use(
      (res) => res,
      (err) => {
        this.$toast.add(getErrorMsg(err));
        return err;
      },
    );
  },
  methods: {
    getErrorMsg,
    async registerAp(request) {
      const { data } = await this.api.put(`${this.API_BASE_URL}/account-payable/register`, request);
      return data;
    },
    async getInvoiceApList(page, rowCount) {
      const { data } = await this.api.post(`${this.API_BASE_URL}/account-payable/list`, { page, rowCount });
      return data;
    },
    async getInvoiceApItemList(invoiceId, status, trackingNumber, carrierId, page, rowCount) {
      const { data } = await this.api.post(`${this.API_BASE_URL}/account-payable/item/list`, {
        invoiceId, status, trackingNumber, carrierId, page, rowCount,
      });
      data.list.map((item) => {
        // eslint-disable-next-line no-param-reassign
        item.totalPrice = sumCharge(item);
        return item;
      });
      return data;
    },
    async validateInvoiceApItem(invoiceApId) {
      const { data } = await this.api.post(`${this.API_BASE_URL}/account-payable/item/list/validate`, { invoiceApId });
      return data;
    },
    async getMismatchApItemList(invoiceApId, page, rowCount) {
      const { data } = await this.api.post(`${this.API_BASE_URL}/account-payable/item/list/mismatch`, { invoiceApId, page, rowCount });
      return data;
    },
    async getSummary(invoiceApId) {
      const { data } = await this.api.post(`${this.API_BASE_URL}/account-payable/item/summary`, { invoiceApId });
      return data;
    },
    async deleteAllByInvoiceApId(list) {
      const { data } = await this.api.delete(`${this.API_BASE_URL}/account-payable/list`, { data: { invoiceIds: list } });
      return data;
    },
  },
};
</script>
