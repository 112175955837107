<template>
  <div>
    <fare-api ref="axios"></fare-api>
    <main-card title="고객사 관리">
      <template v-slot:content>
        <div class="grid flex justify-content-end flex-wrap">
          <div class="flex m-2">
            <Button label="추가" icon="pi pi-plus"  @click="openDialog"></Button>
          </div>
          <div class="flex m-2">
            <Button label="삭제" icon="pi pi-trash"  @click="deleteCustomers"></Button>
          </div>
        </div>
        <Dialog header="고객사 추가" v-model:visible="openModal" :modal="true">
          <div class="flex">
            <div class="flex flex-column">
              <DefaultLabel label="ID"/>
              <InputText v-model="id"></InputText>
            </div>
            <div class="flex flex-column pl-2">
              <DefaultLabel label="이름"/>
              <InputText v-model="name"></InputText>
            </div>
          </div>
          <template #footer>
            <Button label="No" icon="pi pi-times" @click="closeDialog" class="p-button-text"/>
            <Button label="Yes" icon="pi pi-check" @click="addCustomer" autofocus />
          </template>
        </Dialog>
        <DataTable :value="tables" v-model:selection="selectedIndex"
                   responsive-layout="scroll">
          <Column selectionMode="multiple"></Column>
          <Column v-for="col in [{ field: 'key', header: 'ID' }, { field: 'text', header: '이름' }]" :lazy="true" :field="col.field" :header="col.header" :key="col.field"></Column>
        </DataTable>
      </template>
    </main-card>
  </div>
</template>

<script>
import FareApi from '@/apis/FareApi.vue';
import DefaultLabel from '@/components/DefaultLabel.vue';
import Dialog from 'primevue/dialog';

export default {
  name: 'CustomersManagement',
  components: {
    FareApi,
    Dialog,
    DefaultLabel,
  },
  methods: {
    async deleteCustomers() {
      const result = await this.$refs.axios.deleteCustomers(this.selectedIndex.map((v) => v.key));
      if (result) {
        this.$toast.add({
          severity: 'success',
          summary: '삭제 완료.',
          life: 3000,
        });
        await this.showTable();
      }
    },
    openDialog() {
      this.openModal = true;
    },
    async addCustomer() {
      if (this.id === null || this.id === '') {
        this.$toast.add({
          severity: 'error',
          summary: 'ID 를 입력하세요.',
          life: 3000,
        });
        return;
      }

      if (this.name === null || this.name === '') {
        this.$toast.add({
          severity: 'error',
          summary: '이름을 입력하세요.',
          life: 3000,
        });
        return;
      }

      await this.$refs.axios.addCustomer(this.id, this.name);
      this.$toast.add({
        severity: 'success',
        summary: '등록하였습니다.',
        life: 3000,
      });
      await this.showTable();
      this.openModal = false;
    },
    closeDialog() {
      this.openModal = false;
    },
    async showTable() {
      const data = await this.$refs.axios.getCustomerList();
      this.tables = data;
    },
  },
  data() {
    return {
      openModal: false,
      tables: null,
      selectedIndex: [],
      id: null,
      name: null,
    };
  },
  async mounted() {
    await this.showTable();
  },
};
</script>
