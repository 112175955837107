import { DateTime } from 'luxon';
import * as excelReader from '@/services/excel-reader/excel-reader';

export default {
  partnerId: 'sf-express',
  columns: [
    [],
    [],
    [],
    [
      {
        column: 'A',
        text: 'NO.',
        key: 'no',
      },
      {
        column: 'B',
        text: 'Invoice Date',
      },
      {
        column: 'C',
        text: 'Invoice NO.',
      },
      {
        column: 'D',
        text: 'WAYBILL NO.',
        key: 'trackingNumber',
      },
      {
        column: 'E',
        text: 'Billing Date',
      },
      {
        column: 'F',
        text: 'Pick-up Date',
        key: 'shipmentDate',
      },
      {
        column: 'G',
        text: 'Delivery Date',
      },
      {
        column: 'H',
        text: 'Customer Reference',
        key: 'orderName',
      },
      {
        column: 'I',
        text: 'Chargeable unit',
      },
      {
        column: 'J',
        text: 'Chargeable Weight',
        key: 'chargeableWeight',
      },
      {
        column: 'K',
        text: 'Product',
      },
      {
        column: 'L',
        text: 'Consignee Company Name',
      },
      {
        column: 'M',
        text: 'Consignee Destination',
        key: 'toCountryCode',
      },
      {
        column: 'T',
        text: 'Shipper Origin (Country/Region)',
        key: 'fromCountryCode',
      },
      {
        column: 'X',
        text: 'Shipper Contact Name',
        key: 'customerId',
      },
      {
        column: 'Z',
        text: 'Currency',
        key: 'currency',
      },
      {
        column: 'AA',
        text: 'Charge Type',
        key: 'chargeType',
      },
      {
        column: 'AB',
        text: 'Charge Amount',
      },
      {
        column: 'AC',
        text: 'Discount Amount',
      },
      {
        column: 'AD',
        text: 'Deduction Amount',
      },
      {
        column: 'AE',
        text: 'GST / VAT Amount',
      },
      {
        column: 'AF',
        text: 'Net Amount',
        key: 'netAmount',
      },
    ]],
  convert(rows) {
    const map = new Map();
    rows.forEach((value) => {
      if (!value.trackingNumber) {
        return;
      }

      const originValue = map.get(value.trackingNumber) ?? value;
      const netAmount = Number.isNaN(parseInt(value.netAmount?.replace(/[,]/g, ''), 10)) ? 0 : parseInt(value.netAmount?.replace(/[,]/g, ''), 10);
      switch (value.chargeType) {
        case 'Freight Charges':
          originValue.freightCharge = netAmount;
          break;
        case 'Fuel Surcharge':
          originValue.fscSurcharge = netAmount;
          break;
        case 'Temporary Surcharge':
          originValue.emergencySurcharge = netAmount;
          break;
        default:
          originValue.additionalSurcharge = netAmount;
      }
      originValue.carrierId = 'sf-express';
      originValue.partnerId = 'sf-express';
      originValue.customerId = this.convertCustomer(originValue.customerId);
      map.set(originValue.trackingNumber, originValue);
    });
    return Array.from(map.values()).filter((v) => !Number.isNaN(parseInt(v.no, 10)))
      .filter((v) => v.shipmentDate && DateTime.fromFormat(v.shipmentDate?.replace(/[/.-]/g, ' '), 'yyyy L d').isValid);
  },
  customers(rows) {
    return rows.reduce((acc, cur) => {
      if (!acc.includes(cur.customerId)) {
        acc.push(cur.customerId);
      }
      return acc;
    }, [])
      .map((v) => this.convertCustomer(v));
  },
  carriers() {
    return ['sf-express'];
  },
  convertCustomer(customer) {
    switch (customer) {
      case 'Konny by Erin':
        return 'konny';
      case 'thisisneverthat':
        return 'jknd';
      case 'JKND Co. Ltd (thisisneverthat)':
        return 'jknd';
      case 'JKND':
        return 'jknd';
      default:
        return customer;
    }
  },
  async read(file) {
    const {
      isValid, rows, errors,
    } = await excelReader.read(this.columns, file);
    if (isValid) {
      const convertRows = this.convert(rows);
      const carrierIds = this.carriers();
      if (errors) console.log(errors);
      return {
        isValid, customerIds: this.customers(convertRows), partnerId: this.partnerId, carrierIds, rows: convertRows,
      };
    }
    return { isValid };
  },
};
