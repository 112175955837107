<template>
  <confirm-dialog :title="title">
    <template v-slot:default="{  }">
      <div class="pb-4">
        선택 기간에 이미 등록한 운임 정보가 있습니다.<br/>
        기간을 다시 선택 해주세요.
      </div>
      <div><span>&#8226;</span>고객사 : {{ this.GE_SELECT_CUSTOMER.text }}</div>
      <div><span>&#8226;</span>파트너사 : {{ this.GE_SELECT_PARTNER.text }}</div>
      <div><span>&#8226;</span>배송사 : {{ this.GE_SELECT_CARRIER.text }}</div>
      <div><span>&#8226;</span>기간 : <label class="text-primary">{{ startDate }} ~ {{ endDate }}</label></div>
    </template>
  </confirm-dialog>
</template>

<script>
import { toDateFromFormat } from '@/utils/FormatUtil';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import { mapGetters } from 'vuex';

export default {
  components: { ConfirmDialog },
  props: {
    title: {
      type: String,
      required: true,
    },
    result: null,
  },
  data() {
    return {
      startDate: toDateFromFormat(this.result?.startDate),
      endDate: toDateFromFormat(this.result?.endDate),
    };
  },
  computed: {
    ...mapGetters('fareStore', [
      'GE_SELECT_CUSTOMER',
      'GE_SELECT_CARRIER',
      'GE_SELECT_PARTNER',
    ]),
  },
};
</script>

<style scoped>

</style>
