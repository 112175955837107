<template>
  <div>
    <prepared-tracking-api ref="api" />
    <DataTable :value="preparedTrackingNumbers"
               responsive-layout="scroll"
               class="p-datatable-gridlines"
               :paginator="true"
               :rowHover="true"
               :total-records="totalPages * rowCount"
               @page="onPage($event)"
               :lazy="true"
               :rows="rowCount"
    >
      <template #empty>
        결과 없음
      </template>
      <Column field="issuer" header="발급사">
        <template #body="{data}">
          {{ data.issuer }}
        </template>
      </Column>
      <Column field="trackingNumber" header="배송사">
        <template #body="{data}">
          {{ data.carrier }}
        </template>
      </Column>
      <Column field="trackingNumber" header="송장번호">
        <template #body="{data}">
          {{ data.trackingNumber }}
        </template>
      </Column>
      <Column field="status" header="상태">
        <template #body="{data}">
          {{ data.status }}
        </template>
      </Column>
      <Column field="warehousingDate" header="주문번호">
        <template #body="{data}">
          <template v-if="data?.orderName">
            {{ data.orderName || '-' }}
          </template>
        </template>
      </Column>
      <Column field="warehousingDate" header="생성시간">
        <template #body="{data}">
          <span>{{ toDateFromFormat(data.createdAt) ?? '-' }}</span>
        </template>
      </Column>
      <Column field="warehousingDate" header="발급시간">
        <template #body="{data}">
          <span>{{ toDateFromFormat(data.issuedAt) ?? '-' }}</span>
        </template>
      </Column>
      <Column field="delete" header="삭제 처리">
        <template #body="{data}">
          <Button @click="confirm(data)">삭제</Button>
        </template>
      </Column>
    </DataTable>
    <confirm-dialog v-if="showDeleteDialog" title="정말로 삭제하시겠습니까?" @closeDialog="deleteTracking">
      아래 정보가 맞는지 다시 한 번 확인 해주세요.
      <ul>
        <li>발급사 : {{ deleteTarget.issuer }}</li>
        <li>배송사 : {{ deleteTarget.carrier }}</li>
        <li>송장 번호 : {{ deleteTarget.trackingNumber }}</li>
        <li>주문 번호 : {{ deleteTarget.orderName }}</li>
        <li>현재 상태 : {{ deleteTarget.status }}</li>
      </ul>
    </confirm-dialog>
  </div>
</template>

<script>
import { toDateFromFormat } from '@/utils/FormatUtil';
import { mapState } from 'vuex';
import PreparedTrackingApi from '@/views/prepared-tracking/PreparedTrackingApi.vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';

export default {
  components: {
    PreparedTrackingApi,
    ConfirmDialog,
  },
  props: {
    issuer: { type: String, required: true },
    carrier: { type: String, required: true },
  },
  emits: ['delete'],
  data() {
    return {
      progress: true,
      showDeleteDialog: false,
      deleteTarget: {},
    };
  },
  computed: {
    rowCount() {
      return 10;
    },
    ...mapState('preparedTrackingStore', [
      'preparedTrackingNumbers',
      'size',
      'totalPages',
      'pageable',
    ]),
  },
  methods: {
    deleteTracking(payload) {
      if (payload) {
        this.$emit('delete', this.deleteTarget);
      }
      this.showDeleteDialog = false;
    },
    confirm(payload) {
      this.deleteTarget = payload;
      this.showDeleteDialog = true;
    },
    async onPage(event) {
      this.progress = true;
      await this.$emit('onPaging', {
        page: event.page, count: event.rowCount,
      });
      this.progress = false;
    },
    toDateFromFormat,
  },
};
</script>

<style scoped>

</style>
