import axios from 'axios';

export default class PreparedTrackingApi {
  axiosInstance = null;

  preparedTrackingApi = null;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: `${process.env.VUE_APP_API_BASE_URL}/tracking`,
    });
  }

  static create() {
    if (!this.preparedTrackingApi) {
      this.preparedTrackingApi = new PreparedTrackingApi();
    }
    return this.preparedTrackingApi;
  }

  async counts() {
    const { data: { result } } = await this.axiosInstance.get('/prepared-tracking-numbers/counts');

    return result;
  }

  async update(preparedTrackings) {
    await this.axiosInstance.patch('/prepared-tracking-numbers', {
      trackingNumbers: preparedTrackings,
    });
  }

  async list({
    issuer = null, carrier = null, orderName = null, status = 'READY',
  }, { size = 10, page = 0 }) {
    const { data: { result } } = await this.axiosInstance.get('/prepared-tracking-numbers', {
      params: {
        status,
        issuer,
        carrier,
        size,
        page,
        orderName,
      },
    });
    return result;
  }

  async listOfIssuerWithContractedCarriers() {
    const { data: { result } } = await this.axiosInstance.get('/prepared-tracking-numbers/issuers');
    return result;
  }

  async listOfCarriers() {
    const { data: { result } } = await this.axiosInstance.get('/prepared-tracking-numbers/carriers');
    return result;
  }

  async updatePreparedTracking({ issuer, carrier, trackingNumbers }) {
    await this.axiosInstance.post('/prepared-tracking-numbers', {
      issuer,
      carrier,
      trackingNumbers,
    });
  }

  async createCarrier({ carrierId, carrierName }) {
    await this.axiosInstance.post('/prepared-tracking-numbers/carriers', {
      carrierId,
      carrierName,
    });
  }

  async createIssuer({ issuerId, issuerName }) {
    await this.axiosInstance.post('/prepared-tracking-numbers/issuers', {
      issuerId,
      issuerName,
    });
  }

  async contract(issuerId, carrierId) {
    await this.axiosInstance.post(`/prepared-tracking-numbers/issuers/${issuerId}/carriers`, {
      carrierId,
    });
  }

  async listOfSettings() {
    const { data: { result } } = await this.axiosInstance.get('/settings');
    return result.reduce((acc, setting) => {
      acc[setting.id] = setting.settingValue;
      return acc;
    }, {});
  }

  async createOrUpdateSetting({ id, value }) {
    await this.axiosInstance.post('/settings', {
      id,
      settingValue: value,
    });
  }

  async listOfSafetySetting() {
    const { data: { result } } = await this.axiosInstance.get('/settings/safety-bands');
    return result;
  }

  async upsertSafetySetting({ issuerId, carrierId, bandWidth }) {
    const { data: { result } } = await this.axiosInstance.post('/settings/safety-bands', {
      issuerId, carrierId, bandWidth,
    });
    return result;
  }

  async listOfLocalOffices({
    zipCode, localOfficeCode, carrierId, page, size,
  }) {
    const params = new URLSearchParams({
      ...(zipCode ? { zipCode } : {}),
      ...(localOfficeCode ? { localOfficeCode } : {}),
      ...(carrierId ? { carrierId } : {}),
      ...(page ? { page } : {}),
      ...(size ? { size } : {}),
    });
    const { data: { result } } = await this.axiosInstance.get(`/local-offices?${params}`);
    return result;
  }

  async uploadLocalOffices({ carrierId, localOffices }) {
    const { data: { result } } = await this.axiosInstance.post(`/local-offices?carrierId=${carrierId}`, { list: localOffices });

    return result;
  }
}
