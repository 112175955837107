export default function getErrorMsg(err) {
  if (err.response.data.status === 'PARAM_NOT_READABLE') {
    return {
      severity: 'error',
      summary: '입력값이 잘 못 되어 있습니다.',
      detail: err.response.data.fields,
      life: 3000,
    };
  }
  return {
    severity: 'error',
    summary: err.response.data.message ?? err.message,
    detail: err.response.data.fields,
    life: 3000,
  };
}
