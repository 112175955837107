// eslint-disable-next-line import/prefer-default-export
export default function getCustomerText(key) {
  const customers = [
    {
      key: 'konny',
      text: 'Konny by erin',
    },
    {
      key: 'clio',
      text: 'Clio',
    },
    {
      key: 'hm',
      text: 'HM Int.',
    },
    {
      key: 'hm_int',
      text: 'HM Int.',
    },
    {
      key: 'jknd',
      text: 'JKND',
    },
    {
      key: 'bestonetech',
      text: 'Best One Tech',
    },
    {
      key: 'ppb',
      text: 'PPB Studios',
    },
    {
      key: 'konny-global',
      text: 'Konny by erin - Global',
    },
  ];
  const found = customers.find((value) => value.key === key);
  if (found) {
    return found.text;
  }
  return key?.toUpperCase();
}
