<template>
  <div>
    <account-payable-api ref="axios"></account-payable-api>
    <DataTable v-if="tables != null && tables.length != 0" :value="tables" responsive-layout="scroll"
               class="p-datatable-gridlines"
               :rowHover="true"
               :paginator="true"
               :total-records="totalCount * 2"
               @page="onPage($event)"
               :lazy="true"
               :loading="progress"
               :rows="20"
    >
      <Column v-for="col in columns" :lazy="true" :field="col.field" :header="col.header" :key="col.field">
        <template #body="{data, index}">
          <skeleton v-if="progress"></skeleton>
          <span v-else-if="data.status === 'AXB_AP' && isCharge(col.field) && data[col.field] !== 0" :class="compareCharge(col.field, index)">{{ toNumberFormat(data[col.field]) }} </span>
          <span v-else-if="col.field === 'status'">{{ convertStatus(data[col.field]) }}</span>
          <span v-else-if="col.field === 'carrierId'">{{ getCarrierText(data[col.field]) }}</span>
          <span v-else-if="col.field === 'customerId'">{{ getCustomerText(data[col.field]) }}</span>
          <span v-else-if="Number.isInteger(data[col.field])" >{{ toNumberFormat(data[col.field]) }}</span>
          <span v-else-if="col.field === 'shipmentDate'" >{{ toDateFromFormat(data.shipmentDate) }}</span>
          <span v-else>{{ data[col.field] }}</span>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import AccountPayableApi from '@/apis/AccountPayableApi.vue';
import apItemColumns from '@/views/account-payable/detail/apItem-columns';
import getCarrierText from '@/views/constant/carriers-info';
import {
  isCharge, sumCharge, toDateFromFormat, toNumberFormat,
} from '@/utils/FormatUtil';
import getCustomerText from '@/views/constant/customers-info';
import apItemStatus from '@/views/account-payable/detail/apItem-status';

export default {
  components: {
    AccountPayableApi,
  },
  props: {
    progress: {
      type: Boolean,
    },
  },
  data() {
    return {
      tables: null,
      totalCount: null,
      columns: apItemColumns.columns,
    };
  },
  methods: {
    compareCharge(column, index) {
      if (this.tables[index - 1][column] === this.tables[index][column]) {
        return '';
      }
      return 'text-pink-400';
    },
    async onPage(event) {
      await this.loadTable(event.page);
    },
    isCharge,
    convertStatus(status) {
      return apItemStatus.statusMap.find((value) => value.key === status).title;
    },
    toNumberFormat(value) {
      return value === 0 ? '-' : toNumberFormat(value);
    },
    toDateFromFormat,
    getCarrierText,
    getCustomerText,
    async loadTable(page) {
      const { list, totalCount } = await this.$refs.axios.getMismatchApItemList(this.$route.query.invoiceId, page, 10);
      this.tables = list.map((item) => {
        // eslint-disable-next-line no-param-reassign
        item.totalPrice = sumCharge(item);
        return item;
      });
      this.totalCount = totalCount;
    },
  },
  async mounted() {
    await this.loadTable(0);
  },
};
</script>

<style scoped>

</style>
