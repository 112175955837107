<template>
  <fare-api ref="axios"></fare-api>
  <div>
    <main-card title="고객사 요율 등록">
      <template v-slot:content>
        <customer-dropdown-group :partners-filter="['dtrans']" :customers-filter="['wooltari', 'cosrx']" :carriers-filter="['ups']" date-title="운임 기간" style-class="col-12 md:col-3"/>
        <duplicated-fare-dialog title="중복 운임 요금표" v-if="duplicatedFare !== null" :result="duplicatedFare" @closeDialog="closeDialog"></duplicated-fare-dialog>
        <divider/>
        <h4>운임 정보</h4>
        <div class="grid p-fluid">
          <div class="col-12 md:col-3">
            <default-label label="통화"/>
            <dropdown v-model="currency" :options="currencys" optionLabel="text" placeholder="Select One"></dropdown>
          </div>
          <div class="col-12 md:col-3" v-if="serviceTypes != null">
            <default-label label="서비스"/>
            <dropdown v-model="service" :options="serviceTypes" optionLabel="name" placeholder="Select One" @change="checkFareDate"></dropdown>
          </div>
        </div>
        <divider/>
        <div class="card" :key="item" v-for="item in items">
          <div class="flex justify-content-between pb-2">
            <div class="flex align-items-center justify-content-center">
              <h5>{{ item.title }}</h5>
            </div>
          </div>
          <charge-drop-down-group direction="AR" title="청구 방법" calculation-type="PERCENT" :surcharge-type="item.key"/>
        </div>
        <div class="flex justify-content-end flex-wrap">
          <div class="flex align-items-center">
            <Button label="업로드" @click="register"></Button>
          </div>
        </div>
      </template>
    </main-card>
  </div>
</template>
<script>
import CustomerDropdownGroup from '@/components/CustomerDropdownGroup.vue';
import { mapActions, mapGetters } from 'vuex';
import FareApi from '@/apis/FareApi.vue';
import DuplicatedFareDialog from '@/views/contract-info/fare-tariff/register/DuplicatedFareDialog.vue';
import CurrencyInfo from '@/views/constant/currency-info';
import DefaultLabel from '@/components/DefaultLabel.vue';
import ChargeDropDownGroup from '@/components/ChargeDropDownGroup.vue';
// eslint-disable-next-line import/named
import { getCarrierData } from '@/views/constant/carriers-info';

export default {
  name: 'PartnerTariffRegister',
  components: {
    DefaultLabel,
    ChargeDropDownGroup,
    DuplicatedFareDialog,
    CustomerDropdownGroup,
    FareApi,
  },
  data() {
    return {
      fileDetail: null,
      showConfirmDialog: false,
      duplicatedFare: null,
      disableFile: true,
      currency: { },
      term: null,
      uploadComplete: false,
      currencys: CurrencyInfo.currencyMap,
      service: null,
    };
  },
  computed: {
    ...mapGetters('fareStore', [
      'GE_SELECT_CUSTOMER',
      'GE_SELECT_CARRIER',
      'GE_SELECT_PARTNER',
      'GE_START_DATE',
      'GE_END_DATE',
      'GE_TARIFF_FILES',
      'GE_SURCHARGES',
    ]),
    items: {
      get() {
        return getCarrierData(this.GE_SELECT_CARRIER?.key)?.items;
      },
    },
    serviceTypes: {
      get() {
        return getCarrierData(this.GE_SELECT_CARRIER?.key)?.shippingTypes;
      },
    },
  },
  methods: {
    ...mapActions('fareStore', [
      'AC_TARIFF_FILES_CLEAR',
      'AC_SURCHARGES_CLEAR',
    ]),
    closeDialog() {
      this.duplicatedFare = null;
    },
    async checkFareDate(e) {
      const request = {
        customerId: this.GE_SELECT_CUSTOMER.key,
        carrierId: this.GE_SELECT_CARRIER.key,
        partnerId: this.GE_SELECT_PARTNER.key,
        fareType: e.value?.key,
        startDate: this.GE_START_DATE,
        endDate: this.GE_END_DATE,
      };
      const result = await this.$refs.axios.checkFareDate(request);
      this.duplicatedFare = result === '' ? null : result;
      this.disableFile = result !== '';
    },
    async register() {
      const registerFareList = [];

      for (const item of this.GE_SURCHARGES) {
        const registerFareData = {
          customerId: this.GE_SELECT_CUSTOMER.key,
          carrierId: this.GE_SELECT_CARRIER.key,
          partnerId: this.GE_SELECT_PARTNER.key,
          fareType: this.service.key,
          currency: this.currency.key,
          startDate: this.GE_START_DATE,
          endDate: this.GE_END_DATE,
          fromCountryCode: 'KR',
          tariffJson: null,
          chargePrice: item.value,
          tariffType: item.inputType,
          category: item.surchargeType,
        };
        registerFareList.push(registerFareData);
      }

      await this.$refs.axios.registerFare(registerFareList);

      this.$toast.add({
        severity: 'success',
        summary: 'Tariff 업로드 성공',
        detail: `고객사 : ${this.GE_SELECT_CUSTOMER.text}`,
        life: 3000,
      });
      return '';
    },
  },
};
</script>

<style scoped lang="scss">
</style>
