export default {
  headers: [
    { label: '번호', key: 'no' },
    { label: '주문상태', key: 'status' },
    { label: '주문번호', key: 'orderName' },
    { label: '출고번호', key: 'packingNumber' },
    { label: '운송장번호', key: 'trackingNumber' },
    { label: '고객사', key: 'customerId' },
    { label: '파트너사', key: 'partnerId' },
    { label: '배송사', key: 'carrierId' },
    { label: '입고일', key: 'warehousingDate' },
    { label: '생성일', key: 'orderCreatedAt' },
    { label: '실무게', key: 'weight' },
    { label: '청구무게', key: 'chargeableWeight' },
    { label: 'W', key: 'dimWidth' },
    { label: 'L', key: 'dimLength' },
    { label: 'H', key: 'dimHeight' },
    { label: '단위', key: 'dimUnit' },
    { label: '부피무게', key: 'volumeWeight' },
    { label: '배송국가', key: 'fromCountryCode' },
    { label: '도착국가', key: 'toCountryCode' },
  ],
};
