<template>
  <div>
    <div v-if="label" class="font-bold mb-2 p-text-bold">{{ label }}</div>
    <div :style="{ pointerEvents: disabled ? 'none' : '', opacity: disabled ? '0.6' : '1' }">
      <div class="p-inputgroup">
        <span class="p-button p-component p-fileupload-choose" @click="choose">
          <input ref="fileInput" type="file" @change="onFileSelect" :accept="accept" />
          <span class="p-button-icon p-button-icon-left pi pi-fw pi-plus"></span>
          <span class="p-button-label">Choose</span>
        </span>
        <InputText :placeholder="placeholder" readonly :model-value="file ? `${file.name} (${toFileSize(file.size)})` : ''" />
        <Button icon="pi pi-times" class="p-button-secondary" @click="clearFile"/>
      </div>
    </div>
  </div>
</template>

<script>
import { toFileSize } from '@/utils/FormatUtil';

export default {
  emits: ['change:file'],
  props: {
    label: {
      type: String,
    },
    chooseText: {
      type: String,
      default: 'Choose',
    },
    accept: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
    },
  },
  data() {
    return {
      file: null,
    };
  },
  methods: {
    toFileSize,
    choose() {
      this.$refs.fileInput.click();
    },
    onFileSelect(event) {
      if (event.target.files.length) {
        [this.file] = event.target.files;
        this.$emit('change:file', { file: this.file });
      }
    },
    clearFile() {
      if (this.file) {
        this.$refs.fileInput.value = '';
        this.file = null;
        this.$emit('change:file', { file: null });
      }
    },
  },
};
</script>
