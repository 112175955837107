<template>
  <div>
    <prepared-tracking-api ref="api" />
    <main-card title="송장 관리">
      <template v-slot:content>
        <div class="grid">
          <div class="col-8 grid flex-nowrap p-fluid">
            <div class="col-4">
              <DefaultLabel label="발급사 - 배송사" />
              <Dropdown v-model="selectedCarrier" @change="onFilterUpdate" placeholder="sagawa" :options="carriers" optionLabel="label" optionGroupLabel="label" optionGroupChildren="items">
                <template #optiongroup="slotProps">
                  <div class="flex align-items-center country-item">
                    <img src="https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png" width="18" />
                    <div>{{slotProps.option.label}}</div>
                  </div>
                </template>
              </Dropdown>
            </div>
            <div class="col-4">
              <DefaultLabel label="발급 상태" />
              <Dropdown v-model="selectedStatus" @change="onFilterUpdate" :options="statuses" optionLabel="label" optionValue="value" placeholder="Select"></Dropdown>
            </div>
            <div class="col-4">
              <DefaultLabel label="벌크 엑셀 다운로드" />
              <Button @click="bulkDownload" :loading="isBulkDownloadLoading">
                <span v-if="isBulkDownloadLoading">로딩..</span>
                <span v-else>다운로드</span>
              </Button>
            </div>
          </div>

          <div class="col-2">
            <h6>주문번호</h6>
            <InputText type="text" placeholder="주문 번호 검색" @keydown.enter="search" v-model="searchKeyword" />
          </div>
          <div class="col-2">
            <h6>&nbsp;</h6>
            <Button icon="pi pi-search" @click="search"></Button>
          </div>
        </div>
        <prepared-tracking-table @onPaging="onPaging" @delete="deleteTracking" />
      </template>
    </main-card>
  </div>
</template>

<script>
import DefaultLabel from '@/components/DefaultLabel.vue';
import PreparedTrackingApi from '@/views/prepared-tracking/PreparedTrackingApi.vue';
import PreparedTrackingTable from '@/views/prepared-tracking/PreparedTrackingTable.vue';
import { mapState } from 'vuex';

export default {
  components: {
    DefaultLabel,
    PreparedTrackingApi,
    PreparedTrackingTable,
  },
  data() {
    return {
      statuses: [
        { value: 'READY', label: '발급 가능' },
        { value: 'ISSUING', label: '발급 중' },
        { value: 'USED', label: '발급 완료' },
        { value: 'FAILED', label: '실패' },
        { value: 'UNKNOWN', label: '알 수 없음' },
      ],
      page: 0,
      count: 10,
      selectedCarrier: null,
      selectedStatus: 'READY',
      searchKeyword: '',
      isBulkDownloadLoading: false,
    };
  },
  computed: {
    ...mapState('preparedTrackingStore', [
      'issuers',
    ]),
    carriers() {
      return this.issuers.map((i) => ({
        label: i.issuerName,
        items: i.carriers ? i.carriers.map((j) => ({ label: `${i.issuerName}-${j.carrierName}`, value: `${i.id}.${j.id}` })) : [],
      }));
    },
  },
  methods: {
    async deleteTracking(payload) {
      const t = {
        ...payload,
        status: 'FAILED',
        storeId: null,
        orderId: null,
        orderName: null,
        issuedCustomer: null,
      };
      await this.$refs.api.updatePreparedTrackingNumbers([t]);
      await this.search();
    },
    async search() {
      const [issuer, carrier] = this.selectedCarrier?.value.split('.') ?? [null, null];
      const result = await this.$refs.api.listPreparedTrackingNumbers({
        issuer,
        carrier,
        status: this.selectedStatus,
        orderName: this.searchKeyword || null,
        size: this.size,
        page: this.page,
      });
      this.$store.dispatch('preparedTrackingStore/UPDATE_PREPARED_TRACKING_NUMBERS', result);
    },
    async onFilterUpdate() {
      this.page = 0;
      this.count = 10;
      await this.search();
    },
    async onPaging({ page, count }) {
      this.page = page;
      this.count = count;
      await this.search();
    },
    errorMessage(message) {
      return this.$toast.add({
        severity: 'error', summary: 'Error', detail: message || '시스템 관리자에게 문의하세요', life: 3000,
      });
    },
    async bulkDownload() {
      this.isBulkDownloadLoading = true;
      const willGetCount = this.$store.getters['preparedTrackingStore/totalElements'];
      if (!this.selectedCarrier?.value) {
        this.errorMessage('전체 목록은 다운로드 할 수 없습니다. 발급사/배송사를 선택하세요.');
        this.isBulkDownloadLoading = false;
      } else {
        const [issuer, carrier] = this.selectedCarrier.value.split('.');
        const status = this.selectedStatus;
        const list = await this.$refs.api.listPreparedTrackingNumbers({
          issuer,
          carrier,
          size: willGetCount,
          status,
        });
        //

        const rows = [
          ['배송사', '발급사', '송장번호', '상태', '주문번호', '생성시간', '발급시간'],
          ...list.content.map((i) => [
               i?.carrier ?? '',
               i?.issuer ?? '',
               i?.trackingNumber ?? '',
               i?.status ?? '',
               i?.orderId ?? '',
               i?.createdAt ?? '',
               i?.issuedAt ?? '',
          ]),
        ];

        let csvContent = 'data:text/csv;charset=utf-8,';

        rows.forEach((rowArray) => {
          const row = rowArray.join(',');
          csvContent += `${row}\r\n`;
        });
        const link = document.createElement('a');
        link.setAttribute('href', encodeURI(csvContent));
        link.setAttribute('download', `${issuer}-${carrier}-${status}-${willGetCount}.csv`);
        link.click();
      }// list.content
      this.isBulkDownloadLoading = false;
    },
  },
  async mounted() {
    await this.$store.dispatch('preparedTrackingStore/INIT_ISSUERS');
    this.selectedStatus = 'READY';
    await this.search();
  },
};
</script>
