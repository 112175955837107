<template>
  <div>
    <account-payable-api ref="axios"></account-payable-api>
    <div class="flex justify-content-end flex-wrap">
      <div class="flex align-items-center">
        <Button label="청구서 검증" @click="validateInvoiceAp" :disabled="progress"></Button>
      </div>
    </div>
    <div class="grid p-fluid">
      <div class="col-12 pt-4">
        <DefaultLabel label="청구액 검증"/>
        <DataTable :value="tables"
                   responsive-layout="scroll"
                   class="p-datatable-gridlines"
                   :lazy="true"
                   :loading="progress"
                   :style="{fontSize: '0.7rem'}"
        >
          <Column field="carrierId" header="운송사">
            <template #body="{data}">
              <skeleton v-if="progress"></skeleton>
              <span v-else>{{getCarrierText(data.carrierId)}}</span>
            </template>
          </Column>
          <Column field="apItemCount" header="청구서 주문">
            <template v-if="progress" #body>
              <skeleton ></skeleton>
            </template>
          </Column>
          <Column field="matchCount" header="AXB 검증 일치">
            <template v-if="progress" #body>
              <skeleton ></skeleton>
            </template>
          </Column>
          <Column field="mismatchCount" header="AXB 검증 상이">
            <template #body="{data}">
              <skeleton v-if="progress"></skeleton>
              <span v-else class="text-pink-400">{{data.mismatchCount}}</span>
            </template>
          </Column>
        </DataTable>
      </div>
      <div class="col-12 pt-4">
        <DefaultLabel label="AXB 검증 상이"/>
        <mismatch-ap-item-table v-if="!progress"/>
      </div>
    </div>
  </div>
</template>

<script>
import MismatchApItemTable from '@/views/account-payable/detail/MismatchApItemTable.vue';
import DefaultLabel from '@/components/DefaultLabel.vue';
import AccountPayableApi from '@/apis/AccountPayableApi.vue';
import getCarrierText from '@/views/constant/carriers-info';

export default {
  components: { MismatchApItemTable, DefaultLabel, AccountPayableApi },
  data() {
    return {
      tables: [],
      totalCount: null,
      progress: true,
    };
  },
  methods: {
    async validateInvoiceAp() {
      this.$emit('validateInvoiceAp', this.progress = true);
      await this.$refs.axios.validateInvoiceApItem(this.$route.query.invoiceId);
      await this.loadTable();
      this.$emit('validateInvoiceAp', this.progress = false);
    },
    getCarrierText,
    async loadTable() {
      const { list } = await this.$refs.axios.getSummary(this.$route.query.invoiceId);
      list.map((item) => {
        // eslint-disable-next-line no-param-reassign,no-return-assign
        item.matchCount = item.apItemCount - item.mismatchCount;
        return item;
      });
      list.push({
        carrierId: '총 주문',
        apItemCount: list.reduce((re, item) => re + item.apItemCount, 0),
        matchCount: list.reduce((re, item) => re + item.matchCount, 0),
        mismatchCount: list.reduce((re, item) => re + item.mismatchCount, 0),
      });
      this.tables = list;
      this.progress = false;
    },
  },
  async mounted() {
    await this.loadTable();
  },
};
</script>

<style scoped>

</style>
