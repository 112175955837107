<template>
  <card class="card">
    <template #title>
      <div class="flex justify-content-between">
        <div class="flex align-items-center justify-content-center ">
          <span>{{ title }}</span>
        </div>
        <div v-if="progress"  class="flex align-items-center justify-content-top">
          <progress-spinner style="width:30px;height:30px" stroke-width="8" />
        </div>
      </div>
    </template>
    <template #content>
      <slot name="content"></slot>
    </template>
  </card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    progress: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>

</style>
