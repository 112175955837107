<template>
  <div>
    <main-card title="파트너사 입고/출고 정보 등록">
      <template v-slot:content>
        <div class="grid">
          <div class="col-12 md:col-3">
            <SingleFileSelect
              label="SF Express 입고"
              placeholder="업로드 파일을 선택하세요"
              accept=".xlsx,.xls,.csv"
              :disabled="fileDetail?.status === 'LOADING'"
              @change:file="({ file }) => onFileSelect(file)"
            />
          </div>
        </div>
        <divider/>
      </template>
    </main-card>
  </div>
</template>
<script>
import SingleFileSelect from '@/components/SingleFileSelect.vue';

export default {
  name: 'WarehousingRegister',
  components: {
    SingleFileSelect,
  },
  data() {
    return {
      fileDetail: null,
      duplicatedFare: null,
      disableFile: true,
      currency: { },
      term: null,
      uploadComplete: false,
    };
  },
  computed: {
  },
  methods: {
    closeDialog() {
      this.duplicatedFare = null;
    },
    async onFileSelect(file) {
      console.log(file);
    },
    async register() {
      this.$toast.add({
        severity: 'success',
        summary: 'Tariff 업로드 성공',
        detail: `고객사 : ${this.GE_SELECT_CUSTOMER.text}`,
        life: 3000,
      });
      return '';
    },
  },
};
</script>

<style scoped lang="scss">
</style>
