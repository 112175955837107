export default {
  statusMap: [{ key: 'NOT_IN_STOCK', title: '미입고' },
    { key: 'IN_STOCK', title: '입고' },
    { key: 'INVOICE_READY', title: '정산 예정' },
    { key: 'INVOICE_DONE', title: '정산 완료' }],
  getWareHousingStatus(key) {
    const found = this.statusMap.find((value) => value.key === key);
    if (found) {
      return found.title;
    }
    return key.toUpperCase();
  },
};
