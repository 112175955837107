<template>
  <div>
    <order-api ref="axios"></order-api>
    <main-card title="주문 Migration" :progress="progress">
      <template v-slot:content>
        <div class="flex">
          <div class="flex flex-column">
            <DefaultLabel label="고객사"/>
            <Dropdown v-model="customer" editable :options="customers" optionLabel="text" placeholder="Select One"></Dropdown>
          </div>
          <div class="flex flex-column pl-2">
            <DefaultLabel label="주문 생성 기간"/>
            <calendar v-model="term" selectionMode="range"
                      @clear-click="() => this.term = null" @date-select="$emit('close')" @hide="$emit('close')" date-format="yy.mm.dd" :show-icon="true"/>
          </div>
          <div class="flex flex-column justify-content-end pl-2">
            <Button @click="migrateData">Migrate</Button>
          </div>
        </div>
        <div class="pt-2">
          <div>총 주문 수 : {{ totalCount }}</div>
          <div>등록 주문 수 : {{ registeredCount }}</div>
          <div>업데이트 주문 수 : {{ updatedCount }}</div>
        </div>
      </template>
    </main-card>
  </div>
</template>

<script>
import DefaultLabel from '@/components/DefaultLabel.vue';
import OrderApi from '@/apis/OrderApi.vue';

export default {
  name: 'OrdersMigrate',
  components: {
    DefaultLabel,
    OrderApi,
  },
  methods: {
    async migrateData() {
      this.progress = true;
      try {
        const { totalCount, updatedCount, registeredCount } = await this.$refs.axios.getOrdersByCustomer(this.customer.key ?? this.customer, this.term[0].getTime(), this.term[1].getTime());
        this.totalCount = totalCount;
        this.updatedCount = updatedCount;
        this.registeredCount = registeredCount;
        this.$toast.add({
          severity: 'success',
          summary: 'Migration 완료.',
          life: 3000,
        });
      } catch (e) {
        this.progress = false;
      }
      this.progress = false;
    },
    closeDialog() {
      this.openModal = false;
    },
  },
  data() {
    return {
      term: null,
      customer: null,
      progress: false,
      totalCount: 0,
      updatedCount: 0,
      registeredCount: 0,
      customers: [{
        key: 'hm-intl', text: 'Hm Intl.',
      },
      {
        key: 'clio', text: 'Clio',
      },
      {
        key: 'ppb-studios', text: 'PPB-Studios',
      },
      {
        key: 'jknd', text: 'JKND',
      },
      {
        key: 'konny', text: 'Konny',
      },
      {
        key: 'konny-global', text: 'Konny-Global',
      },
      ],
    };
  },
};
</script>
