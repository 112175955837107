<template>
  <div>
    <prepared-tracking-api ref="api" />
    <DataTable :value="list"
               responsive-layout="scroll"
               class="p-datatable-gridlines"
               :paginator="true"
               :rowHover="true"
               :total-records="totalPages * rowCount"
               @page="onPage($event)"
               :lazy="true"
               :rows="rowCount"
    >
      <template #empty>
        결과 없음
      </template>
      <Column field="status" header="운송사">
        <template #body="{data}">
          {{ data.carrierId }}
        </template>
      </Column>
      <Column field="issuer" header="우편번호">
        <template #body="{data}">
          {{ data.zipCode }}
        </template>
      </Column>
      <Column field="trackingNumber" header="영업소 코드">
        <template #body="{data}">
          {{ data.localOfficeCode }}
        </template>
      </Column>
      <Column field="trackingNumber" header="최근 수정된 시간">
        <template #body="{data}">
          {{ data.updatedAt }}
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import { toDateFromFormat } from '@/utils/FormatUtil';
import PreparedTrackingApi from '@/views/prepared-tracking/PreparedTrackingApi.vue';

export default {
  components: {
    PreparedTrackingApi,
  },
  props: {
    list: { type: Array, required: true },
    page: { type: Number, required: false, default: 0 },
    size: { type: Number, required: false, default: 10 },
    totalPages: { type: Number, required: false, default: 1 },
    totalElements: { type: Number, required: false, default: 0 },
  },
  data() {
    return {
      progress: true,
    };
  },
  computed: {
    rowCount() {
      return 10;
    },
  },
  methods: {
    async onPage(event) {
      this.progress = true;
      await this.$emit('onPaging', {
        page: event.page, size: event.rowCount,
      });
      this.progress = false;
    },
    toDateFromFormat,
  },
};
</script>

<style scoped>

</style>
