export default class DisposableWorker {
  constructor(worker) {
    this.worker = worker;
  }

  static of(worker) {
    return new DisposableWorker(worker);
  }

  async run(message) {
    return new Promise((resolve) => {
      this.worker.addEventListener('message', (event) => {
        resolve(event.data);
        this.worker.terminate();
      });
      this.worker.addEventListener('error', (event) => {
        resolve(event.data);
        this.worker.terminate();
      });
      this.worker.postMessage(message);
    });
  }
}
