export default {
  inputTypes: [
    {
      title: 'C/W*KG 적용',
      key: 'CW_KG',
      inputType: 'input',
    },
    {
      title: '배송 건당 고정 부가 운임',
      key: 'PER_CASE',
      inputType: 'input',
    },
    {
      title: '청구 기준으로 부과',
      key: 'PASS_THROUGH',
    },
    {
      title: 'Tariff 적용',
      key: 'TARIFF',
      inputType: 'file',
    },
    {
      title: '요율',
      key: 'PERCENT',
      inputType: 'PERCENT',
    },
  ],
  items: [
    {
      title: '긴급 비용 (Emergency Surcharge)',
      key: 'EMERGENCY_SUR',
      checked: true,
    },
    {
      title: '외곽수수료 (EXTENDED SURCHARGE)',
      key: 'EXTENDED_SUR',
      checked: true,
    },
    {
      title: '관세대납수수료 (DTP)',
      key: 'DTP',
      checked: true,
    },
    {
      title: '주소변경수수료 (ADDRESS CORRECTION)',
      key: 'ADDRESS_SUR',
      checked: true,
    },
    {
      title: '추가처리비용 (ADDITIONAL HANDLING CHARGE)',
      key: 'ADDITIONAL_SUR',
      checked: true,
    },
    {
      title: '부과운임 유류할증료 (FSC)',
      key: 'FSC_SUR',
      checked: true,
    },
    {
      title: '관세 (Duty)',
      key: 'DUTY',
      checked: true,
    },
    {
      title: '부가세 (Tax)',
      key: 'TAX',
      checked: true,
    },
  ],
  getSurchargeInputTypeName(key) {
    const found = this.inputTypes.find((value) => value.key === key);
    if (found) {
      return found.title;
    }
    return key?.toUpperCase();
  },
  getChargeInputType(key) {
    const found = this.inputTypes.find((value) => value.key === key);
    if (found) {
      return found;
    }
    return {};
  },
};
