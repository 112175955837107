export default {
  calculationType: 'PERCENT',
  shippingTypes: [
    {
      code: '01',
      default: true,
      description: 'test',
      isLocalService: true,
      name: 'UPS Next Day Air',
      key: 'UPS_NEXT_DAY_AIR',
      status: 'Active',
    },
    {
      code: '02',
      default: false,
      description: 'test',
      isLocalService: true,
      name: 'UPS 2nd Day Air',
      key: 'UPS_2ND_DAY_AIR',
      status: 'Active',
    },
    {
      code: '59',
      default: false,
      description: 'test',
      isLocalService: true,
      name: 'UPS 2nd Day Air A.M.',
      key: 'UPS_2ND_DAY_AIR_A_M',
      status: 'Active',
    },
    {
      code: '11',
      default: true,
      description: 'test',
      isLocalService: false,
      name: 'UPS Standard',
      key: 'UPS_STANDARD',
      status: 'Active',
    },
    {
      code: '07',
      default: false,
      description: 'test',
      isLocalService: false,
      name: 'UPS Worldwide Express',
      key: 'UPS_WORLDWIDE_EXPRESS',
      status: 'Active',
    },
    {
      code: '03',
      default: false,
      description: 'test',
      isLocalService: false,
      name: 'UPS Ground',
      key: 'UPS_GROUND',
      status: 'Active',
    },
    {
      code: '13',
      default: false,
      description: 'test',
      isLocalService: false,
      name: 'UPS Next Day Air Saver',
      key: 'UPS_NEXT_DAY_AIR_SAVER',
      status: 'Active',
    },
    {
      code: 'M4',
      default: false,
      description: 'UPS Mail Innovations',
      isLocalService: false,
      name: 'UPS Mail Innovations',
      key: 'UPS_MAIL_INNOVATIONS',
      status: 'Active',
    },
    {
      code: '08',
      default: false,
      description: 'UPS Worldwide Expedited',
      isLocalService: false,
      name: 'UPS Worldwide Expedited',
      key: 'UPS_WORLDWIDE_EXPEDITED',
      status: 'Active',
    },
    {
      code: '65',
      default: false,
      description: 'UPS Worldwide Saver',
      isLocalService: false,
      name: 'UPS Worldwide Saver',
      key: 'UPS_WORLDWIDE_SAVER',
      status: 'Active',
    },
    {
      code: '12',
      default: false,
      description: 'UPS 3 Day Select',
      isLocalService: false,
      name: 'UPS 3 Day Select',
      key: 'UPS_3_DAY_SELECT',
      status: 'Active',
    },
    {
      code: '14',
      default: false,
      description: 'UPS Next Day Air Early',
      isLocalService: false,
      name: 'UPS Next Day Air Early',
      key: 'UPS_NEXT_DAY_AIR_EARLY',
      status: 'Active',
    },
  ],
  items: [
    {
      title: 'Transportation',
      key: 'BaseServiceCharge',
    },
    {
      title: 'Fuel surcharge',
      key: '375',
    },
    {
      title: 'Large package',
      key: '377',
    },
    {
      title: 'Large Package Seasonal Surcharge',
      key: '431',
    },
    {
      title: 'Additional handling',
      key: '100',
    },
    {
      title: 'Additional handling seasonal',
      key: '432',
    },
    {
      title: 'Residential address',
      key: '270',
    },
  ],
  getSurchargeInputTypeName(key) {
    const found = this.inputTypes.find((value) => value.key === key);
    if (found) {
      return found.title;
    }
    return key?.toUpperCase();
  },
};
