<template>
  <div class="dashboard-root">
    <template v-if="issuersAndCount">
      <Card
        v-for="(board, index) in issuersAndCount" :key="`board-${index}`"
        class="issuer"
      >
        <template #title>
          {{board.issuer}}
        </template>
        <template #content>
          <div class="grid">
            <div
              class="col-4"
              v-for="(carrier, cIndex) in board.carriers"
              :key="`board-${board.issuer}-${board-carrier}-${cIndex}`"
            >
              <Card>
                <template #title>
                  {{carrier.name}}
                </template>
                <template #content>
                  <div :class="{ available: true, danger: carrier.available < safetySettings[`${board.issuer}-${carrier.name}`] }">
                    {{carrier.available.toLocaleString()}}개
                  </div>
                  <small class="flex justify-content-center align-items-center" style="margin-top:16px; gap:14px; font-size:14px; text-align:center;">
                    <InputText
                      v-model="safetySettings[`${board.issuer}-${carrier.name}`]"
                      style="width:100px;"
                      @change="(e) => changeBandWidth(board.issuer, carrier.name, e.target.value)"
                    /> 개 미만 시 알림
                  </small>
                </template>
              </Card>
            </div>
          </div>
        </template>
      </Card>
    </template>
    <template v-else>
      <Skeleton class="col-2" height="400px" />
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'PreparedTrackingDashboard',
  data: () => ({
    dsa: 0,
    safetySetup: {},
  }),
  computed: {
    dangerSize() {
      return 10000;
    },
    ...mapState('preparedTrackingStore', [
      'settings',
      'safetySettings',
    ]),
  },
  props: {
    issuersAndCount: {
      type: Object,
    },
  },
  methods: {
    toastEdited() {
      this.$toast.add({
        severity: 'success', summary: 'Successful', detail: '변경했습니다.', life: 3000,
      });
    },
    async changeBandWidth(issuer, carrier, value) {
      console.log(issuer, carrier, value);
      await this.$store.dispatch('preparedTrackingStore/UPDATE_SAFETY_SETTING', {
        issuer,
        carrier,
        value,
      });
      this.toastEdited();
    },
  },
  async mounted() {
    await this.$store.dispatch('preparedTrackingStore/INIT_SETTINGS');
    await this.$store.dispatch('preparedTrackingStore/INIT_SAFETY_SETTING');
  },
};
</script>

<style scoped lang="scss">

.dashboard-root {
  padding:0 0 24px 0;

  .issuer {
    margin: 16px 0;
  }
}

.available {
  font-size:20px;
  &.danger {
    color:red;
  }
}

</style>
