export default {
  headers: [
    { label: '포트', key: 'port' },
    { label: '운송장 번호', key: 'trackingNumber' },
    { label: '포장수량', key: 'totalItemCount' },
    { label: '면장중량', key: 'totalWeight' },
    { label: '가격', key: 'totalPrice' },
    { label: '품명', key: 'itemName' },
    { label: '송하인', key: 'sender' },
    { label: '송하인 주소', key: 'senderAddress' },
    { label: '우편번호', key: 'zipCode' },
    { label: '수하인', key: 'receiver' },
    { label: '수하인 주소', key: 'receiverAddress' },
    { label: '전자상거래수출신고번호', key: 'declarationNumber' },
    { label: '수출신고인부호', key: 'exportNumber' },
    { label: '주문번호', key: 'orderId' },
  ],
};
