export default function getPartnerText(key) {
  const partners = [
    {
      key: 'ics',
      text: 'ICS',
    },
    {
      key: 'rincos',
      text: 'Rincos',
    },
    {
      key: 'sf-express',
      text: 'SF Express',
    },
  ];
  const found = partners.find((value) => value.key === key);
  if (found) {
    return found.text;
  }
  return key?.toUpperCase();
}
