<template>
  <div></div>
</template>
<script>
import PreparedTrackingApi from '@/apis/prepared-tracking-api';

export default {
  data() {
    return {

    };
  },
  methods: {
    async issuerListAndCount() {
      const result = await PreparedTrackingApi.create().counts();
      const countList = Object
        .entries(result)
        .map(([issuer, carriers]) => ({
          issuer,
          carriers: carriers.map((i) => ({ name: i.carrier, available: i.count })),
        }));
      const carriers = countList.map((i) => ({
        label: i.issuer,
        items: i.carriers.map((j) => ({ label: `${i.issuer}-${j.name}`, value: `${i.issuer}.${j.name}` })),
      }));
      return {
        countList,
        carriers,
      };
    },

    async listPreparedTrackingNumbers({
      issuer, carrier, orderName, status, size, page,
    }) {
      const result = await PreparedTrackingApi.create().list({
        issuer, carrier, orderName, status,
      }, { size, page });
      return result;
    },

    async updatePreparedTrackingNumbers(preparedTrackings) {
      await PreparedTrackingApi.create().update(preparedTrackings);
    },
    async uploadPreparedTrackingNumbers({ issuer, carrier, trackingNumbers }) {
      const result = await PreparedTrackingApi.create().updatePreparedTracking({ issuer, carrier, trackingNumbers });
      return result;
    },
    async contractIssuerWithCarrier(issuerId, carrierId) {
      await PreparedTrackingApi.create().contract(issuerId, carrierId);
    },
    async listOfLocalOffices({
      zipCode, localOfficeCode, carrierId, page, size,
    }) {
      const result = PreparedTrackingApi.create().listOfLocalOffices({
        zipCode, localOfficeCode, carrierId, page, size,
      });
      return result;
    },
    async uploadLocalOffices({ carrierId, localOffices }) {
      await PreparedTrackingApi.create().uploadLocalOffices({ carrierId, localOffices });
    },
    async listOfCarriers() {
      return PreparedTrackingApi.create().listOfCarriers();
    },
  },
};
</script>
