<template>
  <div class="grid p-fluid">
    <div class="col-12 md:col-3">
      <h6>{{ title }}</h6>
      <Dropdown v-model="selectedData" :options="inputTypes"
                optionLabel="title" @change="selectInput" placeholder="Select One"></Dropdown>
    </div>
    <div v-if="selectedData.inputType === 'input'" class="col-12 md:col-2">
      <h6>건당 가격</h6>
      <InputNumber v-model="chargePrice" @input="updateValue" mode="currency" currency="krw" />
    </div>
    <div v-if="selectedData.inputType === 'PERCENT'" class="col-12 md:col-2">
      <h6>요금</h6>
      <InputNumber v-model="chargePrice" @input="updateValue"/>
    </div>
    <div v-if="selectedData.inputType === 'file'" class="col-4">
      <upload-tariff-file :surcharge-type="surchargeType" :direction="direction" style-class="col-12" :use-tariff-dialog=false />
    </div>
  </div>
</template>

<script>
import UploadTariffFile from '@/views/contract-info/fare-tariff/register/UploadTariffFile.vue';
import ChargeConstant from '@/views/constant/charge-constant';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: { UploadTariffFile },
  props: {
    title: {
      type: String,
    },
    surchargeType: {
      type: String,
    },
    direction: {
      type: String,
    },
    checked: {
      type: Boolean,
    },
    calculationType: {
      type: String,
    },
  },
  data() {
    return {
      selectedData: ChargeConstant.getChargeInputType(this.calculationType),
      chargePrice: 0,
      inputTypes: ChargeConstant.inputTypes,
    };
  },
  computed: {
    ...mapGetters('fareStore', [
      'GE_SURCHARGES',
    ]),
  },
  methods: {
    ...mapActions('fareStore', [
      'AC_SURCHARGES_PUSH',
      'AC_SURCHARGES',
    ]),
    updateValue(event) {
      this.AC_SURCHARGES_PUSH({
        ...this.selectedData,
        value: event.value,
        surchargeType: this.surchargeType,
        direction: this.direction,
        checked: this.checked,
      });
    },
    selectInput() {
      this.chargePrice = 0;
      this.AC_SURCHARGES_PUSH({
        ...this.selectedData, value: this.chargePrice, surchargeType: this.surchargeType, direction: this.direction, checked: this.checked,
      });
    },
  },
};
</script>

<style scoped>

</style>
