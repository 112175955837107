import DisposableWorker from '@/utils/DisposableWorker';

// eslint-disable-next-line import/prefer-default-export
export async function read(columns, file) {
  const {
    isValid, rows, errors,
  } = await DisposableWorker.of(new Worker('./excel-reader.worker.js', { type: 'module' })).run({
    columns,
    file,
  });

  return { isValid, rows, errors };
}

export async function readSheetAll(file) {
  const sheets = await DisposableWorker.of(new Worker('./excel-reader-all.worker.js', { type: 'module' })).run({
    file,
  });
  return sheets;
}

export async function readBySheetName(columns, file, sheetName) {
  const {
    isValid, rows, errors,
  } = await DisposableWorker.of(new Worker('./excel-reader-name.worker.js', { type: 'module' })).run({
    columns,
    file,
    sheetName,
  });

  return { isValid, rows, errors };
}
