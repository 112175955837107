<template>
  <Dialog :visible="true" :modal="true" @update:visible="handler">
    <template #header>
      <h3>{{ title }}</h3>
    </template>
    <slot name="default"></slot>
    <slot name="footer"></slot>
    <template #footer v-if="isExistFooter">
      <Button label="No" icon="pi pi-times" @click="handler(false)" class="p-button-text"/>
      <Button label="Yes" icon="pi pi-check" @click="handler(true)" autofocus/>
    </template>
  </Dialog>
</template>

<script>

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  methods: {
    handler(payload) {
      this.$emit('closeDialog', payload);
    },
  },
  computed: {
    isExistFooter() {
      return !this.$slots.footer;
    },
  },
};
</script>
