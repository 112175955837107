<template>
  <div>
    <div class="flex justify-content-between pb-2">
      <div class="flex align-items-center justify-content-center">
        <h4>추가 운임 항목</h4>
      </div>
      <div class="flex align-items-center justify-content-top">
        <input-switch v-model="allSwitch" @change="allSwitchHandle"/>
      </div>
    </div>
    <div class="card" :key="item" v-for="item in surcharges">
      <div class="flex justify-content-between pb-2">
        <div class="flex align-items-center justify-content-center">
          <h5>{{ item.title }}</h5>
        </div>
        <div class="flex align-items-center justify-content-top">
          <input-switch v-model="item.checked" @change="changeChecked(item)" />
        </div>
      </div>
      <charge-drop-down-group direction="AP" title="파트너사 → 어크로스비 청구 방법" :checked="item.checked" :surcharge-type="item.key" />
      <charge-drop-down-group direction="AR" title="어크로스비 → 고객사 청구 방법" :checked="item.checked" :surcharge-type="item.key" />
    </div>
  </div>
</template>

<script>
import { reactive, ref } from 'vue';
import ChargeDropDownGroup from '@/components/ChargeDropDownGroup.vue';
import { useStore } from 'vuex';
import ChargeConstant from '@/views/constant/charge-constant';

export default {
  components: { ChargeDropDownGroup },
  setup() {
    const store = useStore();
    const surcharges = reactive(ChargeConstant.items);
    const allSwitch = ref(true);
    const allSwitchHandle = () => {
      // eslint-disable-next-line no-param-reassign,no-return-assign
      surcharges.forEach((value) => value.checked = allSwitch.value);
    };

    const changeChecked = (item) => {
      // eslint-disable-next-line no-shadow
      const surcharges = store.getters['fareStore/GE_SURCHARGES'].filter((value) => value.surchargeType === item.key);
      // eslint-disable-next-line no-param-reassign,no-return-assign
      surcharges.map((value) => value.checked = item.checked);
    };

    return {
      surcharges,
      allSwitch,
      allSwitchHandle,
      changeChecked,
    };
  },
};
</script>

<style scoped>

</style>
