<template>
  <div>
    <div class="card">
      <h4>HM Intl`</h4>
      <SingleFileSelect accept=".xlsx,.xls,.csv" :disabled="fileDetail?.status === 'LOADING'" @change:file="onSelect" />
      <hr/>
      <div v-if="fileDetail" class="flex-column">
        <Message v-if="fileDetail.status === 'ERROR'" class="col" severity="error" :closable="false">{{ fileDetail.description }}</Message>
        <UploadFileDetail v-else :detail="fileDetail" />
        <div v-if="fileDetail.status === 'LOADED'" class="flex justify-content-center">
          <div class="p-inputgroup" style="width: 300px;">
            <InputText v-if="env !== 'production'" v-model="customerId" placeholder="Customer ID" />
            <Button class="block" label="업로드" icon="pi pi-upload" @click="onUpload" />
          </div>
        </div>
      </div>
    </div>
    <Dialog header="주문 등록" v-model:visible="showConfirmDialog" :style="{ width: '350px'}" :modal="true"
            :closable="false"
    >
      <div class="flex align-items-center justify-content-center">
        <i class="pi pi-info-circle mr-3" style="font-size: 2rem" />
        <div>
          <h6>{{ customerId }}, {{ storeId }}</h6>
          <div>{{ fileDetail.orders.length }} 건의 주문을 등록하시겠습니까?</div>
        </div>
      </div>
      <template #footer>
        <ProgressBar v-if="fileDetail.status === 'UPLOADING'" mode="indeterminate"/>
        <div v-else>
          <Button label="No" icon="pi pi-times" @click="showConfirmDialog = false" class="p-button-text"/>
          <Button label="Yes" icon="pi pi-check" @click="onConfirm" class="p-button-text" autofocus />
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import { uploadRawOrders } from '@/apis/raw-orders-api';
import SingleFileSelect from '@/components/SingleFileSelect.vue';
import UploadFileDetail from '@/views/upload-orders/UploadFileDetail.vue';
import * as excelReader from '@/services/excel-reader/excel-reader';
import HmDefault from '@/views/upload-orders/excel-formats/raw-order-hm-default';
import { validateOrderIds } from '@/utils/StringUtil';

export default {
  name: 'UploadRawOrders',
  components: { UploadFileDetail, SingleFileSelect },
  data() {
    return {
      customerId: 'hm-intl',
      fileDetail: null,
      showConfirmDialog: false,
    };
  },
  computed: {
    env() {
      // eslint-disable-next-line no-undef
      return global.VUE_APP_ENV;
    },
    storeId() {
      return 'hm-store';
    },
  },
  methods: {
    async onSelect({ file }) {
      this.fileDetail = null;
      if (file) {
        this.fileDetail = { status: 'LOADING' };
        try {
          const { isValid, rows, errors } = await excelReader.read(HmDefault.columns, file);
          if (isValid) {
            if (validateOrderIds(rows)) {
              this.fileDetail = {
                storeId: this.storeId,
                rows,
                orders: HmDefault.convert(rows),
                status: 'LOADED',
                description: '파일이 정상적으로 로드되었습니다.',
              };
              this.status = 'LOADED';
            } else {
              this.fileDetail = {
                status: 'ERROR',
                description: '주문번호가 올바르지 않습니다. 주문번호는 문자, 숫자, -, _ 으로 이루워져야 합니다.',
                errors,
              };
            }
          } else {
            this.fileDetail = {
              status: 'ERROR',
              description: '파일을 인식할 수 없습니다.',
              errors,
            };
          }
        } catch (e) {
          console.log(e);
        }
      }
    },
    onUpload() {
      this.showConfirmDialog = true;
    },
    async onConfirm() {
      this.fileDetail.status = 'UPLOADING';
      try {
        await uploadRawOrders({
          customerId: this.customerId.toLowerCase(),
          storeId: this.storeId,
          rawOrders: this.fileDetail.orders,
        });
        this.$toast.add({
          severity: 'info', summary: 'Success', detail: `Orders(${this.fileDetail.orders.length}) 가 등록 되었습니다.`, life: 5000,
        });
      } catch {
        this.$toast.add({
          severity: 'error', summary: 'Error', detail: '등록에 실패하였습니다.', life: 5000,
        });
      } finally {
        this.showConfirmDialog = false;
        this.fileDetail = null;
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>
