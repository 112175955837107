<template>
  <div>
    <fare-api ref="axios"></fare-api>
    <main-card :title="title">
      <template #content>
        <div class="grid flex justify-content-end flex-wrap">
          <div class="p-inputgroup col-6 hidden">
            <InputText type="text" placeholder="주문 번호, 운송장 번호 검색" style="width:300px"/>
            <Button>
              <i class="pi pi-search"></i>
            </Button>
          </div>
          <div class="flex m-2">
            <Button label="삭제" icon="pi pi-trash"  @click="deleteRows"></Button>
          </div>
        </div>
        <DataTable
          :value="list"
          responsive-layout="scroll"
          class="p-datatable-gridlines"
          :rows="10" dataKey="fareId"
          :rowHover="true"
          :paginator="true"
          :lazy="true"
          v-model:selection="selectedRows"
          selection-mode="multiple"
          :total-records="totalCount"
          @page="onPage($event)"
        >
          <template #empty>
            결과 없음
          </template>
          <slot name="columns"></slot>
        </DataTable>
      </template>
    </main-card>
  </div>
</template>

<script>
import FareApi from '@/apis/FareApi.vue';
import MainCard from '@/components/MainCard.vue';

export default {
  name: 'FareTypeTable',
  props: {
    title: null,
    targets: null,
  },
  components: {
    MainCard,
    FareApi,
  },
  data() {
    return {
      list: [],
      selectedRows: [],
      totalCount: null,
      fareTypes: this.targets,
    };
  },
  computed: {
  },
  methods: {
    errorClass(data) {
      return data > 0 ? 'text-pink-500' : '';
    },
    async deleteRows() {
      const result = await this.$refs.axios.deleteFareByIds(this.selectedRows.map((v) => v.fareId));
      if (result) {
        this.$toast.add({
          severity: 'success',
          summary: '삭제 완료',
          life: 3000,
        });
        this.selectedRows = [];
        await this.loadTable(0, 10);
      }
    },
    async onPage(event) {
      this.progress = true;
      await this.loadTable(event.page, event.rowCount);
    },
    async loadTable(page, rowCount) {
      const request = {
        fareTypes: this.fareTypes.map((v) => v.key),
        page,
        rowCount,
      };
      const { list, totalCount } = await this.$refs.axios.getFareTariffByFareType(request);
      this.totalCount = totalCount;
      this.list = list;
      this.progress = false;
    },
  },
  async mounted() {
    await this.loadTable(0, 10);
  },
};
</script>

<style lang="scss">
.month-select-calendar {
  width: 90px;
}
</style>
