<template>
  <div class="md:col-3 lg:col-3 xl:col-3 sm:col-4">
    <div class="card mb-0">
      <template v-if="detail.status === 'LOADING'">
        <Skeleton width="50%" class="mb-3" />
        <Skeleton class="mb-3" />
        <Skeleton />
      </template>
      <template v-else-if="detail.status === 'ERROR'">
        <div class="block text-500 font-medium mb-3">{{ detail?.storeId ?? 'Unknown' }}</div>
        <div class="text-900 font-medium text-xl mb-1">{{ detail.errors.length }} Errors</div>
        <div>{{ detail.errors.slice(0, 30).map(({ value }) => value).join(', ') }}</div>
      </template>
      <div v-else class="mb-3">
        <div class="block text-500 font-medium mb-3">{{ detail.storeId }}</div>
        <div class="text-900 font-medium text-xl">{{ detail.orders.length }} Orders</div>
        <span class="text-green-500 font-medium">{{ detail.rows.length }} </span>
        <span class="text-500"> Rows</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    detail: { type: Object },
  },
};
</script>
