<template>
  <fare-api ref="axios"></fare-api>
  <DataTable :value="tables" responsive-layout="scroll">
    <Column v-for="col in columns" :lazy="true" :field="col.field" :header="col.header" :key="col.field"></Column>
    <template #empty>
      결과 없음
    </template>
  </DataTable>
</template>

<script>
import FareApi from '@/apis/FareApi.vue';
import ChargeConstant from '@/views/constant/charge-constant';
import { getCarrierData } from '@/views/constant/carriers-info';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    FareApi,
  },
  data() {
    return {
      columns: null,
      tables: null,
    };
  },
  props: {
    type: null,
  },
  mounted() {
    this.emitter.on('searchDate', async (request) => {
      await this.showTable(request);
    });
    this.emitter.on('clearTable', () => {
      this.tables = null;
      this.columns = null;
    });
  },
  computed: {
    ...mapGetters('fareStore', [
      'GE_SELECT_CARRIER',
    ]),
  },
  unmounted() {
    this.emitter.off('searchDate');
    this.emitter.off('clearTable');
  },
  methods: {
    ...mapActions('fareStore', [
      'AC_FARE_DATE',
    ]),
    getCarrierData,
    async showTable(request) {
      const fareList = await this.$refs.axios.getFareTariff(request);
      this.columns = [
        { field: 'fareType', header: '서비스' },
        { field: 'category', header: '요금 적용' },
        { field: 'tariffType', header: '청구 방법' },
        { field: 'chargePrice', header: '단가' },
      ];
      const self = this;
      this.tables = fareList.map((value) => {
        // eslint-disable-next-line no-return-assign,no-param-reassign
        value.tariffType = ChargeConstant.inputTypes.find((inputType) => inputType.key === value.tariffType).title;
        const carrierData = getCarrierData(this.GE_SELECT_CARRIER.key);
        // eslint-disable-next-line no-param-reassign
        value.fareType = carrierData.shippingTypes.find((item) => value.fareType === item.key).name;
        // eslint-disable-next-line no-param-reassign
        value.category = carrierData.items.find((item) => value.category === item.key).title;

        const date = { startDate: new Date(value.startDate).getTime(), endDate: new Date(value.endDate).getTime() };
        self.AC_FARE_DATE(date);
        return value;
      }).sort((s1, s2) => (s1.fareType > s2.fareType ? 0 : -1));

      if (fareList.length === 0) {
        this.$toast.add({
          severity: 'error',
          summary: '조회 결과',
          detail: `고객사 : ${request.customerId}, 타입: ${this.type}`,
          life: 3000,
        });
        this.tables = null;
      }
    },
  },
};
</script>
