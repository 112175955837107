<template>
  <fare-api ref="axios"></fare-api>
  <div>
    <main-card title="운임 관리">
      <template v-slot:content>
        <div class="grid">
          <div class="col-8">
            <customer-dropdown-group :filtered-mode="true" @close="searchDate" style-class="col-4" date-title="운임 기간"></customer-dropdown-group>
          </div>
          <div class="col-1 col-offset-3">
            <div class="grid">
              <div class="col-6 hidden">
                <h6>&nbsp;</h6>
                <Button icon="pi pi-download" @click="downloadTariff"></Button>
              </div>
              <div class="col-6">
                <h6>&nbsp;</h6>
                <Button icon="pi pi-trash" @click="deleteFare"></Button>
              </div>
            </div>
          </div>
        </div>
        <tab-view :activeIndex="activeIndex">
          <tab-panel header="AP 요금표">
            <tariff-table type="AP" />
          </tab-panel>
          <tab-panel header="AR 요금표">
            <tariff-table type="AR"/>
          </tab-panel>
          <tab-panel header="추가 운임 관리">
            <surcharge-table />
          </tab-panel>
        </tab-view>
      </template>
    </main-card>
  </div>
</template>

<script>
import TariffTable from '@/views/contract-info/fare-tariff/management/TariffTable.vue';
import CustomerDropdownGroup from '@/components/CustomerDropdownGroup.vue';
import { mapGetters } from 'vuex';
import FareApi from '@/apis/FareApi.vue';
import SurchargeTable from '@/views/contract-info/fare-tariff/management/SurchargeTable.vue';
import getCustomerText from '@/views/constant/customers-info';

export default {
  components: {
    SurchargeTable,
    CustomerDropdownGroup,
    TariffTable,
    FareApi,
  },
  computed: {
    ...mapGetters('fareStore', [
      'GE_SELECT_CUSTOMER',
      'GE_SELECT_CARRIER',
      'GE_SELECT_PARTNER',
      'GE_FARE_DATE',
      'GE_TERM',
    ]),
  },
  methods: {
    searchDate() {
      if (this.GE_SELECT_CUSTOMER.key && this.GE_SELECT_CARRIER.key && this.GE_SELECT_PARTNER.key && this.GE_TERM && this.GE_TERM[0] && this.GE_TERM[1]) {
        const request = {
          customerId: this.GE_SELECT_CUSTOMER.key,
          carrierId: this.GE_SELECT_CARRIER.key,
          partnerId: this.GE_SELECT_PARTNER.key,
          startDate: this.GE_TERM[0].getTime(),
          endDate: this.GE_TERM[1].getTime(),
        };
        this.emitter.emit('searchDate', request);
      }
    },
    downloadTariff() {
    },
    async deleteFare() {
      const request = {
        customerId: this.GE_SELECT_CUSTOMER.key,
        carrierId: this.GE_SELECT_CARRIER.key,
        partnerId: this.GE_SELECT_PARTNER.key,
        startDate: this.GE_FARE_DATE.startDate,
        endDate: this.GE_FARE_DATE.endDate,
      };
      const res = await this.$refs.axios.deleteFareTariff(request);
      if (res) {
        this.$toast.add({
          severity: 'success',
          summary: '삭제 완료',
          detail: `고객사 : ${getCustomerText(res.customerId)}`,
          life: 3000,
        });
        this.emitter.emit('clearTable');
      }
    },
  },
  data() {
    return {
    };
  },
};
</script>
