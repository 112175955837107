<script>
import axios from 'axios';
import { sumCharge } from '@/utils/FormatUtil';
import getErrorMsg from '@/apis/error-handler';

export default {
  data() {
    return {
      API_BASE_URL: `${process.env.VUE_APP_API_BASE_URL}/settlement/account-receivable`,
      api: axios.create(),
    };
  },
  created() {
    this.api.interceptors.response.use(
      (res) => res,
      (err) => {
        this.$toast.add(getErrorMsg(err));
        return err;
      },
    );
  },
  methods: {
    async registerAr(request) {
      const { data } = await this.api.put(`${this.API_BASE_URL}/register`, request);
      return data;
    },
    async getInvoiceArList(page, rowCount) {
      const { data } = await this.api.post(`${this.API_BASE_URL}/list`, { page, rowCount });
      return data;
    },
    async getInvoiceArItemList(invoiceId, page, rowCount) {
      const { data } = await this.api.post(`${this.API_BASE_URL}/item/list`, { invoiceId, page, rowCount });
      data.list.map((item) => {
        // eslint-disable-next-line no-param-reassign
        item.totalPrice = sumCharge(item);
        return item;
      });
      return data;
    },
    async validateInvoiceArItem(invoiceId) {
      const { data } = await this.api.post(`${this.API_BASE_URL}/item/list/validate`, { invoiceId });
      return data;
    },
    async deleteAllByInvoiceArId(list) {
      const { data } = await this.api.delete(`${this.API_BASE_URL}/list`, { data: { invoiceIds: list } });
      return data;
    },
  },
};
</script>
