<template>
  <confirm-dialog :title="title">
    <template v-slot:default="{  }">
      <customer-dropdown-group style-class="col-12">
        <template v-slot:add>

        </template>
      </customer-dropdown-group>
    </template>
  </confirm-dialog>
</template>

<script>
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import CustomerDropdownGroup from '@/components/CustomerDropdownGroup.vue';

export default {
  components: { CustomerDropdownGroup, ConfirmDialog },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      customer: null,
      carrier: null,
    };
  },
};
</script>

<style scoped>

</style>
