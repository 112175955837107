import gql from 'graphql-tag';
import { getDefaultClient } from '@/graphql-config';

// eslint-disable-next-line import/prefer-default-export
export async function findOrdersByTrackingNumbers(customerId, params) {
  const map = params.reduce((r, { carrierId, trackingNumber }) => {
    if (!r.has(carrierId)) {
      r.set(carrierId, []);
    }
    r.get(carrierId).push(trackingNumber);
    return r;
  }, new Map());

  const result = [];
  const responseErrors = {};
  for (const [carrierId, trackingNumbers] of map.entries()) {
    const { data, errors } = await getDefaultClient().query({
      context: { credentials: 'include' },
      errorPolicy: 'all',
      query: gql`
        query($customerId: String!, $carrierId: String!, $trackingNumbers: [String!]!) {
          orders(customerId: $customerId, carrierId: $carrierId, trackingNumbers: $trackingNumbers) {
            orderId
            storeId
            address {
              shipping {
                address1
                countryCode
                lastName
                firstName
              }
            }
            consumer {
              firstName
              lastName
            }
            price {
              currency
            }
            items {
              itemName
              itemPrice
              units
              unitsManual
              cost
              appendix {
                option
              }
            }
            tracking {
              trackingNumber
            }
          }
        }
      `,
      variables: { customerId, carrierId, trackingNumbers },
    });
    result.push(...(data?.orders ?? []));
    if (errors) {
      responseErrors.errors = errors;
    }
  }
  return { orders: result, gErrors: responseErrors.errors?.[0] };
}
