<template>
  <dashboard-api ref="axios"></dashboard-api>
  <div class="grid">
    <div class="col-12 lg:col-6 xl:col-3">
      <div class="card mb-0">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">미입고 주문</span>
            <div class="text-900 font-medium text-xl">{{ notInStock }}</div>
          </div>
          <div class="flex align-items-center justify-content-center bg-gray-100 border-round" style="width:2.5rem;height:2.5rem">
            <i class="pi pi-shopping-cart text-gray-500 text-xl"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 lg:col-6 xl:col-3">
      <div class="card mb-0">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">입고 주문</span>
            <div class="text-900 font-medium text-xl">{{ inStock }}</div>
          </div>
          <div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width:2.5rem;height:2.5rem">
            <i class="pi pi-shopping-cart text-blue-500 text-xl"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 lg:col-6 xl:col-3">
      <div class="card mb-0">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">{{ currentMonth }} 누적 파트너사 청구 금액</span>
            <div class="text-900 font-medium text-xl">{{ apInvoiceTotalCount }}</div>
          </div>
          <div class="flex align-items-center justify-content-center bg-cyan-100 border-round" style="width:2.5rem;height:2.5rem">
            <i class="pi pi-credit-card text-cyan-500 text-xl"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 lg:col-6 xl:col-3">
      <div class="card mb-0">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">{{ currentMonth }} 누적 고객사 청구 금액</span>
            <div class="text-900 font-medium text-xl">-</div>
          </div>
          <div class="flex align-items-center justify-content-center bg-purple-100 border-round" style="width:2.5rem;height:2.5rem">
            <i class="pi pi-dollar text-purple-500 text-xl"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 xl:col-6">
      <div class="card">
        <div class="flex justify-content-between align-items-center mb-5">
          <h5>고객사 별 주문 현황</h5>
        </div>
        <ul class="list-none p-0 m-0">
          <li v-for="(item, index) of customers" :key="item" class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
            <div>
              <span class="text-900 font-medium mr-2 mb-1 md:mb-0">{{ item.customer }}</span>
            </div>
            <div class="mt-2 md:mt-0 flex align-items-center">
              <div class="surface-300 border-round overflow-hidden w-10rem lg:w-6rem" style="height:8px">
                <div :class="'bg-' + this.colorMap[index] + ' h-full'" :style="'width:' + item.rate + '%'"></div>
              </div>
              <span :class="'text-' + this.colorMap[index] + ' ml-3 font-medium'">{{ item.rate }} %</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-12 xl:col-6" hidden>
      <div class="card">
        <h5>매출</h5>
        <Chart type="line" :data="lineData" />
      </div>
    </div>
  </div>
</template>

<script>
import DashboardApi from '@/apis/DashboardApi.vue';
import getCustomerText from '@/views/constant/customers-info';

export default {
  components: {
    DashboardApi,
  },
  data() {
    return {
      notInStock: null,
      inStock: null,
      apInvoiceTotalCount: null,
      currentMonth: `${new Date().getMonth() + 1} 월`,
      colorMap: ['orange-500', 'cyan-500', 'pink-500', 'green-500', 'purple-500', 'teal-500'],
      customers: null,
      lineData: null,
    };
  },
  productService: null,
  created() {
  },
  methods: {
    formatCurrency(value) {
      return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    },
    getCustomerText,
  },
  async mounted() {
    const orderData = await this.$refs.axios.getOrdersCount();
    this.notInStock = orderData.find((v) => v.orderType === 'NOT_IN_STOCK')?.orderCount ?? 0;
    this.inStock = orderData.find((v) => v.orderType === 'IN_STOCK')?.orderCount ?? 0;
    const priceData = await this.$refs.axios.getPartnerPrice();
    this.apInvoiceTotalCount = priceData.find((v) => v.type === 'AP')?.totalPrice;
    const customerData = await this.$refs.axios.getCustomerOrders();
    const totalCount = customerData.reduce((prev, item) => prev + item.orderCount, 0);
    this.customers = customerData.map((v) => ({ customer: getCustomerText(v.customerId), rate: Math.round((v.orderCount / totalCount) * 100) }));
  },
};
</script>
