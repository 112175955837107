<script>
import axios from 'axios';
import getErrorMsg from '@/apis/error-handler';

export default {
  data() {
    return {
      API_BASE_URL: `${process.env.VUE_APP_API_BASE_URL}/settlement`,
      api: axios.create(),
    };
  },
  created() {
    this.api.interceptors.response.use(
      (res) => res,
      (err) => {
        this.$toast.add(getErrorMsg(err));
        return err;
      },
    );
  },
  methods: {
    async getOrderItemList(request) {
      const { data } = await this.api.post(`${this.API_BASE_URL}/orders/list`, request);
      return data;
    },
    async getOrdersByCustomer(customerId, startTimestamp, endTimestamp) {
      const { data } = await this.api.get(`${this.API_BASE_URL}/orders/migrate?customerId=${customerId}&startTimestamp=${startTimestamp}&endTimestamp=${endTimestamp}&orderGroupStatus=DONE,ARCHIVED`);
      return data;
    },
  },
};
</script>
