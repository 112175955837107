<template>
  <prepared-tracking-api ref="api" />

  <main-card title="발급사 추가">
    <template v-slot:content>
      <div class="grid">
        <div class="col-4">
          <h6>발급사 이름(화면 상)</h6>
          <InputText placeholder="ICS KR" v-model="issuerName" />
        </div>
        <div class="col-4">
          <h6>발급사 코드</h6>
          <InputText placeholder="ics" v-model="issuerId" />
        </div>
        <div class="col-4">
          <h6>&nbsp;</h6>
          <Button @click="createIssuer()" :disabled="!(issuerName && issuerId)">생성</Button>
        </div>
      </div>
      <divider />
      <div class="col-12">
        <DataTable :value="issuers" showGridlines responsiveLayout="scroll">
          <template #header>발급사</template>
          <Column field="issuerName" header="Name"></Column>
          <Column field="id" header="Code"></Column>
        </DataTable>
      </div>
    </template>
  </main-card>

  <main-card title="배송사 추가">
    <template v-slot:content>
      <div class="grid">
        <div class="col-4">
          <h6>배송사 이름(화면 상)</h6>
          <InputText placeholder="Sagawa" v-model="carrierName" />
        </div>
        <div class="col-4">
          <h6>배송사 코드</h6>
          <InputText placeholder="sagawa" v-model="carrierId" />
        </div>
        <div class="col-4">
          <h6>&nbsp;</h6>
          <Button @click="createCarrier" :disabled="!(carrierName && carrierId)">생성</Button>
        </div>
      </div>
      <div class="col-12">
        <DataTable :value="carriers" showGridlines responsiveLayout="scroll">
          <template #header>배송사</template>
          <Column field="carrierName" header="Name"></Column>
          <Column field="id" header="Code"></Column>
        </DataTable>
      </div>
    </template>
  </main-card>

  <main-card title="배송사-발급사 계약(매핑) 관리">
    <template v-slot:content>
      <div class="grid">
        <div class="col-4">
          <h6>발급사 코드</h6>
          <InputText placeholder="ics" v-model="contractIssuerId" />
        </div>
        <div class="col-4">
          <h6>배송사 코드</h6>
          <InputText placeholder="ics" v-model="contractCarrierId" />
        </div>
        <div class="col-4">
          <h6>&nbsp;</h6>
          <Button @click="contract" :disabled="!(contractIssuerId && contractCarrierId)">생성</Button>
        </div>
      </div>
      <DataTable :value="contractedIssuerListAndSummaryCarrier" showGridlines responsiveLayout="scroll">
        <template #header>계약 관리</template>
        <Column field="issuerName" header="Issuer Name"></Column>
        <Column field="id" header="Issuer Code"></Column>
        <Column field="carrierList" header="Carriers(code)"></Column>
      </DataTable>
    </template>
  </main-card>
</template>

<script>
import PreparedTrackingApi from '@/views/prepared-tracking/PreparedTrackingApi.vue';
import { mapState } from 'vuex';

export default {
  name: 'CarrierManagePage',
  components: {
    PreparedTrackingApi,
  },
  data: () => ({
    carrierName: '',
    carrierId: '',
    issuerId: '',
    issuerName: '',
    contractIssuerId: '',
    contractCarrierId: '',
    contractedListOfIssuer: {},
  }),
  computed: {
    ...mapState('preparedTrackingStore', [
      'issuers',
      'carriers',
    ]),
    contractedIssuerListAndSummaryCarrier() {
      return this.issuers.map((i) => ({
        ...i,
        carrierList: i.carriers?.map((c) => c.carrierName).join(','),
      }));
    },
  },
  methods: {
    toastError(message = '시스템 관리자에게 문의하세요') {
      this.$toast.add({
        severity: 'error', summary: 'Error', detail: message || '시스템 관리자에게 문의하세요', life: 3000,
      });
    },
    toastSuccess(message) {
      this.$toast.add({
        severity: 'success', summary: 'Successful', detail: message, life: 3000,
      });
    },
    async initIssuers() {
      await this.$store.dispatch('preparedTrackingStore/INIT_ISSUERS');
      await this.$store.dispatch('preparedTrackingStore/INIT_CARRIERS');
      this.contractedListOfIssuer = this.issuers.reduce((acc, i) => {
        acc[i.id] = i.carriers.map((c) => ({ id: c.id, carrierName: c.carrierName }));
        return acc;
      }, {});
    },
    async createCarrier() {
      try {
        await this.$store.dispatch('preparedTrackingStore/INSERT_CARRIER', {
          carrierId: this.carrierId.trim(),
          carrierName: this.carrierName.trim(),
        });
        this.carrierName = '';
        this.carrierId = '';
        this.toastSuccess('배송사를 만들었습니다.');
      } catch (e) {
        this.toastError(e?.response?.data?.message);
      }
    },
    async createIssuer() {
      try {
        await this.$store.dispatch('preparedTrackingStore/INSERT_ISSUER', { issuerId: this.issuerId.trim(), issuerName: this.issuerName.trim() });
        this.issuerId = '';
        this.issuerName = '';
        this.toastSuccess('발급사를 만들었습니다.');
      } catch (e) {
        this.toastError(e?.response?.data?.message);
      }
    },
    async contract() {
      try {
        await this.$refs.api.contractIssuerWithCarrier(
          this.contractIssuerId.trim(),
          this.contractCarrierId.trim(),
        );
        await this.initIssuers();
        this.contractIssuerId = '';
        this.contractCarrierId = '';
        this.toastSuccess('계약관계가 되었습니다.');
      } catch (e) {
        this.toastError(e?.response?.data?.message);
      }
    },
  },
  mounted() {
    this.initIssuers();
  },
};
</script>

<style scoped lang="scss">
</style>
