<template>
  <fare-api ref="axios"></fare-api>
  <div>
    <main-card title="운임 등록">
      <template v-slot:content>
        <customer-dropdown-group @close="checkFareDate" date-title="운임 기간" style-class="col-12 md:col-3"/>
        <duplicated-fare-dialog title="중복 운임 요금표" v-if="duplicatedFare !== null" :result="duplicatedFare" @closeDialog="closeDialog"></duplicated-fare-dialog>
        <divider/>
        <h4>기본 운임 정보</h4>
        <upload-tariff-file :disabled="disableFile" style-class="col-12 md:col-3"/>
        <div class="grid p-fluid">
          <div class="col-12 md:col-3">
            <default-label label="통화"/>
            <dropdown v-model="currency" :options="currencys" optionLabel="text" placeholder="Select One"></dropdown>
          </div>
        </div>
        <divider/>
        <surcharge-cards/>
        <div class="flex justify-content-end flex-wrap">
          <div class="flex align-items-center">
            <Button label="업로드" @click="register"></Button>
          </div>
        </div>
      </template>
    </main-card>
  </div>
</template>
<script>
import UploadTariffFile from '@/views/contract-info/fare-tariff/register/UploadTariffFile.vue';
import SurchargeCards from '@/views/contract-info/fare-tariff/register/SurchargeCards.vue';
import CustomerDropdownGroup from '@/components/CustomerDropdownGroup.vue';
import { mapActions, mapGetters } from 'vuex';
import FareApi from '@/apis/FareApi.vue';
import DuplicatedFareDialog from '@/views/contract-info/fare-tariff/register/DuplicatedFareDialog.vue';
import CurrencyInfo from '@/views/constant/currency-info';
import DefaultLabel from '@/components/DefaultLabel.vue';

export default {
  name: 'TariffRegister',
  components: {
    DefaultLabel,
    DuplicatedFareDialog,
    SurchargeCards,
    UploadTariffFile,
    CustomerDropdownGroup,
    FareApi,
  },
  data() {
    return {
      fileDetail: null,
      showConfirmDialog: false,
      duplicatedFare: null,
      disableFile: true,
      currency: { },
      term: null,
      uploadComplete: false,
      currencys: CurrencyInfo.currencyMap,
    };
  },
  computed: {
    ...mapGetters('fareStore', [
      'GE_SELECT_CUSTOMER',
      'GE_SELECT_CARRIER',
      'GE_SELECT_PARTNER',
      'GE_START_DATE',
      'GE_END_DATE',
      'GE_TARIFF_FILES',
      'GE_SURCHARGES',
    ]),
  },
  methods: {
    ...mapActions('fareStore', [
      'AC_TARIFF_FILES_CLEAR',
      'AC_SURCHARGES_CLEAR',
    ]),
    closeDialog() {
      this.duplicatedFare = null;
    },
    async checkFareDate() {
      const request = {
        customerId: this.GE_SELECT_CUSTOMER.key,
        carrierId: this.GE_SELECT_CARRIER.key,
        partnerId: this.GE_SELECT_PARTNER.key,
        fareType: 'AP',
        startDate: this.GE_START_DATE,
        endDate: this.GE_END_DATE,
      };
      const result = await this.$refs.axios.checkFareDate(request);
      this.duplicatedFare = result === '' ? null : result;
      this.disableFile = result !== '';
    },
    async register() {
      const registerFareList = [];
      for (const sheet of this.GE_TARIFF_FILES) {
        const registerFareData = {
          customerId: this.GE_SELECT_CUSTOMER.key,
          carrierId: this.GE_SELECT_CARRIER.key,
          partnerId: this.GE_SELECT_PARTNER.key,
          fareType: sheet.surchargeType !== undefined ? `${sheet.surchargeType}_${sheet.type}` : sheet.type,
          currency: this.currency.key,
          startDate: this.GE_START_DATE,
          endDate: this.GE_END_DATE,
          fromCountryCode: 'KR',
          tariffJson: JSON.stringify(sheet.tariff),
          chargePrice: null,
          tariffType: 'TARIFF',
        };
        registerFareList.push(registerFareData);
      }

      for (const item of this.GE_SURCHARGES) {
        if (item.checked && item.key !== 'TARIFF') {
          const registerFareData = {
            customerId: this.GE_SELECT_CUSTOMER.key,
            carrierId: this.GE_SELECT_CARRIER.key,
            partnerId: this.GE_SELECT_PARTNER.key,
            fareType: `${item.surchargeType}_${item.direction}`,
            currency: this.currency.key,
            startDate: this.GE_START_DATE,
            endDate: this.GE_END_DATE,
            fromCountryCode: 'KR',
            tariffJson: null,
            chargePrice: item.value,
            tariffType: item.key,
          };
          registerFareList.push(registerFareData);
        }
      }

      await this.$refs.axios.registerFare(registerFareList);

      this.$toast.add({
        severity: 'success',
        summary: 'Tariff 업로드 성공',
        detail: `고객사 : ${this.GE_SELECT_CUSTOMER.text}`,
        life: 3000,
      });
      this.AC_SURCHARGES_CLEAR();
      return '';
    },
  },
};
</script>

<style scoped lang="scss">
</style>
