import PreparedTrackingApi from '@/apis/prepared-tracking-api';

const preparedTrackingStore = {
  namespaced: true,
  state: {
    preparedTrackingNumbers: [],
    size: 0,
    totalPages: 0,
    totalElements: 0,
    pageable: {
      offset: 0,
      pageNumber: 0,
      pageSize: 10,
      paged: false,
      sort: { empty: false, sorted: false, unsorted: false },
      unpaged: false,
    },
    issuers: [],
    carriers: [],
    settings: {},
    safetySettings: {},
  },
  getters: {
    preparedTrackingNumbers: (state) => state.preparedTrackingNumbers,
    size: (state) => state.size,
    totalPages: (state) => state.totalPages,
    totalElements: (state) => state.totalElements,
    pageable: (state) => state.pageable,
    issuers: (state) => state.issuers,
    carriers: (state) => state.carriers,
    settings: (state) => state.settings,
  },
  mutations: {
    INIT(state, data) {
      state.size = data.size;
      state.totalPages = data.totalPages;
      state.totalElements = data.totalElements;
      state.pageable = data.pageable;
      state.preparedTrackingNumbers = data.content;
    },
    UPSERT(state, data) {
      state.size = data.size;
      state.totalPages = data.totalPages;
      state.totalElements = data.totalElements;
      state.pageable = data.pageable;
      state.preparedTrackingNumbers = [...state.preparedTrackingNumbers, ...data.content];
    },
    ADD_ISSUER(state, issuer) {
      state.issuers.push(issuer);
    },
    INIT_ISSUERS(state, issuers) {
      state.issuers = issuers;
    },
    ADD_CARRIER(state, carrier) {
      state.carriers.push(carrier);
    },
    INIT_CARRIERS(state, carriers) {
      state.carriers = carriers;
    },
    INIT_SETTINGS(state, settings) {
      state.settings = settings;
    },
    ADD_SETTING(state, setting) {
      state.settings[setting.id] = setting.settingValue;
    },
    INIT_SAFETY_SETTING(state, bands) {
      const normalizedResult = bands.reduce((acc, band) => {
        const key = `${band.issuerId}-${band.carrierId}`;
        acc[key] = band.bandWidth;
        return acc;
      }, {});
      state.safetySettings = normalizedResult;
    },
    UPSERT_SAFETY_SETTING(state, band) {
      state.safetySettings[`${band.issuerId}-${band.carrierId}`] = band.bandWidth;
    },
  },
  actions: {
    UPDATE_PREPARED_TRACKING_NUMBERS({ commit }, data) {
      commit('INIT', data);
    },
    async INIT_ISSUERS({ commit }) {
      const issuers = await PreparedTrackingApi.create().listOfIssuerWithContractedCarriers();
      commit('INIT_ISSUERS', issuers);
    },
    async INIT_CARRIERS({ commit }) {
      const issuers = await PreparedTrackingApi.create().listOfCarriers();
      commit('INIT_CARRIERS', issuers);
    },
    async INSERT_ISSUER({ commit }, { issuerId, issuerName }) {
      await PreparedTrackingApi.create().createIssuer({ issuerId, issuerName });
      commit('ADD_ISSUER', { id: issuerId, issuerName });
    },

    async INSERT_CARRIER({ commit }, { carrierId, carrierName }) {
      await PreparedTrackingApi.create().createCarrier({ carrierId, carrierName });
      commit('ADD_CARRIER', { id: carrierId, carrierName });
    },
    async INIT_SETTINGS({ commit }) {
      const settings = await PreparedTrackingApi.create().listOfSettings();
      commit('INIT_SETTINGS', settings);
    },
    async UPDATE_SETTING({ commit }, { id, value }) {
      await PreparedTrackingApi.create().createOrUpdateSetting({ id, value });
      commit('ADD_SETTING', { id, settingValue: value });
    },
    async INIT_SAFETY_SETTING({ commit }) {
      const result = await PreparedTrackingApi.create().listOfSafetySetting();
      commit('INIT_SAFETY_SETTING', result);
    },
    async UPDATE_SAFETY_SETTING({ commit }, { issuer, carrier, value }) {
      const args = { issuerId: issuer, carrierId: carrier, bandWidth: Number(value) };
      await PreparedTrackingApi.create().upsertSafetySetting(args);
      commit('UPSERT_SAFETY_SETTING', args);
    },
  },
};

export default preparedTrackingStore;
