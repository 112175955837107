const fareStore = {
  namespaced: true,
  state: {
    customer: { },
    carrier: { },
    partner: { },
    term: [],
    tariffFiles: [],
    fareDate: { },
    surcharges: [],
  },
  getters: {
    GE_SELECT_CUSTOMER: (state) => state.customer,
    GE_SELECT_CARRIER: (state) => state.carrier,
    GE_SELECT_PARTNER: (state) => state.partner,
    GE_START_DATE: (state) => (state.term.length !== 0 ? state.term[0].getTime() : []),
    GE_END_DATE: (state) => (state.term.length !== 0 ? state.term[1].getTime() : []),
    GE_TERM: (state) => state.term,
    GE_TARIFF_FILES: (state) => state.tariffFiles,
    GE_FARE_DATE: (state) => state.fareDate,
    GE_SURCHARGES: (state) => state.surcharges,
  },
  mutations: {
    MU_SELECT_CUSTOMER(state, customer) {
      state.customer = customer;
    },
    MU_SELECT_CARRIER(state, carrier) {
      state.carrier = carrier;
    },
    MU_SELECT_PARTNER(state, partner) {
      state.partner = partner;
    },
    MU_TERM(state, term) {
      state.term = term;
    },
    MU_TARIFF_FILES(state, files) {
      state.tariffFiles = files;
    },
    MU_TARIFF_FILES_PUSH(state, file) {
      const item = state.tariffFiles.find((value) => value.surchargeType === file.surchargeType && value.type === file.type);
      if (item) {
        Object.assign(item, file);
      } else {
        state.tariffFiles.push(file);
      }
    },
    MU_FARE_DATE(state, date) {
      state.fareDate = date;
    },
    MU_TARIFF_FILE_CLEAR(state) {
      state.tariffFiles = state.tariffFiles.filter((tariffFile) => tariffFile.surchargeType !== undefined);
    },
    MU_SURCHARGES_PUSH(state, surcharge) {
      const item = state.surcharges.find((value) => value.surchargeType === surcharge.surchargeType && value.direction === surcharge.direction);
      if (item) {
        Object.assign(item, surcharge);
      } else {
        state.surcharges.push(surcharge);
      }
    },
    MU_SURCHARGES_CLEAR(state) {
      state.surcharges = [];
    },
    MU_SURCHARGES(state, surcharges) {
      state.surcharges = surcharges;
    },
  },
  actions: {
    AC_SELECT_CUSTOMER: ({ commit }, data) => {
      commit('MU_SELECT_CUSTOMER', data);
    },
    AC_SELECT_CARRIER: ({ commit }, data) => {
      commit('MU_SELECT_CARRIER', data);
    },
    AC_SELECT_PARTNER: ({ commit }, data) => {
      commit('MU_SELECT_PARTNER', data);
    },
    AC_TERM: ({ commit }, data) => {
      commit('MU_TERM', data);
    },
    AC_TARIFF_FILES: ({ commit }, data) => {
      commit('MU_TARIFF_FILES', data);
    },
    AC_TARIFF_FILES_PUSH: ({ commit }, data) => {
      commit('MU_TARIFF_FILES_PUSH', data);
    },
    AC_FARE_DATE: ({ commit }, data) => {
      commit('MU_FARE_DATE', data);
    },
    AC_SURCHARGES_PUSH: ({ commit }, data) => {
      commit('MU_SURCHARGES_PUSH', data);
    },
    AC_SURCHARGES: ({ commit }, data) => {
      commit('MU_SURCHARGES', data);
    },
    AC_TARIFF_FILES_CLEAR: ({ commit }) => {
      commit('MU_TARIFF_FILES', []);
    },
    AC_TARIFF_FILE_CLEAR: ({ commit }) => {
      commit('MU_TARIFF_FILE_CLEAR');
    },
    AC_SURCHARGES_CLEAR: ({ commit }) => {
      commit('MU_SURCHARGES_CLEAR', []);
    },
  },
};

export default fareStore;
