<template>
  <order-api ref="axios"/>
  <div>
    <div class="card">
      <h4>윤슬</h4>
      <div class="card">
        <div class="p-fluid grid">
          <div class="col">
            <InputText type="text" placeholder="Customer ID (ex. jknd, konny...)" :class="customerId ? '' : 'p-invalid'" v-model="customerId" />
          </div>
          <div class="col">
            <Dropdown v-model="selectedCarrier" :options="carriers" optionLabel="name" placeholder="Carrier" />
          </div>
        </div>
        <SingleFileSelect v-if="customerId && selectedCarrier"
                          :key="customerId + selectedCarrier?.code"
                          accept=".xlsx,.xls,.csv" :disabled="fileDetail?.status === 'LOADING'" @change:file="onSelect" />
      </div>
      <div class="card" v-if="fileDetail">
        <div class="md:col-4 lg:col-4 xl:col-4 sm:col-4">
          <div class="card mb-0">
            <template v-if="fileDetail.status === 'LOADING'">
              <Skeleton width="50%" class="mb-3" />
              <Skeleton class="mb-3" />
              <Skeleton />
            </template>
            <template v-else-if="fileDetail.status === 'ERROR'">
              <div class="block text-500 font-medium mb-3">{{ fileDetail.storeId ?? 'Unknown' }}</div>
              <div class="text-900 font-medium text-xl mb-1">{{ fileDetail.errors?.length ?? 0 }} Errors</div>
              <pre>
                <div style="overflow-wrap: break-word" class="overflow-auto" v-html="showError"></div>
              </pre>
            </template>
            <div v-else class="mb-3">
              <div class="block text-500 font-medium mb-3">{{ fileDetail.storeId }}</div>
              <div class="text-900 font-medium text-xl">{{ fileDetail.orders?.length ?? 0 }} Orders</div>
              <span class="text-green-500 font-medium">{{ fileDetail.rows?.length ?? 0 }} </span>
              <span class="text-500"> Rows</span>
            </div>
          </div>
        </div>
      </div>
      <div class="pt-2 flex justify-content-end">
        <Button :disabled="!canConvert" label="Convert" @click="onClickConvert" />
      </div>
    </div>
  </div>
</template>

<script>
import { findOrdersByTrackingNumbers } from '@/apis/orders-api';
import SingleFileSelect from '@/components/SingleFileSelect.vue';
import TrackingSfFormat from '@/views/export-declarations/excel-formats/tracking-sf-express';
import TrackingYtoCNFormat from '@/views/export-declarations/excel-formats/tracking-yto-cn';
import * as excelReader from '@/services/excel-reader/excel-reader';
import * as excelFileWriter from '@/services/excel-writer/excel-writer';
import ExportDeclarationYoonseul from '@/views/export-declarations/excel-formats/export-declaration-yoonseul';

export default {
  name: 'YoonseulExportDeclaration',
  components: { SingleFileSelect },
  data() {
    return {
      customerId: 'jknd',
      selectedCarrier: null,
      fileDetail: null,
      showConfirmDialog: false,
    };
  },
  watch: {
    customerId() {
      this.fileDetail = null;
    },
    selectedCarrier() {
      this.fileDetail = null;
    },
  },
  computed: {
    carriers() {
      return [
        { name: 'SF Express', code: 'sf-express' },
        { name: 'YTO-CN', code: 'yto-cn' },
        { name: 'ZTO', code: 'zto' },
      ];
    },
    canConvert() {
      return this.customerId && this.selectedCarrier?.code && this.fileDetail?.status === 'LOADED';
    },
    showError() {
      return this.fileDetail.errors.map(({ value }) => value).join('<hr/>');
    },
  },
  methods: {
    async onSelect({ file }) {
      this.fileDetail = null;
      if (file) {
        this.fileDetail = { status: 'LOADING' };
        try {
          const { isValid, rows, errors } = await excelReader.read(this.selectedCarrier.code === 'sf-express' ? TrackingSfFormat.columns : TrackingYtoCNFormat.columns, file);
          if (isValid) {
            const { orders, gErrors } = await findOrdersByTrackingNumbers(
              this.customerId,
              rows.filter(({ trackingNumber }) => trackingNumber).map(({ trackingNumber }) => ({ carrierId: this.selectedCarrier.code, trackingNumber })),
            );

            if (gErrors) {
              const normalizeErrors = gErrors.extensions.response.message.map((value) => ({ value: JSON.stringify(value, null, 2) }));
              this.fileDetail = { status: 'ERROR', errors: normalizeErrors };
            } else {
              this.fileDetail = {
                storeId: this.storeId,
                rows,
                orders,
                status: 'LOADED',
                description: '파일이 정상적으로 로드되었습니다.',
              };
            }
          } else {
            this.fileDetail = {
              status: 'ERROR',
              description: '파일을 인식할 수 없습니다.',
              errors,
            };
          }
        } catch (e) {
          console.log(e);
          this.fileDetail = { status: 'ERROR', errors: [{ value: '관리자에 문의' }] };
        }
      }
    },
    async onClickConvert() {
      const netWeightMap = this.fileDetail.rows.reduce((map, r) => {
        // eslint-disable-next-line no-param-reassign
        map[r.trackingNumber] = Number(r.weight);
        return map;
      }, {});
      await excelFileWriter.writeFile({
        headers: ExportDeclarationYoonseul.headers,
        rows: this.fileDetail.orders.flatMap((o) => {
          const totalItemCount = o.items.reduce((sum, { units, unitsManual }) => sum + (unitsManual ?? units), 0);
          const netWeight = this.selectedCarrier.code === 'yto-cn' ? 0.5 : (netWeightMap[o.tracking?.trackingNumber] / totalItemCount);
          const filteredItems = o.items.filter(({ units, unitsManual }) => (unitsManual ?? units) !== 0);
          return filteredItems.map((item) => ({
            trackingNumber: o.tracking?.trackingNumber,
            reporterName: '윤슬합동관세사무소',
            exportAgencyName: '제이케이앤디주식회사',
            exportShipperName: '제이케이앤디주식회사',
            agent: '조나단',
            exporterBusinessNumber: '3618601005',
            customsClearanceCode: '제이케이1181010',
            exporterZipCode: '22827',
            exporterAddress: '서울특별시 서대문구연희로11라길 10-2',
            manufacturerName: '미상',
            recipientName: [o.consumer?.firstName?.toUpperCase(), o.consumer?.lastName?.toUpperCase()].filter((v) => v).join(' '),
            declarationType: 'E',
            tradeType: '15',
            paymentType: 'TT',
            targetCountryCode: o.address.shipping?.countryCode,
            goodsLocationPostalCode: 10947,
            goodsLocationAddress: '경기도 파주시 월롱면 홀작로',
            warehouseCode: '04099999',
            drawBackRole: '1',
            drawBackType: '2',
            totalWeight: '1',
            tradeTerms: 'FOB',
            paymentCurrency: o.price.currency,
            hscode: '6205200000',
            netWeight: (netWeight * (item.unitsManual ?? item.units)).toFixed(2),
            originCountryCode: 'KR',
            itemName: [item.itemName, item.appendix?.option].filter((v) => v).join(' '),
            itemCount: (item.unitsManual ?? item.units),
            quantityUnit: 'EA',
            unitPrice: item.cost ?? item.itemPrice,
            price: (item.cost ?? item.itemPrice) * (item.unitsManual ?? item.units),
            packageCount: '1',
            packageUnit: 'CT',
            transportEquipment: 'ETC',
            loadingLocation: 'ICN',
            transportMeans: '40',
            orderId: o.orderId,
            goodsLocationAddressDetail: '70-8',
          }));
        }),
      }, { filename: `yoonseul-export-declaration-${this.fileDetail.orders.length}.xlsx` });
    },
  },
};
</script>

<style scoped>

</style>
