<template>
  <div>
    <fare-api ref="axios"></fare-api>
    <Card class="card">
      <template #title>
        <div class="flex align-items-center">
          <span class="mr-4">환율 목록</span>
        </div>
      </template>
      <template #content>
        <div class="grid flex justify-content-end flex-wrap">
          <div class="p-inputgroup col-6 hidden">
            <InputText type="text" placeholder="주문 번호, 운송장 번호 검색" style="width:300px"/>
            <Button>
              <i class="pi pi-search"></i>
            </Button>
          </div>
          <div class="flex m-2">
            <Button label="삭제" icon="pi pi-trash"  @click="deleteCurrencies"></Button>
          </div>
        </div>
        <DataTable
          :value="currencyList"
          responsive-layout="scroll"
          class="p-datatable-gridlines"
          :rows="10" dataKey="fareId"
          :rowHover="true"
          :paginator="true"
          :lazy="true"
          @page="onPage($event)"
          v-model:selection="selectedCurrencies"
          selection-mode="multiple"
          :total-records="totalCount"
        >
          <template #empty>
            결과 없음
          </template>
          <Column selectionMode="multiple" header-style="width: 3rem"></Column>
          <Column field="fareId" hidden header="id"></Column>
          <Column field="customerId" header="고객사">
            <template #body="{data}">
              <span>{{ getCustomerText(data.customerId) }}</span>
            </template>
          </Column>
          <Column field="partnerId" header="파트너사">
            <template #body="{data}">
              <span>{{ getPartnerText(data.partnerId) }}</span>
            </template>
          </Column>
          <Column field="carrierId" header="배송사">
            <template #body="{data}">
              <span>{{ getCarrierText(data.carrierId) }}</span>
            </template>
          </Column>
          <Column field="currency" header="적용통화">
          </Column>
          <Column field="chargePrice" header="환율">
            <template #body="{data}">
              <span>{{ toNumberFormat(data.chargePrice) }}</span>
            </template>
          </Column>
          <Column field="fareType" header="대상">
            <template #body="{data}">
              <span>{{ this.fareTypes.find((v) => v.key === data.fareType).text }}</span>
            </template>
          </Column>
          <Column field="startDate" header="적용 시작일">
            <template #body="{data}">
              <span>{{ toDateFromFormat(data.startDate) }}</span>
            </template>
          </Column>
          <Column field="endDate" header="적용 종료일">
            <template #body="{data}">
              <span>{{ toDateFromFormat(data.endDate) }}</span>
            </template>
          </Column>
        </DataTable>
      </template>
    </Card>
  </div>
</template>

<script>
import { toDateFromFormat, toNumberFormat } from '@/utils/FormatUtil';
import getCustomerText from '@/views/constant/customers-info';
import getCarrierText from '@/views/constant/carriers-info';
import getPartnerText from '@/views/constant/partners-info';
import FareApi from '@/apis/FareApi.vue';

export default {
  name: 'CurrencyManagement',
  components: {
    FareApi,
  },
  data() {
    return {
      currencyList: [],
      selectedCurrencies: [],
      totalCount: null,
      fareTypes: [{ key: 'CURRENCY_AP', text: '파트너사 → 어크로스비 청구' }, { key: 'CURRENCY_AR', text: '어크로스비 → 고객사 청구' }],
    };
  },
  computed: {
  },
  methods: {
    errorClass(data) {
      return data > 0 ? 'text-pink-500' : '';
    },
    toDateFromFormat,
    toNumberFormat,
    getCustomerText,
    getCarrierText,
    getPartnerText,
    async deleteCurrencies() {
      const result = await this.$refs.axios.deleteFareByIds(this.selectedCurrencies.map((v) => v.fareId));
      if (result) {
        this.$toast.add({
          severity: 'success',
          summary: '삭제 완료',
          life: 3000,
        });
        this.selectedCurrencies = [];
        await this.loadTable(0, 10);
      }
    },
    async onPage(event) {
      this.progress = true;
      await this.loadTable(event.page, event.rowCount);
    },
    async loadTable(page, rowCount) {
      const request = {
        fareTypes: this.fareTypes.map((v) => v.key),
        page,
        rowCount,
      };
      const { list, totalCount } = await this.$refs.axios.getFareTariffByFareType(request);
      this.totalCount = totalCount;
      this.currencyList = list;
      this.progress = false;
    },
  },
  async mounted() {
    await this.loadTable(0, 10);
  },
};
</script>

<style lang="scss">
.month-select-calendar {
  width: 90px;
}
</style>
