export default {
  columns: [
    {
      column: 'A',
      text: 'Order No',
      key: 'orderId',
    },
    {
      column: 'C',
      text: '신고번호',
      key: 'declarationNumber',
    },
    {
      column: 'H',
      text: '배송번호',
      key: 'trackingNumber',
    },
    {
      column: 'N',
      text: '수출국',
      key: 'countryCode',
    },
    {
      column: 'P',
      text: '총중량',
      key: 'totalWeight',
    },
    {
      column: 'Q',
      text: '총수량',
      key: 'totalItemCount',
    },
    {
      column: 'S',
      text: '총결제금액',
      key: 'totalPrice',
    },
  ],
};
