import { createStore } from 'vuex';
import fareStore from '@/store/modules/fareStore';
import orderStore from '@/store/modules/orderStore';

import { getSession } from '@/apis/session-api';
import preparedTrackingStore from '@/store/modules/preparedTrackingStore';

export default createStore({
  state: {
    session: null,
  },
  getters: {
    hasSession({ session }) {
      return !!session;
    },
  },
  mutations: {
    setSession(state, session) {
      state.session = session;
    },
  },
  actions: {
    async refreshSession({ commit }) {
      const session = await getSession();
      if (session) {
        if (session.type !== 'ADMIN') {
          const url = new URL(`${process.env.VUE_APP_API_BASE_URL}/session/logout`);
          url.searchParams.set('redirect_uri', window.location.href);
          window.location.href = url.toString();
        }
        commit('setSession', session);
      }
    },
  },
  modules: {
    fareStore,
    orderStore,
    preparedTrackingStore,
  },
});
