const orderStore = {
  namespaced: true,
  state: {
    orderName: null,
    trackingNumber: null,
    status: null,
  },
  getters: {
    GE_SELECT_ORDER: (state) => state.orderName,
    GE_SELECT_TRACKING_NUMBER: (state) => state.trackingNumber,
    GE_SELECT_STATUS: (state) => state.status,
  },
  mutations: {
    MU_SELECT_ORDER(state, orderName) {
      state.orderName = orderName;
    },
    MU_SELECT_TRACKING_NUMBER(state, trackingNumber) {
      state.trackingNumber = trackingNumber;
    },
    MU_SELECT_STATUS(state, status) {
      state.status = status;
    },
  },
  actions: {
    AC_SELECT_ORDER: ({ commit }, data) => {
      commit('MU_SELECT_ORDER', data);
    },
    AC_SELECT_TRACKING_NUMBER: ({ commit }, data) => {
      commit('MU_SELECT_TRACKING_NUMBER', data);
    },
    AC_SELECT_STATUS: ({ commit }, data) => {
      commit('MU_SELECT_STATUS', data);
    },
  },
};

export default orderStore;
