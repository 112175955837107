export default {
  surcharges: [
    {
      text: 'VAT',
      type: 'tax',
    },
    {
      text: '부가세',
      type: 'tax',
    },
    {
      text: '긴급할증료',
      type: 'emergencySurcharge',
    },
    {
      text: '반송',
      type: 'returnSurcharge',
    },
    {
      text: '재배송',
      type: 'redeliverySurcharge',
    },
    {
      text: '관세',
      type: 'duty',
    },
    {
      text: '외곽지역',
      type: 'extendedSurcharge',
    },
  ],
  getType(text) {
    return this.surcharges.find((value) => text.includes(value.text))?.type;
  },
};
