export default {
  columns: [
    {
      column: 'B',
      text: '도착국',
      key: 'destination',
    },
    {
      column: 'C',
      text: '송장번호',
      key: 'trackingNumber',
    },
    {
      column: 'D',
      text: '실무게',
      key: 'weight',
    },
  ],
};
