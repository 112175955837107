<template>
  <div>
    <prepared-tracking-api ref="api" />
    <main-card title="송장 대역대 업로드">
      <template v-slot:content>
        <prepared-tracking-upload
          @confirm="init"
        />
      </template>
    </main-card>
    <main-card title="대시보드">
      <template v-slot:content>
        <prepared-tracking-dashboard :issuersAndCount="dashboard" />
      </template>
    </main-card>
  </div>
</template>

<script>
import PreparedTrackingApi from '@/views/prepared-tracking/PreparedTrackingApi.vue';
import PreparedTrackingDashboard from '@/views/prepared-tracking/PreparedTrackingDashboard.vue';
import PreparedTrackingUpload from '@/views/prepared-tracking/PreparedTrackingUpload.vue';

export default {
  components: {
    PreparedTrackingApi,
    PreparedTrackingDashboard,
    PreparedTrackingUpload,
  },
  data() {
    return {
      dashboard: [],
    };
  },
  methods: {
    async init() {
      const {
        carriers,
        countList,
      } = await this.$refs.api.issuerListAndCount();
      this.dashboard = countList;
      this.carriers = carriers;
    },
    convertSelectItem(item) {
      return { key: item, title: item };
    },
  },
  async mounted() {
    await this.init();
  },
};
</script>
