<template>
  <fare-api ref="axios"></fare-api>
  <div>
    <main-card title="고객사 요율 관리">
      <template v-slot:content>
        <div class="grid">
          <div class="col-12">
            <customer-dropdown-group :partners-filter="['dtrans']" :customers-filter="['wooltari']" :carriers-filter="['ups']" :filtered-mode="true"
                                     @close="searchDate" style-class="col-3" date-title="운임 기간"></customer-dropdown-group>
          </div>
          <divider/>
          <div class="grid col-12 flex-nowrap p-fluid">
            <div class="col-2">
              <default-label label="서비스 삭제"/>
              <Dropdown v-model="service" :options="serviceTypes" optionLabel="name" placeholder="Select One"></Dropdown>
            </div>
            <div class="col-1">
              <div class="grid">
                <div class="col-6 hidden">
                  <h6>&nbsp;</h6>
                  <Button icon="pi pi-download" @click="downloadTariff"></Button>
                </div>
                <div class="col-6">
                  <h6>&nbsp;</h6>
                  <Button icon="pi pi-trash" @click="deleteFare"></Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <charge-table />
      </template>
    </main-card>
  </div>
</template>

<script>
import CustomerDropdownGroup from '@/components/CustomerDropdownGroup.vue';
import { mapGetters } from 'vuex';
import FareApi from '@/apis/FareApi.vue';
import ChargeTable from '@/views/contract-info/fare-tariff/customer/management/ChargeTable.vue';
import { getCarrierData } from '@/views/constant/carriers-info';
import DefaultLabel from '@/components/DefaultLabel.vue';
import getCustomerText from '@/views/constant/customers-info';

export default {
  name: 'PartnerTariffManagement',
  components: {
    ChargeTable,
    CustomerDropdownGroup,
    FareApi,
    DefaultLabel,
  },
  computed: {
    ...mapGetters('fareStore', [
      'GE_SELECT_CUSTOMER',
      'GE_SELECT_CARRIER',
      'GE_SELECT_PARTNER',
      'GE_FARE_DATE',
      'GE_TERM',
    ]),
    serviceTypes: {
      get() {
        return getCarrierData(this.GE_SELECT_CARRIER?.key)?.shippingTypes;
      },
    },
  },
  methods: {
    searchDate() {
      if (this.GE_SELECT_CUSTOMER.key && this.GE_SELECT_CARRIER.key && this.GE_SELECT_PARTNER.key && this.GE_TERM && this.GE_TERM[0] && this.GE_TERM[1]) {
        const request = {
          customerId: this.GE_SELECT_CUSTOMER.key,
          carrierId: this.GE_SELECT_CARRIER.key,
          partnerId: this.GE_SELECT_PARTNER.key,
          startDate: this.GE_TERM[0].getTime(),
          endDate: this.GE_TERM[1].getTime(),
        };
        this.emitter.emit('searchDate', request);
      }
    },
    downloadTariff() {
    },
    async deleteFare() {
      if (!this.service) {
        this.$toast.add({
          severity: 'error',
          summary: '서비스',
          detail: '서비스를 선택하세요.',
          life: 3000,
        });
        return;
      }

      const request = {
        customerId: this.GE_SELECT_CUSTOMER.key,
        carrierId: this.GE_SELECT_CARRIER.key,
        partnerId: this.GE_SELECT_PARTNER.key,
        startDate: this.GE_FARE_DATE.startDate,
        endDate: this.GE_FARE_DATE.endDate,
        fareType: this.service.key,
      };
      const res = await this.$refs.axios.deleteFareTariff(request);
      await this.searchDate();
      if (res) {
        this.$toast.add({
          severity: 'success',
          summary: '조회 결과',
          detail: `고객사 : ${getCustomerText(res.customerId)}`,
          life: 3000,
        });
      } else {
        this.$toast.add({
          severity: 'error',
          summary: '삭제 결과',
          detail: `고객사 : ${request.customerId}`,
          life: 3000,
        });
      }
    },
  },
  data() {
    return {
      service: null,
    };
  },
};
</script>
