import { DateTime } from 'luxon';
import apRincosSurcharges from '@/views/account-payable/excel-formats/ap-rincos-surcharges';
import * as excelReader from '@/services/excel-reader/excel-reader';

function convertCarrier(carrier, countryCode) {
  switch (carrier.toLowerCase()) {
    case 'b2c europe':
      return 'eu_post';
    case 'e-ems':
      return 'ems';
    case 'fedex express':
      return 'fedex';
    case 'sf kts express':
      return 'sf-express';
    case 'standard':
      if (countryCode === 'JP') {
        return 'sagawa';
      }
      if (countryCode === 'AU') {
        return 'au_post';
      }
      return 'sf';
    default:
      return carrier;
  }
}

function carriers(convertRows) {
  return [...new Set(convertRows.map((value) => value.carrierId))];
}

function setDefault(value) {
  const defaultValue = {};
  defaultValue.fromCountryCode = 'KR';
  defaultValue.currency = 'KRW';
  defaultValue.partnerId = 'rincos';
  defaultValue.customerId = 'clio';
  if (!value.orderName) {
    defaultValue.orderName = 'Unknown';
  }
  return Object.assign(value, defaultValue);
}

const rowMap = new Map();

export default {
  partnerId: 'rincos',
  customerIds: ['clio'],
  sheets: [{
    sheetName: 'INVOICE DATA LIST',
    columns: [
      [],
      [
        {
          column: 'A',
          text: '순번',
          key: 'no',
        },
        {
          column: 'B',
          text: '주문번호',
          key: 'orderName',
        },
        {
          column: 'C',
          text: '운송장번호',
        },
        {
          column: 'D',
          text: '운송장번호',
          key: 'trackingNumber',
        },
        {
          column: 'E',
          text: '수신자명',
        },
        {
          column: 'F',
          text: '상품명',
        },
        {
          column: 'G',
          text: '상품금액',
        },
        {
          column: 'H',
          text: '상품수량',
        },
        {
          column: 'I',
          text: '서비스명',
          key: 'carrier',
        },
        {
          column: 'J',
          text: '국가코드',
          key: 'toCountryCode',
        },
        {
          column: 'K',
          text: '실중량(kg)',
          key: 'weight',
        },
        {
          column: 'L',
          text: '정산중량(kg)',
          key: 'chargeableWeight',
        },
        {
          column: 'M',
          text: '배송금액',
          key: 'freightCharge',
        },
        {
          column: 'N',
          text: '입고일자',
          key: 'shipmentDate',
        },
      ]],
    convert(rows) {
      rows.forEach((value) => {
        if (!value.trackingNumber) {
          return;
        }

        const originValue = rowMap.get(value.trackingNumber) ?? value;
        originValue.freightCharge = Number.isNaN(parseInt(value.freightCharge?.replace(/[,]/g, ''), 10)) ? 0 : parseInt(value.freightCharge?.replace(/[,]/g, ''), 10);
        originValue.carrierId = convertCarrier(value.carrier, value.toCountryCode);
        rowMap.set(originValue.trackingNumber, originValue);
      });
    },
  },
  {
    sheetName: '기타수수료',
    optional: true,
    columns: [
      [],
      [
        {
          column: 'A',
          text: '순번',
          key: 'no',
        },
        {
          column: 'B',
          text: '픽업일자',
          key: 'shipmentDate',
        },
        {
          column: 'C',
          text: '해외운송장번호',
          key: 'trackingNumber',
        },
        {
          column: 'D',
          text: '린코스운송장번호',
        },
        {
          column: 'E',
          text: '서비스명',
          key: 'carrier',
        },
        {
          column: 'F',
          text: '국가코드',
          key: 'toCountryCode',
        },
        {
          column: 'G',
          text: '수수료',
          key: 'surcharge',
        },
        {
          column: 'H',
          text: '청구운임',
        },
        {
          column: 'I',
          text: '업체명',
        },
        {
          column: 'J',
          text: '송화인명',
        },
        {
          column: 'K',
          text: '기타정산운임',
        },
        {
          column: 'L',
          text: '비고',
          key: 'chargeType',
        },
      ]],
    convert(rows) {
      rows.forEach((value) => {
        if (!value.trackingNumber) {
          return;
        }
        const originValue = rowMap.get(value.trackingNumber) ?? value;

        if (value.chargeType !== undefined) {
          const surcharge = Number.isNaN(parseInt(value.surcharge?.replace(/[,]/g, ''), 10)) ? 0 : parseInt(value.surcharge?.replace(/[,]/g, ''), 10);
          originValue[apRincosSurcharges.getType(value.chargeType)] = (originValue[apRincosSurcharges.getType(value.chargeType)] ?? 0) + parseInt(surcharge, 10);
        }
        originValue.carrierId = convertCarrier(value.carrier, value.toCountryCode);
        rowMap.set(originValue.trackingNumber, originValue);
      });
    },
  },
  {
    sheetName: '차감항목',
    optional: true,
    columns: [
      [],
      [
        {
          column: 'A',
          text: '순번',
          key: 'no',
        },
        {
          column: 'B',
          text: '픽업일자',
          key: 'shipmentDate',
        },
        {
          column: 'C',
          text: '해외운송장번호',
          key: 'trackingNumber',
        },
        {
          column: 'D',
          text: '린코스운송장번호',
        },
        {
          column: 'E',
          text: '서비스명',
          key: 'carrier',
        },
        {
          column: 'F',
          text: '국가코드',
          key: 'toCountryCode',
        },
        {
          column: 'G',
          text: '수수료',
          key: 'discount',
        },
        {
          column: 'H',
          text: '청구운임',
        },
        {
          column: 'I',
          text: '업체명',
        },
        {
          column: 'J',
          text: '송화인명',
        },
        {
          column: 'K',
          text: '기타정산운임',
        },
        {
          column: 'L',
          text: '비고',
        },
      ]],
    convert(rows) {
      rows.forEach((value) => {
        if (!value.trackingNumber) {
          return;
        }

        const originValue = rowMap.get(value.trackingNumber) ?? value;

        if (value.discount !== undefined) {
          const discount = Number.isNaN(parseInt(value.discount?.replace(/[,]/g, ''), 10)) ? 0 : parseInt(value.discount?.replace(/[,]/g, ''), 10);
          const originDiscount = Number.isNaN(parseInt(originValue.discount, 10)) ? 0 : parseInt(originValue.discount, 10);
          originValue.discount = originDiscount + discount;
          if (value.carrier) {
            originValue.carrierId = convertCarrier(value.carrier, value.toCountryCode);
          }
        }
        rowMap.set(originValue.trackingNumber, originValue);
      });
    },
  },
  ],
  async read(file) {
    let isValidAll = true;
    const errorsSheets = [];
    rowMap.clear();
    for (const sheet of this.sheets) {
      const {
        isValid, rows, errors,
      } = await excelReader.readBySheetName(sheet.columns, file, sheet.sheetName);
      if (isValid) {
        sheet.convert(rows);
      } else {
        isValidAll = false;
      }

      if (errors && !sheet.optional) errorsSheets.concat(errors);
    }
    const convertRows = Array.from(rowMap.values()).filter((v) => !Number.isNaN(parseInt(v.no, 10)))
      .filter((v) => v.shipmentDate && DateTime.fromFormat(v.shipmentDate?.replace(/[/.-]/g, ' '), 'yyyy L d').isValid)
      .map((v) => setDefault(v));
    return {
      isValid: isValidAll, errors: errorsSheets, customerIds: this.customerIds, partnerId: this.partnerId, carrierIds: carriers(convertRows), rows: convertRows,
    };
  },
};
