<template>
  <div>
    <fare-api ref="axios"></fare-api>
    <main-card :title="$route.query.title" :progress="progress">
      <template v-slot:content>
        <tab-view :activeIndex="activeIndex">
          <tab-panel header="검증 요약">
            <invoice-ap-summary @validateInvoiceAp="validateInvoiceAp"/>
          </tab-panel>
          <tab-panel header="전체 AP 목록">
            <div class="grid">
              <div class="col-8">
                <div class="grid flex-nowrap p-fluid">
                  <div class="col-3">
                    <h6>상태</h6>
                    <Dropdown v-model="searchOption.status" :options="statusMap" option-label="title" placeholder="선택하세요."></Dropdown>
                  </div>
                  <div class="col-3">
                    <h6>운송사</h6>
                    <Dropdown v-model="searchOption.carrierId" :disabled="carrierMap === null" :options="carrierMap" option-label="text" placeholder="선택하세요."></Dropdown>
                  </div>
                  <div class="col-3">
                    <h6>운송장번호</h6>
                    <input-text v-model="searchOption.trackingNumber" placeholder="입력하세요."></input-text>
                  </div>
                  <div class="flex align-items-end justify-content-center p-2">
                    <Button icon="pi pi-undo" @click="refresh"></Button>
                  </div>
                  <div class="flex align-items-end justify-content-center p-2">
                    <Button icon="pi pi-search" @click="search"></Button>
                  </div>
                </div>
              </div>
            </div>
            <ap-item-table :searchOption="searchOption" />
          </tab-panel>
        </tab-view>
      </template>
    </main-card>
  </div>
</template>

<script>
import FareApi from '@/apis/FareApi.vue';
import ApItemTable from '@/views/account-payable/detail/ApItemTable.vue';
import InvoiceApSummary from '@/views/account-payable/detail/InvoiceApSummary.vue';
import apItemStatus from '@/views/account-payable/detail/apItem-status';

export default {
  components: {
    InvoiceApSummary,
    ApItemTable,
    FareApi,
  },
  methods: {
    search() {
      this.emitter.emit('searchTable');
    },
    refresh() {
      this.searchOption.status = null;
      this.searchOption.carrierId = null;
      this.searchOption.trackingNumber = null;
    },
  },
  async mounted() {
    this.carrierMap = await this.$refs.axios.getCarrierList();
    console.log(this.carrierMap);
  },
  data() {
    return {
      progress: false,
      searchOption: {
        status: null,
        carrierId: null,
        trackingNumber: null,
      },
      statusMap: apItemStatus.statusMap,
      carrierMap: null,
    };
  },
  props: {
    title: {
      type: String,
    },
  },
};
</script>
