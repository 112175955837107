export default {
  surcharges: [
    {
      text: 'ADDRESS CORRECTION',
      type: 'addressSurcharge',
    },
    {
      text: 'DUTY TAX PAID',
      type: 'duty',
    },
    {
      text: 'IMPORT EXPORT DUTIES',
      type: 'duty',
    },
    {
      text: 'IMPORT EXPORT TAXES',
      type: 'tax',
    },
  ],
  getType(text) {
    return this.surcharges.find((value) => text.includes(value.text))?.type;
  },
};
