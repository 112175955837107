import { createApp } from 'vue';
import SimpleWebWorker from 'simple-web-worker';

// import './registerServiceWorker';
import SvMain from '@/sv/main';
import MainCard from '@/components/MainCard.vue';
import mitt from 'mitt';
import axios from 'axios';
import router from './router';
import store from './store';
import App from './App.vue';
import graphqlConfig from './graphql-config';

const emitter = mitt();
axios.defaults.withCredentials = true;

const app = createApp(App);
app.config.globalProperties.emitter = emitter;
app.use(SvMain)
  .use(router)
  .use(store)
  .use({
    install(instance) {
      // eslint-disable-next-line no-param-reassign
      instance.config.globalProperties.$worker = SimpleWebWorker;
    },
  })
  .use(graphqlConfig)
  .mount('#app');

app.component('MainCard', MainCard);
