<template>
  <account-receivable-api ref="axios"></account-receivable-api>
  <confirm-dialog title="고객사 청구서 생성" @closeDialog="close">
    <template v-slot:default="{  }">
      <div class="py-1"><span>&#8226;</span>고객사 : {{ this.GE_SELECT_CUSTOMER.text }}</div>
      <div class="py-1"><span>&#8226;</span>기간 : <label class="text-primary">{{ toDateFromFormat(this.GE_START_DATE) }} ~ {{ toDateFromFormat(this.GE_END_DATE) }}</label></div>
      <div class="py-1"><span>&#8226;</span>주문 상태 : 입고</div>
      <div class="py-1"><span>&#8226;</span>청구서 이름 : <input-text v-model="arInvoiceName" placeholder="입력하세요."></input-text></div>
    </template>
    <template #footer>
      <div class="flex justify-content-end mt-2">
        <Button label="취소" icon="pi pi-times" @click="close" class="p-button-text"/>
        <Button label="생성" icon="pi pi-check" @click="createArInvoice" autofocus/>
      </div>
    </template>
  </confirm-dialog>
</template>

<script>

import { mapGetters } from 'vuex';
import { toDateFromFormat } from '@/utils/FormatUtil';
import AccountReceivableApi from '@/apis/AccountReceivableApi.vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';

export default {
  components: {
    AccountReceivableApi,
    ConfirmDialog,
  },
  data() {
    return {
      arInvoiceName: null,
    };
  },
  computed: {
    ...mapGetters('fareStore', [
      'GE_SELECT_CUSTOMER',
      'GE_START_DATE',
      'GE_END_DATE',
    ]),
  },
  methods: {
    close() {
      this.$emit('closeDialog');
    },
    async createArInvoice() {
      const request = {
        customerId: this.GE_SELECT_CUSTOMER.key,
        status: 'IN_PROGRESS',
        name: this.arInvoiceName,
        startDate: this.GE_START_DATE,
        endDate: this.GE_END_DATE,
      };
      const result = await this.$refs.axios.registerAr(request);

      if (result != null) {
        this.$toast.add({
          severity: 'success',
          summary: 'AR 생성 완료',
          detail: `고객사 : ${this.GE_SELECT_CUSTOMER.text}`,
          life: 3000,
        });
        this.$emit('closeDialog');
      }
    },
    toDateFromFormat,
  },
};
</script>

<style scoped>

</style>
