<template>
  <order-api ref="axios"/>
  <div>
    <div class="card">
      <h4>제이엘</h4>
      <div class="card">
        <div class="p-fluid grid">
          <div class="col">
            <InputText type="text" placeholder="Customer ID (ex. jknd, konny...)" :class="customerId ? '' : 'p-invalid'" v-model="customerId" />
          </div>
          <div class="col">
            <Dropdown v-model="selectedCarrier" :options="carriers" optionLabel="name" placeholder="Carrier" />
          </div>
        </div>
        <SingleFileSelect v-if="customerId && selectedCarrier"
                          :key="customerId + selectedCarrier?.code"
                          accept=".xlsx,.xls,.csv" :disabled="fileDetail?.status === 'LOADING'" @change:file="onSelect" />
      </div>
      <div class="card" v-if="fileDetail">
        <div class="md:col-4 lg:col-4 xl:col-4 sm:col-4">
          <div class="card mb-0">
            <template v-if="fileDetail.status === 'LOADING'">
              <Skeleton width="50%" class="mb-3" />
              <Skeleton class="mb-3" />
              <Skeleton />
            </template>
            <template v-else-if="fileDetail.status === 'ERROR'">
              <div class="block text-500 font-medium mb-3">{{ fileDetail.storeId ?? 'Unknown' }}</div>
              <div class="text-900 font-medium text-xl mb-1">{{ fileDetail.errors?.length ?? 0 }} Errors</div>
              <pre>
                <div style="overflow-wrap: break-word" class="overflow-auto" v-html="showError"></div>
              </pre>
            </template>
            <div v-else class="mb-3">
              <div class="block text-500 font-medium mb-3">{{ fileDetail.storeId }}</div>
              <div class="text-900 font-medium text-xl">{{ fileDetail.orders?.length ?? 0 }} Orders</div>
              <span class="text-green-500 font-medium">{{ fileDetail.rows?.length ?? 0 }} </span>
              <span class="text-500"> Rows</span>
            </div>
          </div>
        </div>
      </div>
      <div class="pt-2 flex justify-content-end">
        <Button :disabled="!canConvert" label="Convert" @click="onClickConvert" />
      </div>
    </div>
  </div>
</template>

<script>
import { findOrdersByTrackingNumbers } from '@/apis/orders-api';
import SingleFileSelect from '@/components/SingleFileSelect.vue';
import * as excelReader from '@/services/excel-reader/excel-reader';
import * as excelFileWriter from '@/services/excel-writer/excel-writer';
import TrackingJlFormat from '@/views/export-declarations/excel-formats/jl/tracking-jl';
import ExportDeclarationKexJl
from '@/views/export-declarations/excel-formats/jl/export-declaration-kex-jl';
import ExportDeclarationIcsJl
from '@/views/export-declarations/excel-formats/jl/export-declaration-ics-jl';

export default {
  name: 'JlExportDeclaration',
  components: { SingleFileSelect },
  data() {
    return {
      customerId: 'jknd',
      selectedCarrier: null,
      fileDetail: null,
      showConfirmDialog: false,
    };
  },
  watch: {
    customerId() {
      this.fileDetail = null;
    },
    selectedCarrier() {
      this.fileDetail = null;
    },
  },
  computed: {
    carriers() {
      return [
        { name: 'SF Express', code: 'sf-express' },
        { name: 'ICS', code: 'ics' },
      ];
    },
    canConvert() {
      return this.customerId && this.selectedCarrier?.code && this.fileDetail?.status === 'LOADED';
    },
    showError() {
      return this.fileDetail.errors.map(({ value }) => value).join('<hr/>');
    },
  },
  methods: {
    async onSelect({ file }) {
      this.fileDetail = null;
      if (file) {
        this.fileDetail = { status: 'LOADING' };
        try {
          const { isValid, rows, errors } = await excelReader.read(TrackingJlFormat.columns, file);
          if (isValid) {
            if (this.selectedCarrier.code === 'sf-express') {
              const { orders, gErrors } = await findOrdersByTrackingNumbers(
                this.customerId,
                rows.filter(({ trackingNumber }) => trackingNumber).map(({ trackingNumber }) => ({ carrierId: this.selectedCarrier.code, trackingNumber })),
              );

              if (gErrors) {
                const normalizeErrors = gErrors.extensions.response.message.map((value) => ({ value: JSON.stringify(value, null, 2) }));
                this.fileDetail = { status: 'ERROR', errors: normalizeErrors };
              } else {
                this.fileDetail = {
                  storeId: this.storeId,
                  rows,
                  orders,
                  status: 'LOADED',
                  description: '파일이 정상적으로 로드되었습니다.',
                };
              }
            } else {
              this.fileDetail = {
                storeId: this.storeId,
                rows,
                orders: [],
                status: 'LOADED',
                description: '파일이 정상적으로 로드되었습니다.',
              };
            }
          } else {
            this.fileDetail = {
              status: 'ERROR',
              description: '파일을 인식할 수 없습니다.',
              errors,
            };
          }
        } catch (e) {
          console.log(e);
          this.fileDetail = { status: 'ERROR', errors: [{ value: '관리자에 문의' }] };
        }
      }
    },
    convertIcs() {
      return this.fileDetail.rows.map((row) => ({
        trackingNumber: row.trackingNumber,
        declarationNumber: row.declarationNumber,
        exportNumber: '23662',
        orderId: row.orderId,
        totalItemCount: row.totalItemCount,
        totalWeight: row.totalWeight,
      }));
    },
    convertKex() {
      const { orders } = this.fileDetail;
      return this.fileDetail.rows.map((row) => {
        const order = orders.find((o) => o.tracking.trackingNumber === row.trackingNumber);
        return order ? {
          port: row.countryCode,
          trackingNumber: row.trackingNumber,
          totalItemCount: row.totalItemCount,
          totalWeight: row.totalWeight,
          totalPrice: row.totalPrice,
          itemName: 'CLOTHES',
          sender: 'JKND Co. Ltd',
          senderAddress: '782, Yeojunam-ro, Yeoju-si, Gyeonggi-do',
          zipCode: '12656',
          receiver: [order.consumer?.firstName, order.consumer?.lastName].filter((v) => v).join(' '),
          receiverAddress: [order.address.shipping?.address1, order.address.shipping?.address2].filter((v) => v).join(' '),
          declarationNumber: row.declarationNumber,
          exportNumber: '23662',
          orderId: row.orderId,
        } : undefined;
      }).filter((v) => v);
    },
    async onClickConvert() {
      const rows = this.selectedCarrier.code === 'sf-express' ? this.convertKex() : this.convertIcs();
      if (!rows || rows.length === 0) {
        this.fileDetail = { status: 'ERROR', errors: [{ value: '관리자에 문의' }] };
        return;
      }
      await excelFileWriter.writeFile({
        headers: this.selectedCarrier.code === 'sf-express' ? ExportDeclarationKexJl.headers : ExportDeclarationIcsJl.headers,
        rows,
      }, { filename: `jl-export-declaration-${this.selectedCarrier.code === 'sf-express' ? 'kex' : 'ics'}-${this.fileDetail.rows.length}.xlsx` });
    },
  },
};
</script>

<style scoped>

</style>
