<template>
  <div>
    <account-payable-api ref="axios"></account-payable-api>
    <Card class="card">
      <template #title>
        <div class="flex align-items-center">
          <span class="mr-4">파트너사 청구서 목록</span>
        </div>
      </template>
      <template #content>
        <div class="grid flex justify-content-end flex-wrap">
          <div class="p-inputgroup col-6 hidden">
            <InputText type="text" placeholder="주문 번호, 운송장 번호 검색" style="width:300px"/>
            <Button>
              <i class="pi pi-search"></i>
            </Button>
          </div>
          <div class="flex m-2">
            <router-link tag="button"
                         to="/account-payable/invoice/register">
              <Button label="파트너사 청구서 업로드" icon="pi pi-upload"></Button>
            </router-link>
          </div>
          <div class="flex m-2">
            <Button label="삭제" icon="pi pi-trash"  @click="deleteInvoiceAps"></Button>
          </div>
        </div>
        <DataTable
          :value="invoiceList"
          responsive-layout="scroll"
          class="p-datatable-gridlines"
          :rows="rowsCount" dataKey="id"
          :rowHover="true"
          :paginator="true"
          :lazy="true"
          v-model:selection="selectedInvoices"
          :total-records="totalCount"
          @row-dblclick="selectedInvoiceAp"
          selection-mode="multiple"
          @page="onPage($event)"
        >
          <template #empty>
            결과 없음
          </template>
          <Column selectionMode="multiple" header-style="width: 3rem"></Column>
          <Column field="id" hidden header="id"></Column>
          <Column field="status" header="상태">
          </Column>
          <Column field="customer" header="고객사">
          </Column>
          <Column field="partner" header="파트너사">
          </Column>
          <Column field="carrier" header="배송사">
          </Column>
          <Column field="name" header="청구서명">
          </Column>
          <Column field="startDate" header="청구 시작일">
            <template #body="{data}">
              <span>{{ toDateFromFormat(data.startDate) }}</span>
            </template>
          </Column>
          <Column field="endDate" header="청구 종료일">
            <template #body="{data}">
              <span>{{ toDateFromFormat(data.endDate) }}</span>
            </template>
          </Column>
          <Column field="apItemCount" header="총 주문">
          </Column>
          <Column field="mismatchCount" header="확인 필요">
            <template #body="{data}">
              <div v-if="data.status !== '청구서 미검증'" :class="errorClass(data.mismatchCount)">
                {{data.mismatchCount}}
              </div>
              <div v-else>
                -
              </div>
            </template>
          </Column>
          <Column field="totalPrice" header="청구금액">
            <template #body="{data}">
              <div v-if="data.status !== '청구서 미검증'">
                {{ toNumberFormat(data.totalPrice) }}
              </div>
              <div v-else>
                -
              </div>
            </template>
          </Column>
        </DataTable>
      </template>
    </Card>
  </div>
</template>

<script>
import AccountPayableApi from '@/apis/AccountPayableApi.vue';
import { toDateFromFormat, toNumberFormat } from '@/utils/FormatUtil';
import getCustomerText from '@/views/constant/customers-info';
import getCarrierText from '@/views/constant/carriers-info';
import getPartnerText from '@/views/constant/partners-info';

export default {
  name: 'ApList',
  components: {
    AccountPayableApi,
  },
  data() {
    return {
      invoiceList: [],
      selectedInvoices: null,
      totalCount: 0,
      rowsCount: 10,
    };
  },
  computed: {
  },
  methods: {
    errorClass(data) {
      return data > 0 ? 'text-pink-500' : '';
    },
    selectedInvoiceAp(e) {
      this.$router.push({ name: 'AccountPayableDetail', query: { invoiceId: e.data.id, title: e.data.name } });
    },
    toNumberFormat,
    toDateFromFormat,
    async deleteInvoiceAps() {
      const result = await this.$refs.axios.deleteAllByInvoiceApId(this.selectedInvoices.map((v) => v.id));
      if (result) {
        this.$toast.add({
          severity: 'success',
          summary: '삭제 완료',
          life: 3000,
        });
        await this.loadTable();
      }
    },
    async onPage(event) {
      this.progress = true;
      await this.loadTable(event.page + 1, event.rowCount);
    },
    async loadTable(page, rowCount) {
      const { list, totalCount } = await this.$refs.axios.getInvoiceApList(page, rowCount);
      const statusMap = [{ key: 'NOT_VERIFIED', title: '청구서 미검증' }, { key: 'VERIFIED', title: '청구서 검증완료' }, { key: 'IN_PROGRESS', title: '청구서 검증 진행' }];
      this.totalCount = totalCount;
      this.invoiceList = list.map((row) => {
        const {
          status, customerIds, carrierIds, partnerIds, ...extra
        } = row;
        const customer = [...new Set(customerIds)].map((customerId) => getCustomerText(customerId)).join(', ');
        const carrier = [...new Set(carrierIds)].map((carrierId) => getCarrierText(carrierId)).join(', ');
        const partner = [...new Set(partnerIds)].map((partnerId) => getPartnerText(partnerId)).join(', ');
        return {
          status: statusMap.find((value) => value.key === status).title,
          customer,
          carrier,
          partner,
          ...extra,
        };
      });
      this.progress = false;
    },
  },
  async mounted() {
    await this.loadTable(1, this.rowsCount);
  },
};
</script>

<style lang="scss">
.month-select-calendar {
  width: 90px;
}
</style>
