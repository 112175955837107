<template>
  <div>
    <account-payable-api ref="axios"></account-payable-api>
    <main-card title="파트너사 청구서 파일 업로드" :progress="progress">
      <template v-slot:content>
        <customer-dropdown-group style-class="col-3" targets="partner" :using-date=false></customer-dropdown-group>
        <div class="grid">
          <SingleFileSelect
            label="업로드 파일"
            placeholder="업로드 파일을 선택하세요"
            class="col-6"
            accept=".xlsx,.xls,.csv"
            :disabled="fileDetail?.status === 'LOADING' || progress"
            @change:file="({ file }) => onFileSelect(file)"
          />
        </div>
        <div v-if="fileDetail?.status === 'LOADED'" class="grid p-fluid">
          <div class="col-12 md:col-5 pt-4">
            <DefaultLabel label="업로드 파일 정보"/>
            <div v-if="customers" class="row grid">
              <DefaultLabel class="upload-file-info__label" label="고객사"/>
              <span class="p-badge mr-1" v-for="(customer, index) of customers" :key="index">
                {{getCustomerText(customer)}}
              </span>

            </div>
            <div v-if="partner" class="row grid">
              <DefaultLabel class="upload-file-info__label" label="파트너사"/>
              <span class="p-badge mr-1">
                {{getPartnerText(partner)}}
              </span>
            </div>
            <div v-if="carriers" class="row grid">
              <DefaultLabel class="upload-file-info__label" label="배송사"/>
              <span class="p-badge mr-1" v-for="(data, index) of carriers" :key="index">
                {{getCarrierText(data)}}
              </span>
            </div>

            <div v-if="fileLoadErrors" class="row grid">
              <DefaultLabel class="upload-file-info__label text-pink-500 mt-3" label="오류"/>
              <span class="p-badge m-1" v-for="(data, index) of fileLoadErrors" :key="index">
                <span>[종류 : {{data.type}}] </span>
                <span v-if="data.row !== undefined">[Row : {{data.row}}]</span>
                <span>[데이터 : {{data.value}}] </span>
              </span>
            </div>
          </div>

        </div>
        <div class="grid p-fluid" v-if="invoiceName !== null">
          <div class="col-12 md:col-4">
            <DefaultLabel label="청구서 명칭"/>
            <InputText v-model="invoiceName" >{{ invoiceName }}</InputText>
          </div>
        </div>
        <div class="grid p-fluid" v-if="settlementPeriodFrom !== null && settlementPeriodTo !== null">
          <div class="col-12 md:col-3">
            <DefaultLabel label="청구서 기간"/>
            <span>{{ settlementPeriodFrom }} ~ {{ settlementPeriodTo }}</span>
          </div>
        </div>
      </template>
    </main-card>
    <div class="flex justify-content-end flex-wrap">
      <div class="flex align-items-center">
        <Button :disabled="progress" label="업로드" @click="register"></Button>
      </div>
    </div>
  </div>
</template>

<script>

import SingleFileSelect from '@/components/SingleFileSelect.vue';
import apSfExpress from '@/views/account-payable/excel-formats/ap-sf-express';
import apRincos from '@/views/account-payable/excel-formats/ap-rincos';
import DefaultLabel from '@/components/DefaultLabel.vue';
import AccountPayableApi from '@/apis/AccountPayableApi.vue';
import getCustomerText from '@/views/constant/customers-info';
import getCarrierText from '@/views/constant/carriers-info';
import getPartnerText from '@/views/constant/partners-info';
import apIcs from '@/views/account-payable/excel-formats/ap-ics';
import CustomerDropdownGroup from '@/components/CustomerDropdownGroup.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'ApUpload',
  components: {
    CustomerDropdownGroup,
    DefaultLabel,
    SingleFileSelect,
    AccountPayableApi,
  },
  data() {
    return {
      settlementPeriodFrom: null,
      settlementPeriodTo: null,
      fileDetail: null,
      progress: false,
      loadedFileInfo: {},
      invoiceName: null,
      term: null,
    };
  },
  methods: {
    async register() {
      const request = {
        customerIds: this.fileDetail.customerIds,
        partnerId: this.fileDetail.partnerId,
        carrierIds: this.fileDetail.carrierIds,
        apItemList: this.fileDetail.rows,
        startDate: this.settlementPeriodFrom,
        endDate: this.settlementPeriodTo,
        name: this.invoiceName,
        apItemCount: this.fileDetail.rows.length,
        status: 'UNVALIDATED',
      };

      this.progress = true;

      const result = await this.$refs.axios.registerAp(request);

      this.progress = false;

      if (result) {
        this.$toast.add({
          severity: 'success',
          summary: '파트너사 청구서 업로드 성공',
          detail: `파트너 : ${this.fileDetail.partnerId}`,
          life: 3000,
        });
      }
    },
    async onFileSelect(file) {
      if (!file) {
        this.fileDetail = null;
        this.settlementPeriodFrom = null;
        this.settlementPeriodTo = null;
        this.invoiceName = null;
        return;
      }
      this.fileDetail = { status: 'LOADING' };
      const formats = [apSfExpress, apRincos, apIcs];
      const selectedFormat = formats.find(((value) => value.partnerId === this.GE_SELECT_PARTNER.key));
      const {
        isValid, customerIds, partnerId, carrierIds, rows, errors,
      } = await selectedFormat.read(file);
      if (isValid) {
        this.fileDetail.customerIds = customerIds;
        this.fileDetail.partnerId = partnerId;
        this.fileDetail.carrierIds = carrierIds;
        this.fileDetail.rows = rows;
        const [first, ...rest] = rows.map((v) => v.shipmentDate).sort((a, b) => new Date(b.date) - new Date(a.date));
        this.settlementPeriodFrom = first;
        this.settlementPeriodTo = rest.pop();
        this.invoiceName = file.name;
      }
      this.fileDetail.status = 'LOADED';
      if (errors.length) {
        this.fileDetail.errors = errors;
      }
      if (!selectedFormat || !isValid) this.fileDetail.errors = [{ type: 'FILE', value: '업로드 불가능한 파일 입니다. 시트 및 컬럼을 확인해주세요.' }];
    },
    getCustomerText,
    getCarrierText,
    getPartnerText,
  },
  computed: {
    ...mapGetters(
      'fareStore',
      ['GE_SELECT_PARTNER'],
    ),
    partner() {
      return this.fileDetail?.partnerId;
    },
    customers() {
      return this.fileDetail?.customerIds;
    },
    carriers() {
      return this.fileDetail?.carrierIds;
    },
    fileLoadErrors() {
      return this.fileDetail?.errors;
    },
  },
};
</script>

<style scoped lang="scss">
.month-select-calendar {
  width: 140px;
}
.upload-file-info__label {
  padding: 0.5rem;
  width: 120px
}
</style>
