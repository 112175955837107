export default {
  columns: [
    { column: 'A', text: '도착국가(DESTINATION)', key: 'destination' },
    { column: 'B', text: '배송종류(DELIVERY TYPE)', key: 'deliveryType' },
    { column: 'C', text: '송장번호(INVOICE NO)', key: 'invoiceNo' },
    { column: 'D', text: '주문번호(ORDER NO)', key: 'orderId' },
    { column: 'E', text: '보내는사람 이름(SENDER NAME)', key: 'senderName' },
    { column: 'F', text: '보내는사람 전화(SENDER TEL NO)', key: 'senderTelNo' },
    { column: 'G', text: '보내는사람 주소(SENDER ADDRESS)', key: 'senderAddress' },
    { column: 'H', text: '받는사람 이름(RECIPIENT NAME)', key: 'recipientName' },
    { column: 'I', text: '추가적인 이름(YOMIGANA 또는 회사명)', key: 'yomigana' },
    { column: 'J', text: '받는사람 전화1(RECIPIENT TEL NO 1)', key: 'recipientTelNo1' },
    { column: 'K', text: '받는사람 전화2(RECIPIENT TEL NO 2)', key: 'recipientTelNo2' },
    { column: 'L', text: '받는사람 우편번호(ZIPCODE)', key: 'zipcode' },
    { column: 'M', text: '받는사람 주소-성(STATE)', key: 'state' },
    { column: 'N', text: '받는사람 주소-시(CITY)', key: 'city' },
    { column: 'O', text: '받는사람 주소-구(DISTRICT)', key: 'district' },
    { column: 'P', text: '받는사람 나머지 주소(ADDRESS 2)', key: 'address2' },
    { column: 'Q', text: '신분증번호(ID NUMBER)', key: 'idNumber' },
    { column: 'R', text: '무게(WEIGHT) kg', key: 'weightKg' },
    { column: 'S', text: '박스갯수(BOX COUNT)', key: 'boxCount' },
    { column: 'T', text: '통화단위(CURRENCY UNIT)', key: 'currencyUnit' },
    { column: 'U', text: '배송메세지(DELIVERY MESSAGE)', key: 'deliveryMessage' },
    { column: 'V', text: '사용자1(USER DATA1)', key: 'userData1' },
    { column: 'W', text: '사용자2(USER DATA2)', key: 'userData2' },
    { column: 'X', text: '사용자3(USER DATA3)', key: 'userData3' },
    { column: 'Y', text: '상품명(ProductName) 1', key: 'productName' },
    { column: 'Z', text: '갯수(Count) 1', key: 'count' },
    { column: 'AA', text: '단가(Unit Price) 1', key: 'unitPrice' },
    { column: 'AB', text: '브랜드명(Brand) 1', key: 'brand' },
    { column: 'AC', text: '제품용량(Capacity) 1 Option', key: 'capacity' },
    { column: 'AD', text: '단위(Unit) 1', key: 'unit' },
    { column: 'AE', text: 'SKU 1', key: 'sku' },
    { column: 'AF', text: 'HSCODE', key: 'hscode' },
    { column: 'AG', text: '구매제품 URL 1', key: 'url' },
    { column: 'AH', text: '배송ID', key: 'deliveryId' },
    { column: 'AI', text: '작업순번', key: 'jobSeq' },
    { column: 'AJ', text: '배송비(원)', key: 'shippingFeeKRW' },
    { column: 'AK', text: '배송비', key: 'shippingFee' },
  ],
  convert: (rows) => {
    const orders = [];
    rows.reduce((map, r) => {
      if (!map.has(r.orderId)) {
        map.set(r.orderId, []);
      }
      map.get(r.orderId).push(r);
      return map;
    }, new Map()).forEach((arr) => {
      orders.push({ orderId: arr[0].orderId, items: arr });
    });
    if (!orders[orders.length - 1].orderId) {
      orders.pop();
    }
    return orders.map(({ orderId, items }) => items.map((i) => {
      const trimRecipientName = (i.recipientName ?? '').replace(/\s+/gi, '');
      const recipientName = trimRecipientName.length === 4 ? (`${trimRecipientName.substr(0, 2)} ${trimRecipientName.substr(2, 2)}`) : trimRecipientName;

      return {
        orderId,
        orderCreatedAt: Date.now(),
        consumer: {
          firstName: recipientName,
          lastName: '',
          phone: i.recipientTelNo1,
        },
        address: {
          billing: { recipient: {} },
          shipping: {
            recipient: {
              firstName: recipientName,
              lastName: '',
              firstNamePhoneticSymbols: i.yomigana,
              lastNamePhoneticSymbols: '',
              phone: i.recipientTelNo1,
            },
            countryCode: i.destination,
            postalCode: i.zipcode,
            state: i.state,
            city: i.city,
            address1: i.district ?? '',
            address2: i.address2,
          },
        },
        price: { currency: i.currencyUnit },
        messages: [i.deliveryMessage ?? ''],
        appendix: {
          deliveryType: i.deliveryType,
          invoiceNo: i.invoiceNo,
          deliveryId: i.deliveryId,
          jobSeq: i.jobSeq,
          recipientTelNo2: i.recipientTelNo2,
          idNumber: i.idNumber,
          shippingFee: Number(i.shippingFee),
          shippingFeeKRW: Number(i.shippingFeeKRW),
          totalWeight: Number(i.weightKg ?? 0) * 1000,
          sender: {
            name: i.senderName,
            telNo: i.senderTelNo,
            address: i.senderAddress,
          },
        },
        items: [{
          sku: i.sku,
          hscode: i.hscode,
          weight: Number(i.weightKg ?? 0) * 1000,
          itemName: i.productName,
          itemPrice: Number(i.unitPrice),
          units: Number(i.count),
          shippingTitle: `${(i.url || '')}-${i.sku}`,
          appendix: {
            userData1: i.userData1 ?? '',
            userData2: i.userData2 ?? '',
            userData3: i.userData3 ?? '',
            boxCount: Number(i.boxCount),
            unit: i.unit,
            brand: i.brand || 'KTOWN4U',
            url: i.url,
            capacity: i.capacity,
          },
        }],
      };
    }).reduce((r, i) => ({
      ...r,
      ...i,
      items: [...r.items, ...i.items],
    }), { items: [] })).map((o) => ({
      ...o,
      price: { currency: o.price.currency, totalPrice: o.items?.reduce((total, i) => total + i.itemPrice, 0) || 0 },
    }));
  },
};
