export default {
  columns: [
    { field: 'status', header: '상태' },
    { field: 'trackingNumber', header: '운송장번호' },
    { field: 'carrierId', header: '운송사' },
    { field: 'customerId', header: '고객사' },
    { field: 'orderName', header: '주문번호' },
    { field: 'fromCountryCode', header: '출발국가' },
    { field: 'toCountryCode', header: '도착국가' },
    { field: 'shipmentDate', header: '선적일' },
    { field: 'weight', header: '실무게' },
    { field: 'chargeableWeight', header: '청구무게' },
    { field: 'freightCharge', header: '항공운임' },
    { field: 'emergencySurcharge', header: '긴급비용' },
    { field: 'extendedSurcharge', header: '외곽수수료' },
    { field: 'dtpSurcharge', header: '관세대납수수료' },
    { field: 'addressSurcharge', header: '주소변경수수료' },
    { field: 'additionalSurcharge', header: '추가처리비용' },
    { field: 'fscSurcharge', header: '유류할증료' },
    { field: 'redeliverySurcharge', header: '재배송비용' },
    { field: 'returnSurcharge', header: '반송비용' },
    { field: 'duty', header: '관세' },
    { field: 'tax', header: '부가세' },
    { field: 'discount', header: '할인' },
    { field: 'currency', header: '통화' },
    { field: 'totalPrice', header: '총액' },
  ],
};
