import * as fileSaver from 'file-saver';
import DisposableWorker from '@/utils/DisposableWorker';

// eslint-disable-next-line import/prefer-default-export
export async function writeFile({ headers, rows }, { filename }) {
  const { isValid, blob, errors } = await DisposableWorker.of(new Worker('./excel-writer.worker.js', { type: 'module' })).run({
    headers,
    rows,
  });
  if (isValid) {
    fileSaver.saveAs(blob, filename);
    return { isValid: true, errors: [] };
  }

  return { isValid: false, errors };
}
