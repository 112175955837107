import { toDateFromFormat } from '@/utils/FormatUtil';
import axios from 'axios';
import * as excelFileWriter from '@/services/excel-writer/excel-writer';

function getFormattedDate(date) {
  return new Date(date + 3240 * 10000).toISOString().replace('T', ' ').replace(/\..*/, '');
}

function toClioData(items) {
  const result = [];
  let no = 0;
  const headers = [
    { label: 'no', key: 'no' },
    { label: '주문생성일(orderCreatedAt)', key: 'orderCreatedAt' },
    { label: '주문명(orderName)', key: 'orderName' },
    { label: '출고번호(packingNumber)', key: 'packingNumber' },
    { label: '배송사(carrierId)', key: 'carrierId' },
    { label: '해외운송장번호(trackingNumber)', key: 'trackingNumber' },
  ];
  for (const item of items) {
    result.push({
      no: no += 1,
      orderCreatedAt: getFormattedDate(item.orderCreatedAt),
      orderName: item.orderName,
      packingNumber: item?.packing?.packingNumber,
      carrierId: item?.tracking?.carrierId,
      trackingNumber: item?.tracking?.trackingNumber,
    });
  }
  return { headers, result };
}

function toKonnyDataByOrder(items) {
  const result = [];
  let no = 0;
  const orders = items;
  const headers = [
    { label: 'no', key: 'no' },
    { label: '요청일(orderCreatedAt)', key: 'orderCreatedAt' },
    { label: '주문번호(orderName)', key: 'orderName' },
    { label: '배송사(carrierId)', key: 'carrierId' },
    { label: '송장번호(trackingNumber)', key: 'trackingNumber' },
    { label: '상품목록', key: 'items' },
    { label: '총상품수량', key: 'totalCount' },
    { label: '총상품금액', key: 'totalPrice' },
  ];
  orders.forEach((order) => {
    result.push({
      no: no += 1,
      orderCreatedAt: getFormattedDate(order.orderCreatedAt),
      orderName: order.orderName,
      carrierId: order?.tracking?.carrierId,
      trackingNumber: order?.tracking?.trackingNumber,
      items: `${order.items.reduce((acc, cur) => {
        // eslint-disable-next-line no-param-reassign
        acc += `,${cur.sku}*${cur.units}-${(cur?.shippingTitle || '')}-${(cur?.options?.join('-') || '')}`;
        return acc;
      }, '')
        .substr(1)}`,
      totalCount: order.items.reduce((acc, cur) => {
        // eslint-disable-next-line no-param-reassign
        acc += (cur?.units ?? 0);
        return acc;
      }, 0),
      totalPrice: order.items.reduce((acc, cur) => {
        // eslint-disable-next-line no-param-reassign
        acc += (cur?.itemPrice ?? 0) * (cur?.units ?? 0);
        return acc;
      }, 0),
    });
  });
  return { headers, result };
}

function toKonnyGloabalDataByOrder(items) {
  const result = [];
  let no = 0;
  const headers = [
    { label: 'no', key: 'no' },
    { label: '요청일(orderCreatedAt)', key: 'orderCreatedAt' },
    { label: '주문번호(orderName)', key: 'orderName' },
    { label: '국가', key: 'country' },
    { label: '배송사(carrierId)', key: 'carrierId' },
    { label: '송장번호(trackingNumber)', key: 'trackingNumber' },
  ];
  for (const item of items) {
    if (!['yto-tw', 'sf-express', 'eparcel-kr'].includes(item?.tracking?.carrierId?.toLowerCase())) {
      // eslint-disable-next-line no-continue
      continue;
    }
    const { country } = item.address.shipping;
    result.push({
      no: no += 1,
      orderCreatedAt: getFormattedDate(item.orderCreatedAt),
      orderName: item.orderName,
      country,
      carrierId: item?.tracking?.carrierId,
      trackingNumber: item?.tracking?.trackingNumber,
    });
  }
  return { headers, result };
}

function toHmData(items) {
  const result = [];
  let no = 0;
  const headers = [
    { label: 'no', key: 'no' },
    { label: '요청일(orderCreatedAt)', key: 'orderCreatedAt' },
    { label: '주문번호(orderName)', key: 'orderName' },
    { label: '배송사(carrierId)', key: 'carrierId' },
    { label: '송장번호(trackingNumber)', key: 'trackingNumber' },
    { label: '배송ID', key: 'deliveryId' },
    { label: '작업순번', key: 'jobSeq' },
    { label: '상품목록', key: 'items' },
    { label: '상품수량', key: 'totalCount' },
    { label: '총상품금액', key: 'totalPrice' },
    { label: '받는사람 우편번호', key: 'postalCode' },
    { label: '받는사람 주소', key: 'addr' },
    { label: '국가', key: 'country' },
    { label: '주(state)', key: 'state' },
    { label: '도시(city)', key: 'city' },
    { label: 'addr1', key: 'address1' },
    { label: 'addr2', key: 'address2' },
    { label: 'addr3', key: 'address3' },
  ];
  for (const item of items) {
    // if(item.orderCreatedAt > dateTo) continue;
    const {
      country, state, city, address1, address2, address3,
    } = item.address.shipping;
    const addr = [country, state, city, address1, address2, address3].filter((s) => s).join(' ');
    result.push({
      no: no += 1,
      orderCreatedAt: getFormattedDate(item.orderCreatedAt),
      orderName: item.orderName,
      carrierId: item?.tracking?.carrierId,
      trackingNumber: item?.tracking?.trackingNumber,
      deliveryId: item?.customFields?.deliveryId,
      jobSeq: item?.customFields?.jobSeq,
      items: `${item.items.reduce((acc, cur) => {
        // eslint-disable-next-line no-param-reassign
        acc += `,${(cur?.appendix?.url || '')}-${cur.sku}*${cur.units}`;
        return acc;
      }, '').substr(1)}`,
      totalCount: item.items.reduce((acc, cur) => {
        // eslint-disable-next-line no-param-reassign
        acc += (cur?.units ?? 0);
        return acc;
      }, 0),
      totalPrice: item.items.reduce((acc, cur) => {
        // eslint-disable-next-line no-param-reassign
        acc += (cur?.cost ?? cur?.itemPrice) * (cur?.units);
        return acc;
      }, 0),
      postalCode: item.address.shipping?.postalCode,
      addr,
      country,
      state,
      city,
      address1,
      address2,
      address3,
    });
  }
  return { headers, result };
}

function toJKNDData(items) {
  const result = [];
  let no = 0;
  const headers = [
    { label: 'no', key: 'no' },
    { label: '요청일(orderCreatedAt)', key: 'orderCreatedAt' },
    { label: '주문번호(orderId)', key: 'orderId' },
    { label: '주문명(orderName)', key: 'orderName' },
    { label: '배송사(carrierId)', key: 'carrierId' },
    { label: '송장번호(trackingNumber)', key: 'trackingNumber' },
    { label: '상품목록', key: 'items' },
    { label: '상품수량', key: 'totalCount' },
    { label: '국가명', key: 'country' },
    { label: '총상품금액', key: 'totalPrice' },
  ];
  for (const item of items) {
    // if(item.orderCreatedAt > dateTo) continue;
    const {
      country, countryCode,
    } = item.address.shipping;
    if (countryCode !== 'CN') {
      console.log(countryCode, country);
      // eslint-disable-next-line no-continue
      continue;
    }
    result.push({
      no: no += 1,
      orderCreatedAt: getFormattedDate(item.orderCreatedAt),
      orderId: item.orderId,
      orderName: item.orderName,
      carrierId: item?.tracking?.carrierId,
      trackingNumber: item?.tracking?.trackingNumber,
      items: `${item.items.reduce((acc, cur) => {
        // eslint-disable-next-line no-param-reassign
        acc += `,${cur.sku}*${cur.units}-${(cur?.shippingTitle || '')}-${(cur?.options?.join('-') || '')}`;
        return acc;
      }, '').substr(1)}`,
      totalCount: item.items.reduce((acc, cur) => {
        // eslint-disable-next-line no-param-reassign
        acc += (cur?.units ?? 0);
        return acc;
      }, 0),
      totalPrice: item.items.reduce((acc, cur) => {
        // eslint-disable-next-line no-param-reassign
        acc += (cur?.cost ?? cur?.itemPrice) * (cur?.units);
        return acc;
      }, 0),
      country,
    });
  }
  return { headers, result };
}

function toPPBStudios(items) {
  const result = [];
  let no = 0;
  const headers = [
    { label: 'no', key: 'no' },
    { label: '사용자데이터1(customFields.storeOrderNumber)', key: 'storeOrderNumber' },
    { label: '주문명(orderName)', key: 'orderName' },
    { label: '출고번호(packingNumber)', key: 'packingNumber' },
    { label: '해외운송장번호(trackingNumber)', key: 'trackingNumber' },
  ];
  for (const item of items) {
    // if(item.orderCreatedAt > dateTo) continue;
    result.push({
      no: no += 1,
      storeOrderNumber: item?.customFields?.storeOrderNumber,
      orderName: item.orderName,
      packingNumber: item?.packing?.packingNumber,
      carrierId: item?.tracking?.carrierId,
      trackingNumber: item?.tracking?.trackingNumber,
    });
  }
  return { headers, result };
}

async function getOrders(customerId, dateFrom, dateTo) {
  // eslint-disable-next-line max-len
  const { data } = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/settlement/orders/json-list?customerId=${customerId}&startTimestamp=${dateFrom}&endTimestamp=${dateTo}&orderGroupStatus=DONE,ARCHIVED`);
  const { list } = data;
  const orders = list;
  orders.sort((s1, s2) => (s1.orderCreatedAt > s2.orderCreatedAt ? 0 : -1));
  switch (customerId) {
    case 'hm-intl':
      return toHmData(orders);
      // return toHmFor관부가세검토(result);
    case 'clio':
      return toClioData(orders);
    case 'ppb-studios':
      return toPPBStudios(orders);
    case 'jknd':
      return toJKNDData(orders);
    case 'konny':
      return toKonnyDataByOrder(orders);
      // return toKonnyFor관부가세검토(result);
    case 'konny-global':
      return toKonnyGloabalDataByOrder(orders);
    default:
      return { headers: undefined, result: undefined };
  }
}

export default async function createOrderForSettlement(customerId, startDate, endDate) {
  const fromDateStr = toDateFromFormat(startDate);
  const toDateStr = toDateFromFormat(endDate);
  const filename = `${customerId}(${fromDateStr}~${toDateStr}).xlsx`;

  const { headers, result } = await getOrders(customerId, startDate, endDate);
  if (headers !== undefined) {
    await excelFileWriter.writeFile(
      {
        headers,
        rows: result,
      }, { filename },
    );
  }
  return { headers, result };
}
