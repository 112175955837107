<template>
  <fare-type-table title="유류할증료 목록" :targets="targets">
    <template #columns>
      <Column selectionMode="multiple" header-style="width: 3rem"></Column>
      <Column field="fareId" hidden header="id"></Column>
      <Column field="customerId" header="고객사">
        <template #body="{data}">
          <span>{{ getCustomerText(data.customerId) }}</span>
        </template>
      </Column>
      <Column field="partnerId" header="파트너사">
        <template #body="{data}">
          <span>{{ getPartnerText(data.partnerId) }}</span>
        </template>
      </Column>
      <Column field="carrierId" header="배송사">
        <template #body="{data}">
          <span>{{ getCarrierText(data.carrierId) }}</span>
        </template>
      </Column>
      <Column field="tariffType" header="청구 방법">
        <template #body="{data}">
          <span>{{ convertTariffType(data.tariffType) }}</span>
        </template>
      </Column>
      <Column field="chargePrice" header="유류할증료">
        <template #body="{data}">
          <span>{{ toNumberFormat(data.chargePrice) }}</span>
        </template>
      </Column>
      <Column field="fareType" header="대상">
        <template #body="{data}">
          <span>{{ this.targets.find((v) => v.key === data.fareType).text }}</span>
        </template>
      </Column>
      <Column field="startDate" header="적용 시작일">
        <template #body="{data}">
          <span>{{ toDateFromFormat(data.startDate) }}</span>
        </template>
      </Column>
      <Column field="endDate" header="적용 종료일">
        <template #body="{data}">
          <span>{{ toDateFromFormat(data.endDate) }}</span>
        </template>
      </Column>
    </template>
  </fare-type-table>
</template>

<script>

import FareTypeTable from '@/views/contract-info/fare-type-list/FareTypeTable.vue';
import { toDateFromFormat, toNumberFormat } from '@/utils/FormatUtil';
import getCustomerText from '@/views/constant/customers-info';
import getCarrierText from '@/views/constant/carriers-info';
import getPartnerText from '@/views/constant/partners-info';
import ChargeConstant from '@/views/constant/charge-constant';

export default {
  name: 'FSCManagement',
  components: {
    FareTypeTable,
  },
  data() {
    return {
      targets: [{ key: 'FSC_AP', text: '파트너사 → 어크로스비 청구' }, { key: 'FSC_AR', text: '어크로스비 → 고객사 청구' }],
    };
  },
  computed: {
  },
  methods: {
    toDateFromFormat,
    toNumberFormat,
    getCustomerText,
    getCarrierText,
    getPartnerText,
    convertTariffType(key) {
      return ChargeConstant.getSurchargeInputTypeName(key);
    },
  },
};
</script>

<style lang="scss">
.month-select-calendar {
  width: 90px;
}
</style>
