<template>
  <prepared-tracking-api ref="api" />
  <div class="grid p-fluid">
    <div class="col-2">
      <h6>발급사-배송사</h6>
      <Dropdown v-model="carrier" placeholder="sagawa" :options="carriers" optionLabel="label" optionGroupLabel="label" optionGroupChildren="items">
        <template #optiongroup="slotProps">
          <div class="flex align-items-center country-item">
            <img src="https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png" width="18" />
            <div>{{slotProps.option.label}}</div>
          </div>
        </template>
      </Dropdown>
    </div>
    <div class="col-8">
      <h6>대역대 엑셀 파일</h6>
      <SingleFileSelect
        accept=".xlsx"
        @change:file="({ file }) => onSelect(file)"
        ref="fileSelector"
        :disabled="!carrier"
      />
      <ProgressBar mode="indeterminate" v-if="uploading" style="height: .5em; margin-top:14px;" />
    </div>
  </div>
  <div class="mt-2">
    <Button @click="uploadTracking" :disabled="!enabledUpload" :loading="uploading">송장 대역대 업로드</Button>
  </div>
</template>

<script>
import SingleFileSelect from '@/components/SingleFileSelect.vue';
import PreparedTrackingApi from '@/views/prepared-tracking/PreparedTrackingApi.vue';
import * as excelReader from '@/services/excel-reader/excel-reader';
import { mapState } from 'vuex';

export default {
  name: 'PreparedTrackingUpload',
  components: {
    SingleFileSelect,
    PreparedTrackingApi,
  },
  emits: ['confirm'],
  data: () => ({
    selectedFile: null,
    uploading: false,
    carrier: '',
    groupedCities: [{
      label: 'Germany',
      items: [
        { label: 'Berlin', value: 'Berlin' },
        { label: 'Frankfurt', value: 'Frankfurt' },
        { label: 'Hamburg', value: 'Hamburg' },
        { label: 'Munich', value: 'Munich' },
      ],
    }],
  }),
  computed: {
    enabledUpload() {
      return this.carrier && this.selectedFile;
    },
    ...mapState('preparedTrackingStore', [
      'issuers',
    ]),
    carriers() {
      return this.issuers.map((i) => ({
        label: i.issuerName,
        items: i.carriers ? i.carriers.map((j) => ({ label: `${i.issuerName}-${j.carrierName}`, value: `${i.id}.${j.id}` })) : [],
      }));
    },
  },
  methods: {
    onSelect(file) {
      this.selectedFile = file;
    },
    async uploadTracking() {
      this.uploading = true;
      const { isValid, rows, errors } = await excelReader.read([[{ column: 'A', text: '송장번호', key: 'trackingNumber' }]], this.selectedFile);
      if (isValid && !errors?.length) {
        try {
          const [issuer, carrier] = this.carrier.value.split('.');
          const trackingNumbers = rows.map((i) => i.trackingNumber);
          console.log({
            issuer,
            carrier,
            trackingNumbers,
          });
          await this.$refs.api.uploadPreparedTrackingNumbers({
            issuer,
            carrier,
            trackingNumbers,
          });
          this.carrier = '';
          this.selectedFile = null;
          this.$refs.fileSelector.clearFile();
          this.$toast.add({
            severity: 'success', summary: 'Successful', detail: '대역대 업로드 완료', life: 3000,
          });
          this.$emit('confirm');
        } catch (e) {
          if (e?.response?.data) {
            const { message } = e.response.data;
            this.$toast.add({
              severity: 'error', summary: 'Error', detail: message || '시스템 관리자에게 문의하세요', life: 3000,
            });
          }
          console.error(e);
        }
        this.uploading = false;
      } else {
        console.log('error');
      }
    },
    convertSelectItem(item) {
      return { key: item, title: item };
    },
  },
  mounted() {
    this.$store.dispatch('preparedTrackingStore/INIT_ISSUERS');
  },
};
</script>

<style scoped>

</style>
