import { DateTime } from 'luxon';
import * as excelReader from '@/services/excel-reader/excel-reader';
import apIcsSurcharges from '@/views/account-payable/excel-formats/ap-ics-surcharges';
import { toDateFromFormat } from '@/utils/FormatUtil';

function convertCarrier(carrier) {
  switch (carrier.toUpperCase()) {
    case 'AIR SAGAWA':
    case 'AIR(SAGAWA)':
      return 'sagawa';
    case 'AIR YAMATO':
    case 'AIR(YAMATO)':
      return 'yamato';
    case 'AIR YTO':
    case 'AIR(YTO)':
      return 'yto_cn';
    case 'AIR YTO-TW':
    case 'AIR(YTO-TW)':
      return 'yto_tw';
    case 'AIR NEKOPOS':
    case 'AIR(NEKOPOS)':
      return 'nekopos';
    case 'DHLDTP':
    case 'DHL':
      return 'dhl';
    default:
      return carrier.toLowerCase();
  }
}

const carrierList = ['sagawa', 'yamato', 'yto_cn', 'yto_tw', 'dhl', 'nekopos'];

function convertPrice(value) {
  return Number.isNaN(parseInt(value?.replace(/[,₩]/g, ''), 10)) ? 0 : parseInt(value?.replace(/[,₩]/g, ''), 10);
}

function removeDuplicate(convertRows, target) {
  return [...new Set(convertRows.map((value) => value[target]))];
}

function setDefault(value) {
  const defaultValue = {};
  defaultValue.fromCountryCode = value.fromCountryCode ?? 'KR';
  defaultValue.toCountryCode = value.toCountryCode ?? 'JP';
  defaultValue.currency = value.currency ?? 'KRW';
  defaultValue.partnerId = value.partnerId ?? 'ics';
  defaultValue.orderName = value.orderName ?? 'Unknown';
  if (DateTime.fromFormat(value.shipmentDate, 'm/d/yy').isValid || DateTime.fromFormat(value.shipmentDate, 'yyyy.MM.dd').isValid) {
    defaultValue.shipmentDate = toDateFromFormat(new Date(value.shipmentDate).getTime());
  }
  return Object.assign(value, defaultValue);
}

const rowMap = new Map();

export default {
  partnerId: 'ics',
  sheets: [{
    sheetName: '상세데이터',
    columns: [
      [],
      [
        {
          column: 'A',
          text: '순번',
          key: 'no',
        },
        {
          column: 'B',
          text: '고객사명',
          key: 'customerId',
        },
        {
          column: 'C',
          text: 'Date',
          key: 'shipmentDate',
        },
        {
          column: 'D',
          text: 'Bound',
        },
        {
          column: 'E',
          text: 'P/O No.',
          key: 'orderName',
        },
        {
          column: 'F',
          text: 'H.B/L No.',
          key: 'trackingNumber',
        },
        {
          column: 'G',
          text: 'flight',
          key: 'carrierId',
        },
        {
          column: 'H',
          text: 'Port',
          key: 'toCountryCode',
        },
        {
          column: 'I',
          text: 'G/W',
          key: 'weight',
        },
        {
          column: 'J',
          text: 'C/W',
          key: 'chargeableWeight',
        },
        {
          column: 'K',
          text: '항공운임 (JPY)',
        },
        {
          column: 'L',
          text: '항공운임 (KRW)',
          key: 'freightCharge',
        },
        {
          column: 'M',
          text: '유류할증료',
          key: 'fscSurcharge',
        },
        {
          column: 'N',
          text: 'Emergency Surcharge',
          key: 'emergencySurcharge',
        },
        {
          column: 'O',
          text: '외곽비용',
          key: 'extendedSurcharge',
        },
        {
          column: 'P',
          text: '관세대납수수료',
          key: 'dtpSurcharge',
        },
        {
          column: 'Q',
          text: '총 금액',
        },
        {
          column: 'R',
          text: '비고',
        },
      ]],
    convert(rows) {
      rows.forEach((value) => {
        if (!value.trackingNumber) {
          return;
        }

        const originValue = rowMap.get(value.trackingNumber.trim()) ?? value;
        originValue.freightCharge = convertPrice(value.freightCharge);
        originValue.fscSurcharge = convertPrice(value.fscSurcharge);
        originValue.emergencySurcharge = convertPrice(value.emergencySurcharge);
        originValue.extendedSurcharge = convertPrice(value.extendedSurcharge);
        originValue.dtpSurcharge = convertPrice(value.dtpSurcharge);
        originValue.carrierId = convertCarrier(value.carrierId);
        originValue.customerId = value.customerId.toLowerCase();
        rowMap.set(originValue.trackingNumber.trim(), originValue);
      });
    },
  },
  {
    sheetName: '관부가세 내역',
    optional: true,
    columns: [
      [],
      [
        {
          column: 'A',
          text: '순번',
          key: 'no',
        },
        {
          column: 'B',
          text: '고객사명',
          key: 'customerId',
        },
        {
          column: 'C',
          text: 'Date',
          key: 'shipmentDate',
        },
        {
          column: 'D',
          text: 'Bound',
        },
        {
          column: 'E',
          text: 'P/O No.',
          key: 'orderName',
        },
        {
          column: 'F',
          text: 'H.B/L No.',
          key: 'trackingNumber',
        },
        {
          column: 'G',
          text: 'flight',
          key: 'carrierId',
        },
        {
          column: 'H',
          text: '관세',
          key: 'duty',
        },
        {
          column: 'I',
          text: '부가세',
          key: 'tax',
        },
        {
          column: 'J',
          text: '세관검사비용',
          key: 'customCharge',
        },
        {
          column: 'K',
          text: '라벨서류작성비용',
          key: 'labelCharge',
        },
        {
          column: 'L',
          text: '관부가세총액',
        },
        {
          column: 'M',
          text: '비고',
        },
      ]],
    convert(rows) {
      rows.forEach((value) => {
        if (!value.trackingNumber) {
          return;
        }
        const originValue = rowMap.get(value.trackingNumber.trim()) ?? value;
        originValue.duty = convertPrice(value.duty) + convertPrice(value.customCharge) + convertPrice(value.labelCharge);
        originValue.tax = convertPrice(value.tax);
        originValue.carrierId = convertCarrier(value.carrierId);
        originValue.customerId = value.customerId.toLowerCase();
        rowMap.set(originValue.trackingNumber.trim(), originValue);
      });
    },
  },
  {
    sheetName: 'DHL 기타운임',
    optional: true,
    columns: [
      [],
      [
        {
          column: 'A',
          text: '순번',
          key: 'no',
        },
        {
          column: 'B',
          text: '고객사명',
          key: 'customerId',
        },
        {
          column: 'C',
          text: 'Date',
          key: 'shipmentDate',
        },
        {
          column: 'D',
          text: 'Bound',
        },
        {
          column: 'E',
          text: 'P/O No.',
          key: 'orderName',
        },
        {
          column: 'F',
          text: 'H.B/L No.',
          key: 'trackingNumber',
        },
        {
          column: 'G',
          text: 'flight',
          key: 'carrierId',
        },
        {
          column: 'H',
          text: '기타금액',
          key: 'surcharge',
        },
        {
          column: 'I',
          text: '기타금액 항목',
          key: 'chargeType',
        },
        {
          column: 'I',
          text: '부가운임FSC',
          key: 'additionalSurcharge',
        },
      ]],
    convert(rows) {
      rows.forEach((value) => {
        if (!value.trackingNumber) {
          return;
        }

        const originValue = rowMap.get(value.trackingNumber.trim()) ?? value;

        if (value.chargeType !== undefined) {
          originValue[apIcsSurcharges.getType(value.chargeType)] = (originValue[apIcsSurcharges.getType(value.chargeType)] ?? 0) + convertPrice(value.surcharge);
        }
        originValue.additionalSurcharge = convertPrice(value.additionalSurcharge);
        originValue.carrierId = convertCarrier(value.carrierId);
        originValue.customerId = value.customerId.toLowerCase();
        rowMap.set(originValue.trackingNumber.trim(), originValue);
      });
    },
  },
  ],
  async read(file) {
    let isValidAll = true;
    const errorsSheets = [];
    rowMap.clear();
    for (const sheet of this.sheets) {
      const {
        isValid, rows, errors,
      } = await excelReader.readBySheetName(sheet.columns, file, sheet.sheetName);
      if (isValid) {
        sheet.convert(rows);
      } else if (!sheet.optional) {
        isValidAll = false;
      }
      if (errors && !sheet.optional) errorsSheets.concat(errors);
    }
    const convertRows = Array.from(rowMap.values()).filter((v) => !Number.isNaN(parseInt(v.no, 10)))
      .filter((v) => v.shipmentDate && (DateTime.fromFormat(v.shipmentDate?.replace(/[/.-]/g, ' '), 'yyyy mm dd').isValid
      || DateTime.fromFormat(v.shipmentDate, 'm/d/yy').isValid))
      .map((v) => setDefault(v));
    const errorsAll = [...convertRows.filter((v) => !carrierList.includes(v.carrierId)).map((v) => ({ type: 'ROW', value: v.carrierId, row: v.no })), ...errorsSheets];
    return {
      isValid: isValidAll,
      errors: errorsAll,
      customerIds: removeDuplicate(convertRows, 'customerId'),
      partnerId: this.partnerId,
      carrierIds: removeDuplicate(convertRows, 'carrierId'),
      rows: convertRows,
    };
  },
};
