import axios from 'axios';

const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

const SESSION_BASE_URL = `${API_BASE_URL}/session`;

const getSession = async () => {
  const { data } = await axios.get(SESSION_BASE_URL);
  return data ? {
    customerId: data.customerId,
    id: data.id,
    type: data.type,
    nickName: data.nickName,
    authorities: data.authorities,
  } : null;
};

const logout = async () => {
  try {
    await axios.delete(SESSION_BASE_URL);
  } catch (e) {
    console.log(e.response);
  }
};

export {
  logout,
  getSession,
};
