<template>
  <div>
    <fare-api ref="axios"></fare-api>
    <main-card title="환율 등록" :progress="progress">
      <template v-slot:content>
        <customer-dropdown-group @close="checkCurrencyDate" style-class="col-3" date-title="환율 적용 기간"></customer-dropdown-group>
        <duplicated-fare-dialog title="중복 환율" v-if="duplicatedCurrency !== null" :result="duplicatedCurrency" @closeDialog="closeDialog"></duplicated-fare-dialog>
        <div class="grid p-fluid">
          <div class="col-12 md:col-3">
            <default-label label="운임 통화"/>
            <dropdown v-model="selectedCurrency" :options="currencys"
                      optionLabel="text" placeholder="Select One"></dropdown>
          </div>
        </div>
        <div class="grid p-fluid">
          <div class="col-12 md:col-3">
            <default-label label="파트너사 → 어크로스비 청구" />
            <InputNumber v-model="chargePriceAP" mode="currency" currency="KRW" :min-fraction-digits="2"/>
          </div>
        </div>
        <div class="grid p-fluid">
          <div class="col-12 md:col-3">
            <default-label label="어크로스비 → 고객사 청구" />
            <InputNumber v-model="chargePriceAR" mode="currency" currency="KRW" :min-fraction-digits="2"/>
          </div>
        </div>
      </template>
    </main-card>
    <div class="flex justify-content-end flex-wrap">
      <div class="flex align-items-center">
        <Button :disabled="progress" label="업로드" @click="register"></Button>
      </div>
    </div>
  </div>
</template>

<script>

import DefaultLabel from '@/components/DefaultLabel.vue';
import getCustomerText from '@/views/constant/customers-info';
import getCarrierText from '@/views/constant/carriers-info';
import getPartnerText from '@/views/constant/partners-info';
import CustomerDropdownGroup from '@/components/CustomerDropdownGroup.vue';
import { mapGetters } from 'vuex';
import CurrencyInfo from '@/views/constant/currency-info';
import FareApi from '@/apis/FareApi.vue';
import DuplicatedFareDialog from '@/views/contract-info/fare-tariff/register/DuplicatedFareDialog.vue';

export default {
  name: 'CurrencyRegister',
  components: {
    FareApi,
    CustomerDropdownGroup,
    DefaultLabel,
    DuplicatedFareDialog,
  },
  data() {
    return {
      progress: false,
      loadedFileInfo: {},
      selectedCurrency: null,
      duplicatedCurrency: null,
      currencys: CurrencyInfo.currencyMap,
      chargePriceAP: null,
      chargePriceAR: null,
      term: null,
    };
  },
  methods: {
    closeDialog() {
      this.duplicatedCurrency = null;
    },
    async checkCurrencyDate() {
      const defaultData = {
        customerId: this.GE_SELECT_CUSTOMER.key,
        carrierId: this.GE_SELECT_CARRIER.key,
        partnerId: this.GE_SELECT_PARTNER.key,
        startDate: this.GE_START_DATE,
        endDate: this.GE_END_DATE,
      };
      defaultData.fareType = 'CURRENCY_AR';
      let result = await this.$refs.axios.checkFareDate(defaultData);
      if (result === '') {
        defaultData.fareType = 'CURRENCY_AP';
        result = await this.$refs.axios.checkFareDate(defaultData);
      }
      this.duplicatedCurrency = result === '' ? null : result;
    },
    async register() {
      const defaultData = {
        customerId: this.GE_SELECT_CUSTOMER.key,
        carrierId: this.GE_SELECT_CARRIER.key,
        partnerId: this.GE_SELECT_PARTNER.key,
        currency: this.selectedCurrency.key,
        startDate: this.GE_START_DATE,
        endDate: this.GE_END_DATE,
        fromCountryCode: 'KR',
        tariffJson: null,
        tariffType: 'PER_CASE',
      };
      const request = [];
      defaultData.fareType = 'CURRENCY_AR';
      defaultData.chargePrice = this.chargePriceAR;
      request.push({ ...defaultData });
      defaultData.fareType = 'CURRENCY_AP';
      defaultData.chargePrice = this.chargePriceAP;
      request.push({ ...defaultData });

      this.progress = true;

      const result = await this.$refs.axios.registerFare(request);

      this.progress = false;

      if (!result) {
        this.$toast.add({
          severity: 'success',
          summary: '환율 등록 성공',
          detail: `고객사 : ${this.GE_SELECT_CUSTOMER.text}`,
          life: 3000,
        });
      }
    },
    getCustomerText,
    getCarrierText,
    getPartnerText,
  },
  computed: {
    ...mapGetters(
      'fareStore',
      [
        'GE_SELECT_CUSTOMER',
        'GE_SELECT_CARRIER',
        'GE_SELECT_PARTNER',
        'GE_START_DATE',
        'GE_END_DATE',
      ],
    ),
  },
};
</script>

<style scoped lang="scss">
.month-select-calendar {
  width: 140px;
}
.upload-file-info__label {
  padding: 0.5rem;
  width: 120px
}
</style>
