<template>
  <h6 v-if="label" class="font-bold" :style="[{'width': width}]">{{label}}</h6>
</template>

<script>
export default {
  name: 'DefaultLabel',
  emits: ['change:input'],
  props: {
    label: {
      type: String,
    },
    width: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
</style>
