<template>
  <prepared-tracking-api ref="api" />
  <div class="grid p-fluid">
    <div class="col-2">
      <h6>운송사</h6>
      <Dropdown v-model="carrier" :placeholder="this.carriers[0]?.label ?? ''" :options="carriers" optionLabel="label">
        <template #optiongroup="slotProps">
          <div class="flex align-items-center country-item">
            <img src="https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png" width="18" />
            <div>{{slotProps.option.label}}</div>
          </div>
        </template>
      </Dropdown>
    </div>
    <div class="col-8">
      <h6>영업소 코드 파일</h6>
      <SingleFileSelect
        :accept="this.uploadFileExtension"
        @change:file="({ file }) => onSelect(file)"
        ref="fileSelector"
        :disabled="!carrier"
      />
      <ProgressBar mode="indeterminate" v-if="uploading" style="height: .5em; margin-top:14px;" />
    </div>
  </div>
  <div class="mt-2">
    <Button @click="uploadLocalOfficeFile" :disabled="!enabledUpload" :loading="uploading">영업소 코드 파일 업로드</Button>
  </div>
</template>

<script>
import SingleFileSelect from '@/components/SingleFileSelect.vue';
import PreparedTrackingApi from '@/views/prepared-tracking/PreparedTrackingApi.vue';
// import * as excelReader from '@/services/excel-reader/excel-reader';
import { mapState } from 'vuex';

export default {
  name: 'PreparedTrackingLocalOfficeUpload',
  components: {
    SingleFileSelect,
    PreparedTrackingApi,
  },
  emits: ['confirm'],
  data: () => ({
    selectedFile: null,
    uploading: false,
    carrier: { label: 'yamato', value: 'yamato' },
    carriers: [],
  }),
  computed: {
    enabledUpload() {
      return this.carrier && this.selectedFile;
    },
    ...mapState('preparedTrackingStore', [
      'issuers',
    ]),
    uploadFileExtension() {
      if (this.carrier.label === 'yamato' || this.carrier.label === 'nekopos') return '.dat';
      if (this.carrier.label === 'sagawa') return '.xlsx,.xls';
      return '.undefined';
    },
  },
  methods: {
    onSelect(file) {
      this.selectedFile = file;
    },
    async yamatoNekoposUploadOfficeFile(carrierId) {
      const content = await new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onload = () => resolve(fileReader.result);
        fileReader.onerror = (evt) => reject(evt.target.error);
        fileReader.readAsText(this.selectedFile);
      });

      // validation
      const rows = content.split('\r\n');
      const localOffices = [];
      for (let i = 0; i < rows.length - 1; i += 1) {
        const row = rows[i];
        const cols = row.split(' ').filter((col) => col !== '');
        if (cols.length < 2) {
          throw Error(`Undefined data format: ${i + 1} line must have 2 columns at least.`);
        }
        if (cols[0].length !== 8) {
          throw Error(`Undefined data format: ${i + 1} line, the first column(zip code) must a string having 8 length.`);
        }
        if (cols[1].length !== 24) {
          throw Error(`Undefined data format: ${i + 1} line, the second column(office info) must a string having 24 length.`);
        }

        localOffices.push({
          zipCode: cols[0].substring(1),
          localOfficeInfo: cols[1],
        });
      }
      await this.$refs.api.uploadLocalOffices({ carrierId, localOffices });
    },
    async sagawaUploadOfficeFile() {
      // TODO
      // const { isValid, rows, errors } = await excelReader.read([[{ column: 'A', text: '송장번호', key: 'trackingNumber' }]], this.selectedFile);
      // if (isValid && !errors?.length) {
      //   try {
      //     const [issuer, carrier] = this.carrier.value.split('.');
      //     const trackingNumbers = rows.map((i) => i.trackingNumber);
      //     console.log({
      //       issuer,
      //       carrier,
      //       trackingNumbers,
      //     });
      //     await this.$refs.api.uploadPreparedTrackingNumbers({
      //       issuer,
      //       carrier,
      //       trackingNumbers,
      //     });
      //     this.carrier = '';
      //     this.selectedFile = null;
      //     this.$refs.fileSelector.clearFile();
      //     this.$toast.add({
      //       severity: 'success', summary: 'Successful', detail: '대역대 업로드 완료', life: 3000,
      //     });
      //     this.$emit('confirm');
      //   } catch (e) {
      //     if (e?.response?.data) {
      //       const { message } = e.response.data;
      //       this.$toast.add({
      //         severity: 'error', summary: 'Error', detail: message || '시스템 관리자에게 문의하세요', life: 3000,
      //       });
      //     }
      //     console.error(e);
      //   }
      // } else {
      //   console.log('error');
      // }
    },
    async uploadLocalOfficeFile() {
      this.uploading = true;
      try {
        if (this.carrier.label === 'sagawa') {
          await this.sagawaUploadOfficeFile();
        } else if (this.carrier.label === 'yamato' || this.carrier.label === 'nekopos') {
          await this.yamatoNekoposUploadOfficeFile(this.carrier.label);
        } else {
          throw Error('Undefined behavior: Not proper carrier selected');
        }
        this.$toast.add({
          severity: 'success', summary: 'Successful', detail: '영업소 코드 업로드 완료', life: 3000,
        });
      } catch (e) {
        const { message } = e;
        this.$toast.add({
          severity: 'error', summary: 'Error', detail: message || '시스템 관리자에게 문의하세요', life: 15000,
        });
        console.error(e);
      }
      this.$refs.fileSelector.clearFile();
      this.uploading = false;
    },
    convertSelectItem(item) {
      return { key: item, title: item };
    },
  },
  async mounted() {
    const carrierList = (await this.$refs.api.listOfCarriers()).map((carrier) => ({
      label: carrier.id,
      value: carrier.id,
    }));
    this.carriers = carrierList;
    this.$store.dispatch('preparedTrackingStore/INIT_ISSUERS');
  },
};
</script>

<style scoped>

</style>
